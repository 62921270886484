import AppConfig from "../configs/AppConfig";
import * as stream from "getstream";
import { StreamChat } from "stream-chat";
const { connect } = require("getstream");
import moment from "moment-timezone";
import axios from "axios";
import store from "../store";
import contact from "../components/PageManager/Pages/Components/TabComponents/Contact";
import { log } from "../components/utils/Utils";
import { capitalizeFirstLetter } from "./common";
export default {
  data() {
    return {
      notificationData: [],
      newNotification: {
        activities: [],
        verb: "",
      },
    };
  },
  computed: {
    accessToken() {
      return this.$store.getters.getAccessTokenOfGetStream;
    },
    messagingAccessToken() {
      return this.$store.getters.getAccessTokenOfGetStreamMessaging;
    },
    getSubscriptionData() {
      return this.$store.getters.getGetStreamSubscription;
    },
    streamUser() {
      return this.$store.getters.user_map_to_props.user_data;
    },
    userId() {
      return this.$store.getters.user_map_to_props.user_data.id;
    },
    userName() {
      return this.$store.getters.user_map_to_props.user_data.username;
    },
    userArtwork() {
      return this.$store.getters.user_map_to_props.user_data.artwork_url;
    },
    userFullName() {
      return this.$store.getters.user_map_to_props.user_data.full_name;
    },
    userCountry() {
      return (
        this.$store.getters.user_map_to_props.user_data.country.length > 0 &&
        this.$store.getters.user_map_to_props.user_data.country[0]
      );
    },
    mutualFriends() {
      return this.$store.getters.user_map_to_props.user_data.mututal_friends;
    },
    feed() {
      return this.$store.getters.getGetStreamUser;
    },
    getClient() {
      return this.$store.getters.getGetStreamChatUser;
    },
    getMyFriends() {
      return this.$store.getters.getMyFriends;
    },
    getActiveChatUser() {
      return this.$store.getters.getActiveChatUser;
    },
    getActiveChannel() {
      return this.$store.getters.getActiveChannel;
    },
    getActiveMutableChannel() {
      return this.$store.getters.getMutableActiveChannel;
    },
    getRoomInfo() {
      return this.$store.getters.getLaunchRoomData.launch_room_info;
    },
    getRoomUsers() {
      return this.$store.getters.getLaunchRoomData.launch_room_users;
    },
    getRoomSchedule() {
      return this.$store.getters.getLaunchRoomData.launch_room_schedule;
    },
    getNotificationClient() {
      return this.$store.getters.getNotificationClient
    }
  },
  methods: {
    async setConnection() {
      const access_token = this.accessToken;

      const client = await stream.connect(
        AppConfig.get_stream_api_key,
        access_token,
        AppConfig.get_stream_api_app_id
      );
      this.$store.commit('SET_NOTIFICATION_CLIENT', client)
      let feed = await client.feed("notification", this.userId);
      this.$store.commit("SET_USER", feed);
    },
    async setMessagingConnection() {
      const self = this;
      const time_zone = self.userCountry
        ? JSON.parse(self.userCountry.timezones)[0].zoneName
        : null;
      const country = self.userCountry ? self.userCountry.name : "";
      const name = self.userFullName;
      const username = self.streamUser ? self.streamUser.username : "";
      const image = self.userArtwork;
      const dob = self.streamUser ? self.streamUser.birth : "";
      const email = self.streamUser ? self.streamUser.email : "";
      const city = self.streamUser ? self.streamUser.current_city : "";
      const mutual_friends = self.mutualFriends;
      const msg_access_token = this.messagingAccessToken;
      const client = StreamChat.getInstance(AppConfig.get_stream_api_key);
      const user = await client.connectUser(
        {
          id: self.userId.toString(),
          name: name,
          username: username,
          image: image,
          dob: dob,
          email: email,
          city: city,
          country: country,
          timezone: time_zone,
          mutual_friends: mutual_friends,
        },
        msg_access_token
      );
      await this.$store.commit(
        "SET_UNREAD_MESSAGES_COUNT",
        user.me.total_unread_count
      );
      await this.$store.commit("SET_CHAT_CLIENT", client);
    },
    async subscribeGetStream() {
      await this.setConnection();

      let self = this;
      let subscription = self.feed.subscribe(function(data) {
        self.newNotification.activities = data.new;
        self.newNotification.verb =
          data && data.new.length > 0 && data.new[0].verb
            ? data.new[0].verb
            : "";
        self.$store.commit("SET_NEW_NOTIFICATION", self.newNotification);
        self.newNotification = {};
        let total_seen = self.$store.getters.getTotalSeen + 1;
        self.$store.commit("SET_TOTAL_SEEN", total_seen);
      });
      await this.getNotification();
    },

    cancelSubscribeGetStream() {
      const subscription = this.getSubscriptionData;
      subscription.cancel();
    },
    async getNotification() {
      this.$store.commit("SET_NOTIFICATIONS", []);
      const notifications = await this.feed.get({ mark_read: false });
      this.$store.commit("SET_TOTAL_READ", notifications.unread);
      this.$store.commit("SET_TOTAL_SEEN", notifications.unseen);
      const filterNotification = notifications.results.filter(e => e.activities[0].user.full_name !== this.userFullName)
      this.$store.commit("SET_NOTIFICATIONS", filterNotification);
    },
    async notificationAsRead(notification_id) {
      if (Array.isArray(notification_id)) {
        const payload = {
          mark_read: true,
        };
        let notifications = await this.getNotificationClient.feed('notification', this.userId).get(payload);
        if (notifications && notifications.unread > 0) {
          notifications = await this.getNotificationClient.feed('notification', this.userId).get(payload);
        }
        if (notifications && notifications.unread < 1) {
          this.$store.commit("SET_NOTIFICATIONS", []);
          this.$store.commit('SET_TOTAL_READ', null)
          const filterNotification = notifications.results.filter(e => e.activities[0].user.full_name !== this.userFullName)
          this.$store.commit("SET_NOTIFICATIONS", filterNotification);
          this.$store.commit("SET_TOTAL_READ", notifications.unread);
        }
      }
    },
    async notificationAsSeen(notification_id) {
      if (Array.isArray(notification_id)) {
        const payload = {
          mark_seen: true,
        };
        let notifications = await this.getNotificationClient.feed('notification', this.userId).get(payload);
        if (notifications && notifications.unseen > 0) {
           notifications = await this.getNotificationClient.feed('notification', this.userId).get(payload);
        }
        if (notifications && notifications.unseen < 1) {
          this.$store.commit("SET_NOTIFICATIONS", []);
          this.$store.commit('SET_TOTAL_SEEN', null)
          const filterNotification = notifications.results.filter(e => e.activities[0].user.full_name !== this.userFullName)
          this.$store.commit("SET_NOTIFICATIONS", filterNotification);
          this.$store.commit("SET_TOTAL_SEEN", notifications.unseen);
        }
      }
    },
    async setOnlineUsers() {
      if (this.getMyFriends.length > 0) {
        const friendIds = this.getMyFriends.map((e) => {
          return e.id.toString();
        });

        const response = await this.getClient.queryUsers(
          { id: { $in: friendIds } },
          { last_active: -1 },
          { presence: true }
        );
        this.$store.commit("SET_GET_STREAM_FRIENDS", response.users);
        if (response.users.length > 0) {
          const users = response.users.filter((e) => e.online === true);
          this.$store.commit("SET_ONLINE_USERS", users);
        }
      }
      const response = await this.getClient.queryUsers(
        { role: "user" },
        { last_active: -1 },
        { presence: true }
      );
      let onlineUserIds;
      const allOnlinUser = response.users.filter(
        (el) => el.online === true && el.id != this.userId
      );
      onlineUserIds = allOnlinUser.map((el) => el.id);
      this.$store.commit("set_all_online_users_id", onlineUserIds);
      this.$store.commit("SET_ALL_ONLINE_USERS", allOnlinUser);
    },
    async setLastMessages() {
      const userId = this.userId.toString();

      const filter = {
        type: "messaging",
        members: { $in: [userId] },
        name: "single",
      };
      const sort = [{ last_message_at: -1 }, { created_at: -1 }];

      const lastMessages = await this.getClient.queryChannels(filter, sort, {
        // watch: true, // this is the default
        state: true,
      });
      this.$store.commit("SET_LAST_MESSAGES", lastMessages);
    },

    async setLastMessagesGroups(event,offset = 0, limit = 10, showMore = false) {
      const userId = this.userId.toString();

      const filter = {
        type: "messaging",
        members: { $in: [userId] },
        name: "group",
        room_type: "text",
      };

      const sort = [{ last_message_at: -1 }, { created_at: -1 }];

      const groups = await this.getClient.queryChannels(filter, sort, {
        state: true,
        offset: offset,
        limit: limit,
      });

      if (showMore) {
        this.$store.commit(
          "JOIN_LAST_MESSAGES_GROUPS_WITH_OTHER_GROUPS",
          groups
        );

      } else {
        this.$store.commit("SET_LAST_MESSAGES_GROUPS", groups);
        // this.openFullViewChat(event, 'group', '', groups);
        // this.openFullViewChat(event, active_tab, getUser, message);
        // this.$store.commit("SET_VIEW_ALL_CHAT_LISTING", false);
        // this.$store.commit('SET_ACTIVE_FULL_CHAT_PREVIOUS_TYPE', '')
      }
    },
    async setLastMessagesClubroom(offset = 0, limit = 10, showMore = false) {
      const userId = this.userId.toString();

      const filter = {
        type: "messaging",
        members: { $in: [userId] },
        name: "group",
        room_type: "clubroom",
      };
      const sort = [{ created_at: -1 }];

      const clubs = await this.getClient.queryChannels(filter, sort, {
        state: true,
        offset: offset,
        limit: limit,
      });
      if (showMore) {
        this.$store.commit(
          "JOIN_LAST_MESSAGES_CLUBROOM_WITH_OTHER_CLUBROOM",
          clubs
        );
      } else {
        this.$store.commit("SET_LAST_MESSAGES_CLUBROOM", clubs);
      }
    },
    async setLastMessagesPartyroom(offset = 0, limit = 10, showMore = false) {
      const userId = this.userId.toString();

      const filter = {
        type: "messaging",
        members: { $in: [userId] },
        name: "group",
        room_type: "partyroom",
      };
      const sort = [{ created_at: -1 }];

      const parties = await this.getClient.queryChannels(filter, sort, {
        state: true,
        offset: offset,
        limit: limit,
      });

      if (showMore) {
        this.$store.commit(
          "JOIN_LAST_MESSAGES_PARTY_ROOM_WITH_OTHER_PARTY_ROOMS",
          parties
        );
      } else {
        this.$store.commit("SET_LAST_MESSAGES_PARTYROOM", parties);
      }
    },

    async freezeChat(activeUser, chatOff, group = false) {
      let filter = {};
      try {
        this.$store.dispatch("loading", true);
        if(group) {
          filter = { cid: activeUser.cid }
        }else {
          const firstChannelId = "single-" + activeUser.id + "-" + this.userId;
          const secondChannelId = "single-" + this.userId + "-" + activeUser.id;
          filter = { id: { $in: [firstChannelId, secondChannelId] } };
        }
        const chatClient = this.getClient;
        const sort = {};
        const channels = await chatClient.queryChannels(filter, sort);
        if (channels.length === 0) {
          console.error('No matching channel found.');
          return;
        }
        const channel = channels[0];
        const updatedChannel = await channel.updatePartial(
          { set: { frozen: chatOff, frozen_by: this.userId, } },
          { text: 'Channel frozen', user_id: channel.data.created_by.id }
        );
        this.$store.dispatch("loading", false);
        this.$store.commit("SET_MUTABLE_ACTIVE_CHANNEL", updatedChannel.channel);
      } catch (error) {
        this.$store.dispatch("loading", false);
        console.error('Error in freezing chat:', error);
      }
    },

    async toggleSound(activeUser, group){
      let filter = {};
      try {
        this.$store.dispatch("loading", true);
        if(group) {
          filter = { cid: activeUser.cid };
        }else {
          const firstChannelId = "single-" + activeUser.id + "-" + this.userId;
          const secondChannelId = "single-" + this.userId + "-" + activeUser.id;
          filter = { id: { $in: [firstChannelId, secondChannelId] } };
        }
        const chatClient = this.getClient;
        const sort = {};
        const channels = await chatClient.queryChannels(filter, sort);
        if (channels.length === 0) {
          console.error('No matching channel found.');
          return;
        }
        const channel = channels[0];
        const currentNotificationsSounds = channel.data.notificationsSounds || [];
        const userNotificationsSoundsIndex = currentNotificationsSounds.findIndex(
          (not) => not.for_User === this.userId
        );
        let updatedNotificationsSounds = [...currentNotificationsSounds];
        if (userNotificationsSoundsIndex !== -1) {
          updatedNotificationsSounds[userNotificationsSoundsIndex].is_notify = !currentNotificationsSounds[userNotificationsSoundsIndex].is_notify;
        } else {
          updatedNotificationsSounds.push({
            for_User: this.userId,
            is_notify: true,
          });
        }
        const updatedChannel = await channel.updatePartial({
          set: { notificationsSounds: updatedNotificationsSounds },
        });
        this.$store.dispatch("loading", false);
        this.$store.commit("SET_MUTABLE_ACTIVE_CHANNEL", updatedChannel.channel);
      } catch (error) {
        this.$store.dispatch("loading", false);
        console.error('Error in freezing chat:', error);
      }
    },

    async setFavoriteChat(activeUser, group) {
      let filter = {};
      let sort = {};
      try {
        this.$store.dispatch("loading", true);
        if(group) {
          filter = { cid: activeUser.cid }
        }else{
          const firstChannelId = "single-" + activeUser.id + "-" + this.userId;
          const secondChannelId = "single-" + this.userId + "-" + activeUser.id;
          filter = { id: { $in: [firstChannelId, secondChannelId] } };
        }
        const chatClient = this.getClient;
        const channels = await chatClient.queryChannels(filter, sort);
        if (channels.length === 0) {
          console.error('No matching channel found.');
          return;
        }
        const channel = channels[0];
        const currentFavorites = channel.data.favorites || [];
        const userFavoriteIndex = currentFavorites.findIndex(
          (fav) => fav.by === this.userId
        );
        let updatedFavorites = [...currentFavorites];
        if (userFavoriteIndex !== -1) {
          updatedFavorites[userFavoriteIndex].is_favorite = !currentFavorites[userFavoriteIndex].is_favorite;
        } else {
          updatedFavorites.push({
            by: this.userId,
            is_favorite: true,
          });
        }
        const updatedChannel = await channel.updatePartial({
          set: { favorites: updatedFavorites },
        });
        this.$store.commit("SET_MUTABLE_ACTIVE_CHANNEL", updatedChannel.channel);
        this.$store.dispatch("loading", false);
      } catch (error) {
        this.$store.dispatch("loading", false);
        console.error('Error in freezing chat:', error);
      }
    },

    async setActiveChannel(activeUser) {
      let firstChannelId = "single-" + activeUser.id + "-" + this.userId;
      let secondChannelId = "single-" + this.userId + "-" + activeUser.id;
      const chatClient = this.getClient;
      const filter = { id: { $in: [firstChannelId, secondChannelId] } };
      const sort = {};
      const channel = await chatClient.queryChannels(filter, sort);
      if (channel.length > 0) {
        this.$store.commit("SET_ACTIVE_CHAT", channel[0].state.read);
        this.$store.commit(
          "SET_ACTIVE_CHANNEL",
          channel[0].state.messageSets[0]
        );
        await this.markReadMessages(channel[0]);
      }
    },
    async markReadMessages(channel) {
      const getChannel = await this.getGroupChannel(channel);
      if (
        getChannel.state.read &&
        getChannel.state.read[this.userId] &&
        getChannel.state.read[this.userId].unread_messages > 0
      ) {
        await channel.markRead({ user_id: this.userId.toString() });
      }
    },

    async getChannelMember(last_message) {
      const channelMembers = await last_message.queryMembers({ joined: true });
      const activeMember = channelMembers.members.filter(
        (e) => e.user_id != this.userId
      );
      return activeMember[0];
    },
    getThreadUser(last_message, type) {
      let value = "";
      if (last_message.data && last_message.data.created_by.id != this.userId) {
        value = last_message.data.created_by;
      } else if (last_message.data && last_message.data.with) {
        value = last_message.data.with;
      }

      if (type === "name") {
        if (value && value.id) {
          return last_message &&
            last_message.state &&
            last_message.state.members &&
            last_message.state.members.hasOwnProperty(value.id) &&
            last_message.state.members[value.id].user &&
            last_message.state.members[value.id].user.name
            ? last_message.state.members[value.id].user.name
            : "";
        }
      } else if (type === "image") {
        if (value && value.id) {
          return value.image;
        }
      } else if (type === "online") {
        if (value && value.id) {
          return last_message &&
            last_message.state &&
            last_message.state.members &&
            last_message.state.members.hasOwnProperty(value.id) &&
            last_message.state.members[value.id].user &&
            last_message.state.members[value.id].user.online
            ? last_message.state.members[value.id].user.online
            : "";
        }
      } else if (type === "friend") {
        if (value && value.id) {
          return last_message &&
            last_message.state &&
            last_message.state.members &&
            last_message.state.members.hasOwnProperty(value.id) &&
            last_message.state.members[value.id].user
            ? last_message.state.members[value.id].user
            : {};
        }
      }
    },
    async sendMessageToUser(msg, activeUser, chatType, event, attachments) {
      let active_channel = {};
      let attachment = [];
      if (Array.isArray(attachments)) {
        attachment = attachments;
      }
      const chatClient = this.getClient;
      const data = await this.checkIfChannelExist(activeUser);
      if (data.find_channel === true) {
        active_channel = data.active_channel;
      } else {
        active_channel = chatClient.channel("messaging", `${data.id}`, {
          name: "single",
          image: "https://bit.ly/2F3KEoM",
          members: [activeUser.id.toString(), this.userId.toString()],
          with: activeUser,
        });
        await active_channel.create();
        if (chatType !== "new") {
          await this.openFullViewChat(
            event,
            "single",
            activeUser,
            active_channel
          );
        }
      }
      if (chatType === "new") {
        await this.openChatBox(event, "single", activeUser, active_channel);
      }
      const message = await active_channel.sendMessage({
        text: msg,
        attachments: attachment,
        mentioned_users: [activeUser.id],
      });
      // active_channel.on("message.new", (event) => {});
      await this.setLastMessages();
    },
    async checkIfChannelExist(activeUser) {
      let firstChannelId = "single-" + activeUser.id + "-" + this.userId;
      let secondChannelId = "single-" + this.userId + "-" + activeUser.id;
      const chatClient = this.getClient;
      const filter = { id: { $in: [firstChannelId, secondChannelId] } };
      const sort = {};
      const channel = await chatClient.queryChannels(filter, sort);
      if (channel.length > 0) {
        const data = {
          active_channel: channel[0],
          id: firstChannelId,
          find_channel: true,
        };
        return data;
      } else {
        const data = {
          active_channel: {},
          id: firstChannelId,
          find_channel: false,
        };
        return data;
      }
    },
    async createChatGroup(title, members) {
      const memberIds = await members.map((e) => {
        return e.id.toString();
      });
      memberIds.push(this.userId.toString());
      const currentTime = moment().unix();
      const Id = "group-" + currentTime;
      const group = await this.getClient.channel("messaging", Id, {
        name: "group",
        image: "https://bit.ly/2F3KEoM",
        members: memberIds,
        title: title,
      });
      await group.create();
      await group.watch();
    },
    async getGroupChannel(data) {
      const filter = { cid: data.cid };
      const sort = {};
      const self = this;
      const channel = await self.getClient.queryChannels(filter, sort, {
        watch: false, // this is the default
        state: true,
      });
      if (channel.length > 0) {
        return channel[0];
      }
    },
    async getGroupMembers(group) {
      let sort = { created_at: -1 };
      const members = await group.queryMembers({}, sort, {});
      return members.members;
    },
    async sendGroupMessage(text, data, attachments) {
      const filter = { cid: data.cid };
      const sort = {};
      let attachment = [];
      if (Array.isArray(attachments)) {
        attachment = attachments;
      }

      const channel = await this.getClient.queryChannels(filter, sort);
      if (channel.length > 0) {
        const activeGroup = channel[0];
        const message = await activeGroup.sendMessage({
          text: text,
          attachments: attachment,
        });
        activeGroup.on("message.new", (event) => {});
      }

      await this.setLastMessagesGroups();
    },
    async getMessageNotifications() {
      const chatClient = this.getClient;

      let totalNotifications = 0;

      await chatClient.on((event) => {
        if (event.total_unread_count !== null && event.total_unread_count > 0) {
          totalNotifications = event.total_unread_count;
          this.$store.commit("SET_UNREAD_MESSAGES_COUNT", totalNotifications);
        }
      });
    },

    async sendMessageReaction(messageID, reaction, activeUserId, userId) {
      let firstChannelId = "single-" + activeUserId + "-" + userId;
      let secondChannelId = "single-" + userId + "-" + activeUserId;
      const chatClient = StreamChat.getInstance(AppConfig.get_stream_api_key);
      const filter = { id: { $in: [firstChannelId, secondChannelId] } };
      const sort = {};
      const channel = await chatClient.queryChannels(filter, sort);
      if (channel.length > 0) {
        const reactions = await channel[0].sendReaction(
          messageID,
          { type: reaction, user_id: this.userId },
          { enforce_unique: true },
        );
      }
    },

    async removeReaction(messageID, reaction, activeUserId, userId) {
      let firstChannelId = "single-" + activeUserId + "-" + userId;
      let secondChannelId = "single-" + userId + "-" + activeUserId;
      const chatClient = StreamChat.getInstance(AppConfig.get_stream_api_key);
      const filter = { id: { $in: [firstChannelId, secondChannelId] } };
      const sort = {};
      const channel = await chatClient.queryChannels(filter, sort);
      if (channel.length > 0) {
        const reactions = await channel[0].deleteReaction(
          messageID,
          reaction
        );
      }
    },

    async addAndRemoveMemberInGroup(data, selected_members_id, type) {
      const filter = { cid: data.cid };
      const sort = {};
      const channel = await this.getClient.queryChannels(filter, sort);
      if (channel.length > 0) {
        const activeGroup = channel[0];
        if (type === 1) {
          await activeGroup.addMembers([selected_members_id]);
        } else {
          await activeGroup.removeMembers([selected_members_id]);
        }
      }
    },
    formatSizeUnits(bytes) {
      if ((bytes >> 30) & 0x3ff)
        bytes = (bytes >>> 30) + "." + (bytes & (3 * 0x3ff)) + "GB";
      else if ((bytes >> 20) & 0x3ff)
        bytes = (bytes >>> 20) + "." + (bytes & (2 * 0x3ff)) + "MB";
      else if ((bytes >> 10) & 0x3ff)
        bytes = (bytes >>> 10) + "." + (bytes & 0x3ff) + "KB";
      else if ((bytes >> 1) & 0x3ff) bytes = (bytes >>> 1) + "Bytes";
      else bytes = bytes + "Byte";
      return bytes;
    },
    downloadItem(url, label, type) {
      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    async startAndStopTypingEvents(activeUser) {
      let active_channel = {};
      const data = await this.checkIfChannelExist(activeUser);
      if (data.find_channel === true) {
        active_channel = data.active_channel;
      }
      await active_channel.keystroke();
      // else if (check === 0) {
      //   await active_channel.stopTyping();
      // }
    },
    watchingChannelForStartTyping(channel) {
      const self = this;
      channel.on("typing.start", (event) => {
        self.$store.commit("SET_IS_TYPING", true);
        self.$store.commit("SET_TYPING_USER", `${event.user.name}`);
      });
    },
    watchingChannelForStopTyping(channel) {
      const self = this;
      channel.on("typing.stop", (event) => {
        setTimeout(self.$store.commit("SET_IS_TYPING", false), 5000);
        setTimeout(self.$store.commit("SET_TYPING_USER", ""), 5000);
      });
    },

    async getMessagesPagination(message) {
      const filter = { cid: message.cid };
      const sort = {};

      const channel = await this.getClient.queryChannels(filter, sort);
      if (channel.length > 0) {
        const activeMessageChannel = channel[0];
        const lastMessageId = message.id;
        const result = await activeMessageChannel.query({
          messages: { limit: 25, id_lt: lastMessageId },
        });
      }
    },
    async searchMessageInActiveChannel(channelId, text) {
      const channelFilters = { cid: channelId };
      const messageFilters = { text: { $autocomplete: text } };
      const { results } = await this.getClient.search(
        channelFilters,
        messageFilters,
        {
          sort: [{ relevance: -1 }, { updated_at: 1 }, { my_custom_field: -1 }],
          limit: 25,
        }
      );
      this.$store.commit("SET_SEARCHED_MESSAGES_LIST", results);
    },
    async launchRoomStream(file) {
      const getRoomInfo = this.getRoomInfo;
      const getRoomSchedule = this.getRoomSchedule;
      const memberIds = this.getRoomUsers.room_users_ids;
      memberIds.push(this.userId.toString());
      const currentTime = moment().unix();
      const Id = "group-" + currentTime;
      const group = await this.getClient.channel("messaging", Id, {
        name: "group",
        members: memberIds,
        data:{
          page_id:
            getRoomInfo && getRoomInfo.room_page_id
              ? getRoomInfo.room_page_id.toString()
              : "",
          name: "group",
        },
        page_id:
          getRoomInfo && getRoomInfo.room_page_id
            ? getRoomInfo.room_page_id.toString()
            : "",
        room_type:
          getRoomInfo && getRoomInfo.room_info_type
            ? getRoomInfo.room_info_type
            : "",
        image: "",
        title:
          getRoomInfo && getRoomInfo.room_info_name
            ? getRoomInfo.room_info_name
            : "",
        description:
          getRoomInfo && getRoomInfo.room_info_description
            ? getRoomInfo.room_info_description
            : "",
        privacy:
          getRoomInfo && getRoomInfo.room_info_privacy
            ? getRoomInfo.room_info_privacy
            : "",
        schedule: getRoomSchedule ? getRoomSchedule : "",
        twilio_room_sid: "",
        stream_sid: "",
        streaming: "",
      });
      const channel = await group.create();
      await group.watch();

      if (file && file.length > 0) {
        await this.setChannelImage(channel, file);
      }
      if (
        Object.keys(channel) !== 0 &&
        (getRoomInfo.room_page_type === "clubroom" ||
          getRoomInfo.room_page_type === "partyroom")
      ) {

        if (getRoomSchedule.is_room_schedule) {
          this.notificationToast(true, 'Launch Room', `${ capitalizeFirstLetter(getRoomInfo.room_page_type)} schedule  successfully!`, 5000, 'success')
        }

        this.notificationToast(true, 'Launch Room', `${ capitalizeFirstLetter(getRoomInfo.room_page_type)} created  successfully!`, 5000, 'success')

        //TODO Integrate Twilio

        // const payload = {
        //   roomName: channel.channel.id,
        // };
        //
        // const { status, data } = await this.$store.dispatch(
        //   "createTwilioAudioRoom",
        //   payload
        // );
        // if (status === 1) {
        //   await this.setSIDOfTwilioRoomInGetStreamRoom(
        //     channel.channel,
        //     data["sid"].toString()
        //   );
        //   this.notificationToast(true, 'Launch Room', `${ capitalizeFirstLetter(getRoomInfo.room_page_type)} created  successfully!`, 5000, 'success')
        // } else {
        //   this.notificationToast(true, "Launch Room", `Unable to ${ capitalizeFirstLetter(getRoomInfo.room_page_type)}, please try again later`, 5000, 'error');
        // }
      }
      if (channel) {
        const filter = {
          id: { $in: [channel.channel.id] },
        };
        const channels = await this.getClient.queryChannels(filter);
        const current_channel = channels[0];
        let active_tab = "";
        let getUser = "";
        if (
          current_channel &&
          current_channel.data &&
          current_channel.data.name === "single"
        ) {
          active_tab = "single";
          getUser = this.getThreadUser(current_channel, "friend");
        } else if (
          current_channel &&
          current_channel.data &&
          current_channel.data.name === "group"
        ) {
          active_tab =
            (current_channel.data.room_type === "text" || current_channel._data.room_type === "text")
              ? "group"
              : current_channel.data.room_type || current_channel._data.room_type;
        }
        this.$store.commit("SET_ACTIVE_CHAT_ID", current_channel.id);
        this.$store.commit("SET_LAST_MESSAGES_FOR_CHANNELS", current_channel);
        await this.openFullViewChat("", active_tab, getUser, current_channel);
      }
    },
    async setSIDOfTwilioRoomInGetStreamRoom(active_channel, sid) {
      const current_channel = await this.getGroupChannel(active_channel);
      await current_channel.updatePartial({ set: { twilio_room_sid: sid } });
    },
    async setStreamSidAndStreamingStatusOfTwilioRoomInGetStreamRoom(
      active_channel,
      stream_sid,
      streaming
    ) {
      const current_channel = await this.getGroupChannel(active_channel);
      await current_channel.updatePartial({
        set: { stream_sid: stream_sid, streaming: streaming },
      });
    },
    async setChannelImage(activeChannel, file) {
      if (file) {
        const channel = await this.getGroupChannel(activeChannel.channel);
        const response = await channel.sendFile(file[0]);
        if (Object.keys(response).length !== 0) {
          const update = await channel.update({ image: response.file });
        }
      }
    },
    async setPageRooms(page_id) {
      const userId = this.userId.toString();
      const id = page_id.toString();
      const filter = {
        type: "messaging",
        members: { $in: [userId] },
        page_id:  { '$eq': String(page_id) },
        name:  { '$eq': 'group' },
      };
      const sort = [{ last_message_at: -1 }];

      const groups = await this.getClient.queryChannels(filter, sort, {
        watch: true, // this is the default
        state: true,
      });
      this.$store.commit("SET_PAGE_ROOMS", groups);
    },
    async setPagetextRooms(page_id) {
      const userId = this.userId.toString();
      const id = page_id.toString();
      const filter = {
        type: "messaging",
        members: { $in: [userId] },
        room_type: "text",
        page_id:  { '$eq': String(page_id) },
        name:  { '$eq': 'group' },
      };
      const sort = [{ last_message_at: -1 }];

      const groups = await this.getClient.queryChannels(filter, sort, {
        watch: true, // this is the default
        state: true,
      });
      this.$store.commit("SET_PAGE_TEXT_ROOMS", groups);
    },
    async setPageTypeRooms(page_id, roomType) {
      const userId = this.userId.toString();
      const id = page_id.toString();
      const filter = {
        type: "messaging",
        members: { $in: [userId] },
        room_type: roomType,
        page_id:  { '$eq': String(page_id) },
        name:  { '$eq': 'group' },
      };
      const sort = [{ last_message_at: -1 }];

      const groups = await this.getClient.queryChannels(filter, sort, {
        watch: true, // this is the default
        state: true,
      });
      this.$store.commit("SET_PAGE_AUDIO_VIDEO_ROOMS", groups);
    },
    async addMembersInGroup(active_channel, memberIds) {
      try {
        const channel = await this.getGroupChannel(active_channel);
        await channel.addMembers(memberIds);
        this.notificationToast(true, 'Add Member', 'Members added successfully', 5000, 'success')
      } catch (e) {
        this.notificationToast(true, "Add Member", "Unable to add Member", 5000, "error");
        console.error(e);
      }
    },
    async refreshLink() {
      const id = "4fc3fac7-1b1b-4084-bd0e-62d6c720b9f6";
      const { message } = await this.getClient.getMessage(id);
      return message;
    },
    async checkOnlineUser(userId) {
      let id = userId.toString();
      const response = await this.getClient.queryUsers(
        { id: id },
        { last_active: -1 },
        { presence: true }
      );
      return response;
    },
    getCurrentLoginUserName() {
      return this.userFullName
    },
    async sharePostInGroupMessage(text, group_id, post) {
      const filter = { cid: group_id };
      const sort = {};
      const channel = await this.getClient.queryChannels(filter, sort);
      if (channel.length > 0) {
        const activeGroup = channel[0];
        const message = await activeGroup.sendMessage({
          text: text,
          post: post
        });
        activeGroup.on("message.new", (event) => {});
      }
    },
    async sharePostToUser(text, user_id, post) {
      let active_channel = {};
      const chatClient = this.getClient;
      const activeUser = {
        id: user_id
      }
      const data = await this.checkIfChannelExist(activeUser);
      if (data.find_channel === true) {
        active_channel = data.active_channel;
      } else {
        active_channel = chatClient.channel("messaging", `${data.id}`, {
          name: "single",
          image: "https://bit.ly/2F3KEoM",
          members: [activeUser.id.toString(), this.userId.toString()],
          with: activeUser,
        });
        await active_channel.create();
      }
      const message = await active_channel.sendMessage({
        text: text,
        post: post,
        mentioned_users: [user_id],
      });
    },
  },
};
