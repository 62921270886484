<template>
  <div class="date-picker-wrapper">
    <date-picker
      v-model="dates"
      range
      format="DD/MM/YYYY"
      placeholder="DD/MM/YYYY – DD/MM/YYYY"
      :editable="false"
      :clearable="false"
      :calendar-icon="'calendar-icon'"
      @change="onDateChange"
      class="custom-date-picker"
    />
  </div>
</template>
  
<script>
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  
  export default {
    components: { DatePicker },
    data() {
      return {
        dates: [],
      };
    },
    methods: {
      onDateChange(dates) {
        if (dates.length === 2) {
          this.$emit("imput", this.dates)
        }
      },
    },
  };
</script>
  
<style lang="scss" scoped>
  .date-picker-wrapper {
    position: relative;
  }

  ::v-deep(.mx-datepicker) {
    z-index: 1050 !important;
  }
  .mx-datepicker {
    width: 100% !important;
  }
  .date-picker-component {
    display: flex;
    flex-direction: column;
  }
  
  .date-picker-label {
    font-weight: 600;
    margin-bottom: 8px;
    color: #47505b;
  }
  
  .date-picker-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .custom-date-picker input {
    color: #8b949f;
  }
  
  .calendar-icon {
    position: absolute;
    right: 12px;
    pointer-events: none;
  }
</style>
<style>
  .custom-date-picker .mx-input:hover, .custom-date-picker .mx-input:focus {
    border-color: #E6EAF0;
  }
  .custom-date-picker .mx-input {
    border-color:  #E6EAF0 !important;
    width: 310px !important;
  }
  .custom-date-picker .mx-input {
    height: 30px !important;
  }
  .mx-datepicker {
    z-index: 9999;
  }
  .custom-date-picker input {
    pointer-events: auto;
  }
  .mx-calendar-content .cell.in-range, .mx-calendar-content .cell.hover-in-range {
    background-color: rgba(209, 67, 58, 0.10);
  }
</style>