<template>
  <div class="find-events">
    <div class="find-events-top">
      <div class="back">
        <router-link to="/events/page">
          <svg
            width="7"
            height="14"
            viewBox="0 0 7 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.85938 12.7285L0.130375 7.00052L5.85937 1.27152"
              stroke="#071526"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          OVERVIEW
        </router-link>
      </div>
      <div class="filter-section">
        <div class="filter-name">
          {{ $route.params.eventsName.split("-").join(" ") }}
        </div>
        <div class="sort">
          <div class="sort-btn">
            <p class="mb-0">Sort</p>
            <svg
              width="16"
              height="13"
              viewBox="0 0 16 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="9.51351"
                height="2.16216"
                rx="1.08108"
                fill="#D1433A"
              />
              <rect
                y="5.18945"
                width="16"
                height="2.16216"
                rx="1.08108"
                fill="#D1433A"
              />
              <rect
                y="10.3779"
                width="12.973"
                height="2.16216"
                rx="1.08108"
                fill="#D1433A"
              />
            </svg>
          </div>
          <div @click.stop="sortPopup = !sortPopup" class="sort-select">
            <p class="mb-0">{{ activeSort }}</p>
            <svg
              width="9"
              height="5"
              viewBox="0 0 9 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.78125 1L4.53325 4.3L0.99925 1"
                stroke="#D1433A"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <transition name="fade">
              <div
                v-if="sortPopup"
                v-click-outside="closePopup"
                class="sort-select-popup"
              >
                <div
                  class="item"
                  :class="{ 'active-sort': activeSort === item.value }"
                  v-for="item in sorts"
                  :key="item.id"
                  @click="handleSort(item.value)"
                >
                  {{ item.value }}
                  <svg
                    v-if="activeSort === item.value"
                    width="9"
                    height="7"
                    viewBox="0 0 9 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.09484 0.176061C8.35659 0.421737 8.36962 0.833088 8.12394 1.09484L3.43099 6.09484C3.30814 6.22573 3.13663 6.29998 2.95712 6.3C2.77761 6.30002 2.60608 6.2258 2.4832 6.09493L0.176151 3.63794C-0.0695785 3.37624 -0.0566316 2.96489 0.205069 2.71916C0.46677 2.47343 0.878123 2.48638 1.12385 2.74808L2.95695 4.70032L7.17606 0.205165C7.42174 -0.0565852 7.83309 -0.0696158 8.09484 0.176061Z"
                      fill="#D1433A"
                    />
                  </svg>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <div class="find-events-content">
      <EventsCard
        v-for="event in GET_ALL_EVENTS_BY_TYPE && GET_ALL_EVENTS_BY_TYPE.data"
        :key="event.id"
        :event="event"
        :mRight="'30px'"
      />
    </div>
    <div
      v-if="GET_ALL_EVENTS_BY_TYPE && GET_ALL_EVENTS_BY_TYPE.total > showCard"
      class="find-events-show-more"
      @click="showMore"
    >
      <svg
        width="9"
        height="8"
        viewBox="0 0 9 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.24219 0.689453V7.01945"
          stroke="#071526"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 3.85449H7.48702"
          stroke="#071526"
          stroke-width="1.3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p class="mb-0">SHOW {{ showMoreCount }} MORE EVENTS</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EventsCard from "../components/EventsCard.vue";
export default {
  components: {
    EventsCard,
  },
  data() {
    return {
      sorts: [
        {
          id: 1,
          value: "Latest",
        },
        {
          id: 2,
          value: "Featured",
        },
        {
          id: 3,
          value: "Trending",
        },
        {
          id: 4,
          value: "Popular",
        },
        {
          id: 5,
          value: "High Rating",
        },
        {
          id: 6,
          value: "Low Rating",
        },
      ],
      activeSort: "Trending",
      sortPopup: false,
      showCard: 12,
    };
  },
  computed: {
    ...mapGetters(["GET_ALL_EVENTS_BY_TYPE"]),
    showMoreCount() {
      console.log("this.GET_ALL_EVENTS_BY_TYPE", this.GET_ALL_EVENTS_BY_TYPE);
      return (
        this.GET_ALL_EVENTS_BY_TYPE &&
        (this.GET_ALL_EVENTS_BY_TYPE.total - this.showCard > 6
          ? 6
          : this.GET_ALL_EVENTS_BY_TYPE.total - this.showCard)
      );
    },
  },
  methods: {
    ...mapActions(["getEventsByType"]),
    async handleSort(value) {
      this.activeSort = value;
      await this.getEvents();
    },
    closePopup() {
      this.sortPopup = false;
    },
    async getEvents() {
      console.log("this.$route.query", this.$route.query);
      let type =
        this.$route.params.eventsName === "this-week"
          ? "weekly"
          : this.$route.params.eventsName.split("-")[0].toLowerCase();
      await this.$store.dispatch("loading", true);
      await this.getEventsByType({
        type: type,
        offset: 0,
        limit: this.showCard,
        sort_by: this.activeSort.split(" ").join("_").toLowerCase(),
        event_type: this.$route.query.event_type_id,
      });
      await this.$store.dispatch("loading", false);
    },
    async showMore() {
      this.showCard += 6;
      await this.getEvents();
    },
  },
  created() {
    if (!this.GET_ALL_EVENTS_BY_TYPE) {
      this.getEvents();
    }
  },
};
</script>

<style lang="scss" scoped>
.active-sort {
  background: #D1433A1A;
  color: #D1433A !important;
}
.find-events {
  width: 1170px;
  padding-top: 130px;
  padding-bottom: 130px;
  &-top {
    background: #ffffff;
    border: 1px solid #e6eaf0;
    border-radius: 4px;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    .back {
      width: 169px;
      max-width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #e6eaf0;
      a {
        display: flex;
        align-items: center;
        color: #071526;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        font-family: HelveticaNeueBold;
        svg {
          margin-right: 13px;
        }
      }
    }
    .filter-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-left: 30px;
      padding-right: 20px;
      .filter-name {
        color: #47505b;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        font-family: HelveticaNeueBold;
      }
      .sort {
        display: flex;
        font-family: HelveticaNeueMedium;
        &-btn {
          display: flex;
          align-items: center;
          margin-right: 10px;
          p {
            color: #071526;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            margin-right: 8px;
          }
        }
        &-select {
          width: 170px;
          max-width: 100%;
          height: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #D1433A;
          border-radius: 4px;
          padding-left: 20px;
          padding-right: 10px;
          cursor: pointer;
          position: relative;
          p {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #D1433A;
          }
          &-popup {
            position: absolute;
            top: 40px;
            left: 0;
            background: #ffffff;
            box-shadow: 0px 10px 20px rgba(74, 75, 77, 0.16);
            border-radius: 4px;
            font-family: HelveticaNeueMedium;
            width: 100%;
            z-index: 999;
            .item {
              color: #071526;
              font-weight: 500;
              font-size: 13px;
              line-height: 16px;
              padding: 7px 10px 7px 20px;
              transition: background-color 0.3s;
              display: flex;
              justify-content: space-between;
              align-items: center;
              &:hover {
                background: #D1433A1A;
              }
            }
          }
        }
      }
    }
  }
  &-content {
    // width: 100%;
    width: 1170px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
    padding-right: -30px;
    margin-top: 25px;
    gap: 25px;
    
    & .events-card {
      margin-right: 0;
      margin-top: 17px;
    }
    // & .events-card + .events-card {
    //   margin-left: 30px;
    // }
  }
  &-show-more {
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    font-family: HelveticaNeueBold;
    color: #071526;
    background: #e6eaf0;
    border-radius: 2px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    cursor: pointer;
    svg {
      margin-right: 10px;
    }
  }
}
</style>
