import moment from "moment-timezone"

import {
    ACCESS_TOKEN,
    REFRESH_TOKEN,
    REFRESHING_TOKEN,
    ACCESS_TOKEN_EXPIRES_AT,
    REFRESH_TOKEN_EXPIRES_AT,
    GET_STREAM_TOKEN

} from "./types";
import * as Utils from "../components/utils/Utils";

const sleep = (time) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, time)
    })
}

const INITIAL_STATE = {
    [ACCESS_TOKEN]: "",
    [REFRESH_TOKEN]: "",
    [REFRESHING_TOKEN]: false,
    [ACCESS_TOKEN_EXPIRES_AT]: "",
    [REFRESH_TOKEN_EXPIRES_AT]: ""

};

export const state = {...INITIAL_STATE};

export const actions = {
    // isAccessTokenExpired({state, getters}) {
    //     const accessTokenExpiryAt = moment(getters.getAccessTokenExpiresAt, 'YYYY-MM-DD H:m:s')
    //     return moment(moment().format('YYYY-MM-DD H:m:s'), 'YYYY-MM-DD H:m:s') > accessTokenExpiryAt;
    // },
    // isRefreshTokenExpired({getters}) {
    //     const refreshTokenExpiryAt = moment(getters.getRefreshTokenExpiresAt, 'YYYY-MM-DD H:m:s')
    //     return moment(moment().format('YYYY-MM-DD H:m:s'), 'YYYY-MM-DD H:m:s') > refreshTokenExpiryAt;
    // },
    // async getAccessToken1({state, commit, getters, dispatch}) {
    //     let count = 0;
    //     while(getters.isRefreshingToken && count < 10) {
    //         await sleep(1000);
    //         count++
    //     }
    //     if(getters.isRefreshTokenExpired) {
    //         return null;
    //     }
    //     if(await getters.isAccessTokenExpired) {
    //         await dispatch("refreshAccessToken")
    //     }
    //     return await getters.getAccessToken;
    // },
    async refreshAccessToken({state, commit, getters}) {
        if (getters.isRefreshTokenExpired) {
            // logout user, clear store and everything
        }
        let formData = new FormData()
        formData.append("refresh-token", getters.getRefreshToken);
        const {data: {token, refreshToken, expiresIn}} = await refreshToken(formData);
        await commit("SET_ACCESS_TOKEN", token);
        await commit("SET_REFRESH_TOKEN", refreshToken);
        await commit("SET_ACCESS_TOKEN_EXPIRES_AT", expiresIn);
        await commit("SET_REFRESH_TOKEN_EXPIRES_AT");
    },
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
    SET_ACCESS_TOKEN(state, payload) {
        state[ACCESS_TOKEN] = payload;
    },
    SET_REFRESH_TOKEN(state, payload) {
        state[REFRESH_TOKEN] = payload;
    },
    SET_REFRESHING_TOKEN(state, payload) {
        state[REFRESHING_TOKEN] = payload;
    },
    SET_ACCESS_TOKEN_EXPIRES_AT(state, seconds) {
        const expiresAt = moment().add(seconds, 'seconds')
        state[ACCESS_TOKEN_EXPIRES_AT] = expiresAt.format('YYYY-MM-DD H:m:s')
    },
    SET_REFRESH_TOKEN_EXPIRES_AT(state) {
        const expiresAt = moment().add(1, 'days')
        state[REFRESH_TOKEN_EXPIRES_AT] = expiresAt.format('YYYY-MM-DD H:m:s')
    },
    CLEAR_TOKEN_STORE(state) {
        state[ACCESS_TOKEN] = null;
        state[REFRESH_TOKEN] = null;
        state[REFRESHING_TOKEN] = false;
        state[ACCESS_TOKEN_EXPIRES_AT] = null;
        state[REFRESH_TOKEN_EXPIRES_AT] = null;
    }
};

const getters = {
    getAccessToken(state) {
        let _state = Utils.parseState(state);
        return _state[ACCESS_TOKEN];
    },
    getRefreshToken(state) {
        let _state = Utils.parseState(state);
        return _state[REFRESH_TOKEN];
    },
    isRefreshingToken(state) {
        let _state = Utils.parseState(state);
        return _state[REFRESHING_TOKEN];
    },
    getAccessTokenExpiresAt(state) {
        let _state = Utils.parseState(state);
        return _state[ACCESS_TOKEN_EXPIRES_AT];
    },
    getRefreshTokenExpiresAt(state) {
        let _state = Utils.parseState(state);
        return _state[REFRESH_TOKEN_EXPIRES_AT];
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
