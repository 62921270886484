const appSettings = {
    app_url: process.env.VUE_APP_CLIENT_URL,
    base_url: process.env.VUE_APP_BASE_URL,
    app_key: process.env.VUE_APP_KEY,
    image_url: process.env.VUE_APP_IMAGE_URL,
    post_image_url: process.env.VUE_APP_POST_IMAGE_URL,
    google_location_api : process.env.VUE_APP_GOOGLE_LOCATION_API,
    get_stream_api_key : process.env.VUE_APP_STREAM_API_KEY,
    get_stream_api_secret : process.env.VUE_APP_STREAM_API_SECRAT,
    get_stream_api_app_id : process.env.VUE_APP_STREAM_API_ID
};

let variable = appSettings;

export default variable;

