<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div class="right-setting-bar">
      <div class="setting-header">
        <div class="setting-header-inner d-flex align-items-center">
          <div class="svg-icon">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M14.6668 8.00016C14.6668 11.6821 11.6821 14.6668 8.00016 14.6668C4.31826 14.6668 1.3335 11.6821 1.3335 8.00016C1.3335 4.31826 4.31826 1.3335 8.00016 1.3335C11.6821 1.3335 14.6668 4.31826 14.6668 8.00016ZM9.00016 4.00016C9.00016 3.44788 8.55245 3.00016 8.00016 3.00016C7.44788 3.00016 7.00016 3.44788 7.00016 4.00016V8.00016C7.00016 8.37893 7.21417 8.7252 7.55295 8.89459L10.2196 10.2279C10.7136 10.4749 11.3143 10.2747 11.5613 9.78071C11.8082 9.28673 11.608 8.68606 11.114 8.43907L9.00016 7.38213V4.00016Z"
                fill="#47505B"></path>
            </svg>
          </div>
          <p class="ml-3 mb-0">Availability & rate</p>
          <button class=" btn-view ml-auto" type="button" @click="redirectToProfilePage(username)">
            <span class="pr-2">
              <EyeIcon />
            </span>
            View Profile
          </button>
        </div>
        <div class="setting-content-inner">
          <form class="setting-form" @submit="submit">
            <div v-if="message" class="alert alert-danger" role="alert">
              {{ message }}
            </div>
            <div v-if="success_message" class="alert alert-success" role="alert">
              {{ success_message }}
            </div>
            <div class="row">
              <div class="col-12">
                <div class="expert-label expert-label1">
                  <div class="form-group">
                    <label class="label-title">I am currently</label>
                    <ul class="mb-0">
                      <li>
                        <div class=" custom-radio expert-radio">
                          <input type="radio" id="Entry" name="radio-group" v-model="user_currently_available"
                            value="available">
                          <label for="Entry">Available</label>
                        </div>
                      </li>
                      <li>
                        <div class=" custom-radio expert-radio">
                          <input type="radio" id="Intermediate" name="radio-group" v-model="user_currently_available"
                            value="intermediate">
                          <label for="Intermediate">Not Available</label>
                        </div>
                      </li>
                      <li>
                        <div class=" custom-radio expert-radio">
                          <input type="radio" id="Expert" name="radio-group" v-model="user_currently_available"
                            value="expert">
                          <label for="Expert">Expert</label>
                        </div>
                      </li>
                    </ul>
                    <label class="error_msg error_msg_red" v-if="_handleErrorMessage('user_currently_available')">{{
                      _handleErrorMessage("user_currently_available") }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="expert-label expert-label1">
                  <div class="form-group">
                    <label class="label-title">Availability</label>
                    <ul class="mb-0">
                      <li>
                        <div class=" custom-radio expert-radio">
                          <input type="radio" id="week" name="radio-group1" v-model="availability"
                            value="more_than_30_hrs_week">
                          <label for="week">More than 30 hrs/week</label>
                        </div>
                      </li>
                      <li>
                        <div class=" custom-radio expert-radio">
                          <input type="radio" id="hr" name="radio-group1" v-model="availability"
                            value="less_than_30_hrs_week">
                          <label for="hr">Less than 30 hrs/week</label>
                        </div>
                      </li>
                      <li>
                        <div class=" custom-radio expert-radio">
                          <input type="radio" id="offers" name="radio-group1" v-model="availability"
                            value="as_needed_open_to_offers">
                          <label for="offers">As needed - open to offers</label>
                        </div>
                      </li>
                    </ul>
                    <label class="error_msg error_msg_red" v-if="_handleErrorMessage('availability')">{{
                      _handleErrorMessage("availability") }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="expert-label expert-label1">
                  <div class="form-group">
                    <ul>
                      <li>
                        <div class="rate-hour">
                          <label class="label-title">Rate (per hour)</label>
                          <input type="text" v-model="rate" @keypress="onChange">
                          <span>USD</span>
                        </div>
                        <label class="avail-error" v-if="_handleErrorMessage('rate')">{{ _handleErrorMessage("rate")
                          }}</label>
                      </li>
                      <li>
                        <div class="rate-hour youget">
                          <label class="label-title">You’ll get (-10% fee)</label>
                          <input type="text" v-model="you_will_get" @keypress="onChange">
                          <span>USD</span>
                        </div>
                        <label class="avail-error" v-if="_handleErrorMessage('you_will_get')">{{
                          _handleErrorMessage("you_will_get") }}</label>
                      </li>
                      <li>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col text-right">
                <button type="submit" class="save-btn">{{ loading ? "Please Wait..." : "Save Changes" }}</button>
              </div>
            </div>
            
          </form>
        </div>

      </div>
      <div class=""></div>
    </div>
    <full-page-loader :show="loading" />
  </div>
</template>

<script type="application/javascript">
import { mapActions, mapMutations, mapGetters } from 'vuex';
import * as Utils from '../../../components/utils/Utils';
import * as Helper from '../../../apis/Helper.js';
import { EDIT_PROFILE_ERRORS, USER_ROOT, USER_DATA, USER_KEY, SUCCESS, ERROR } from '../../../store/types';
import EyeIcon from '../../../assets/svgs/EyeIcon.vue';

export default {
  components: {
    EyeIcon,
  },

  data() {
    return {
      user_currently_available: "",
      availability: "",
      rate: "",
      you_will_get: "",
      loading: false,
      message: "",
      success_message: ""
    }
  },
  filters: {
    filterNumericWords: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      form_data: "edit_profile_form_map_to_props",
      user: "user_map_to_props"
    }),
    // loading() {
    //   const {loading = false} = this.form_data || {};
    //
    //   return loading;
    // },
    errors() {
      const { errors = [] } = this.form_data || {};

      return errors;
    }
  },
  methods: {
    ...mapActions([
      "availability_and_rate",
      "fetchUserData"
    ]),
    ...mapMutations([
      "updateEditProfileFormData",
      "updateEditProfileUIConstraints"
    ]),
    init() {
      this.loading = true;
      const { user_data } = this.user || {};
      const { availability_time, rateAfterDeduction, rate, availability } = user_data || {};

      if (availability) this.user_currently_available = availability;
      if (rate) this.rate = rate;
      if (availability_time) this.availability = availability_time;
      if (rateAfterDeduction) this.you_will_get = rateAfterDeduction;
      this.loading = false;
    },
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex(ele => ele.fieldName === key) > -1
          ? {
            status: true,
            message:
              this.errors[this.errors.findIndex(ele => ele.fieldName === key)].message
          }
          : { status: false, message: "" };
      } else return { status: false, message: "" };
    },
    _handleErrorMessage(key) {
      const { message = "" } = this.isError(key);

      return message;
    },
    onChange(e) {
      let { key } = e;

      const value = key === "." ? key : key.replace(/\D/gi, "");

      if (value) return true;

      return e.preventDefault();
    },
    submit(e) {
      if (e) e.preventDefault();

      const { user_data } = this.user || {};
      const { id } = user_data || {};
      const { loading, user_currently_available, availability, rate, you_will_get } = this;

      if (loading) return;

      let body = {
        user_currently_available,
        availability,
        rate,
        you_will_get
      };

      Helper.validate(Object.keys(body), body)
        .then(async ({ status, response }) => {
          if (status) {
            this.updateEditProfileUIConstraints({
              [EDIT_PROFILE_ERRORS]: []
            });
            try {
              this.message = "";
              this.loading = true;
              const { status, message } = await this.availability_and_rate(body);
              this.loading = false;
              switch (status) {
                case SUCCESS:
                  this.success_message = message;
                  await this.fetchUserData();
                  setTimeout(() => {
                    this.success_message = ""
                  }, 5000);
                  break;
                case ERROR:
                  this.message = message;
                  break;
              }
            } catch (e) {
              this.message = e && e.message ? e.message : "Please try again.";
            }
          } else this.updateEditProfileUIConstraints({
            [EDIT_PROFILE_ERRORS]: response && response.length ? response : []
          });
        })
        .catch(err => console.log(err));
    }
  }
};
</script>
