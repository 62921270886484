<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div class="right-setting-bar">
      <div class="setting-header">
        <div class="setting-header-inner d-flex align-items-center">
          <div class="svg-icon">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.5127 7.76843L15.1794 1.76843L9.17936 14.4351L7.84603 9.10177L2.5127 7.76843Z" fill="#47505B"
                    stroke="#47505B" stroke-width="1.5"></path>
            </svg>
          </div>
          <p class="ml-3 mb-0">Language and region</p>
        </div>
        <div class="languages-list">
          <div v-if="message" class="alert alert-danger my-2" role="alert">
            {{ message }}
          </div>
          <div v-if="success_message" class="alert alert-success my-2" role="alert">
            {{ success_message }}
          </div>
          <ul>
            <li>
              <div class="language-content">
                <h1>AudioGround Language</h1>
                <p>Choose the main language you would like to communicate with on your AUDDIO account.</p>
              </div>
              <div class="">
                <div>
                  <SelectDropdown
                    :options="languages"
                    v-model="audio_ground_language"
                    @change="((value) => audio_ground_language = value)"
                    :style="'width: 130px; border: 1px solid #E6EAF0;'"
                  />
                </div>
              </div>
            </li>
            <li>
              <div class="language-content">
                <h1>Region</h1>
                <p>We use your region for format for dates, time and numbers</p>
              </div>
              <div class="">
                <div>
                  <SelectDropdown
                    :options="regions"
                    v-model="region"
                    @change="((value) => region = value)"
                    :style="'width: 130px; border: 1px solid #E6EAF0;'"
                  />
                </div>
              </div>
            </li>
            <li>
              <div class="language-content">
                <h1>Posts and Articles</h1>
                <p>Language you’d like to have posts translated into</p>
              </div>
              <div class="">
                <div>
                  <SelectDropdown
                    :options="languages"
                    v-model="posts_and_articles"
                    @change="((value) => posts_and_articles = value)"
                    :style="'width: 130px; border: 1px solid #E6EAF0;'"
                  />
                </div>
              </div>
            </li>

          </ul>
          <div class="row mb_30px pr_20px">
            <div class="col text-right">
              <button type="button" @click="submit" class="save-btn">{{
                  loading ? "Please Wait..." : "Save Changes"
                }}
              </button>
            </div>
          </div>
        </div>

      </div>
      <div class=""></div>
    </div>
  </div>
</template>

<script type="application/javascript">
import {mapActions, mapMutations, mapGetters} from 'vuex';
import * as Utils from '../../../components/utils/Utils';
import * as Helper from '../../../apis/Helper.js';
import {update_language_api, get_languages_api, get_regions_api} from '../../../apis/APIs';
import {EDIT_PROFILE_ERRORS, USER_ROOT, USER_DATA, USER_KEY, SUCCESS, ERROR} from '../../../store/types';
import SelectDropdown from "../../Common/Dropdown/SelectDropdown.vue";

export default {
  components:{
    SelectDropdown
  },
  data() {
    return {
      languages: [],
      regions: [],
      list_loading: false,

      audio_ground_language: "",
      region: "",
      posts_and_articles: "",

      message: "",
      success_message: "",
      loading: false
    }
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      form_data: "edit_profile_form_map_to_props",
      "user": "user_map_to_props"
    }),
    errors() {
      const {errors = []} = this.form_data || {};

      return errors;
    }
  },
  methods: {
    ...mapActions([
      "fetchUserData"
    ]),
    ...mapMutations([
      "updateEditProfileFormData",
      "updateEditProfileUIConstraints"
    ]),
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex(ele => ele.fieldName === key) > -1
            ? {
              status: true,
              message:
              this.errors[this.errors.findIndex(ele => ele.fieldName === key)].message
            }
            : {status: false, message: ""};
      } else return {status: false, message: ""};
    },
    _handleErrorMessage(key) {
      const {message = ""} = this.isError(key);

      return message;
    },
    async init() {
      await this.fetchLanguagesData();
      await this.fetchRegionsData();
      this.initData();
    },
    async fetchLanguagesData() {
      try {
        this.list_loading = true;
        const res = await get_languages_api();
        const {success, message, data} = res.data || {};
        this.list_loading = false;

        if (success === 1) {
          if (data && data.length) this.languages = data.map(ele => ({value: ele.id, label: ele.native_name}));
        }
      } catch (error) {
        this.list_loading = false;
      }
    },
    async fetchRegionsData() {
      try {
        this.list_loading = true;
        const res = await get_regions_api();
        const {success, message, data} = res.data || {};
        this.list_loading = false;

        if (success === 1) {
          if (data && data.length) this.regions = data.map(ele => ({value: ele.id, label: ele.name}));
        }
      } catch (error) {
        this.list_loading = false;
      }
    },
    initData() {
      const {user_data} = this.user || {};
      const {id, language, region, post_article_language} = user_data || {};

      if (language && language.length) this.audio_ground_language = language[0].id;
      this.region = this.regions.find((r)=> r.value == region);
      this.posts_and_articles = this.languages.find((p)=> p.value == post_article_language);
    },
    async submit(e) {
      if (e) e.preventDefault();

      const {loading, audio_ground_language, region, posts_and_articles} = this;

      const {user_data} = this.user || {};
      const {id} = user_data || {};

      if (loading) return;

      try {
        // const query = `?id=${user_id}&lang_audiogound_language=${audio_ground_language}&lang_region=${region}&lang_posts_articles=${posts_and_articles}`;
        var formdata = new FormData();
        formdata.append("language", audio_ground_language);
        formdata.append("region", region);
        formdata.append("post_article_language", posts_and_articles);
        this.message = "";
        this.loading = true;
        const res = await update_language_api(formdata);
        const {success, message, data} = res.data || {};

        if (success === 1) {
          this.success_message = message;
          this.$store.commit("updateUserUIConstraints", ({
            [USER_DATA]: data
          }));
          this.fetchUserData();
          setTimeout(() => this.success_message = "", 5000);
        } else {
          this.message = message;
        }

        this.loading = false;
      } catch (error) {
        this.loading = false;
      }

    }
  }
};
</script>
