<template>
  <div class="">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <h1 class="Page_title">{{ header ? "EXPLORE" : "" }}</h1>
        </div>
      </div>
    </div>
    <div class="content discover fixConttentDiscover">
      <div class="">
        <div class="col px-0">
          <div
            class="page-filter d-flex d-flex flex-row align-items-center p-r-20"
          >
            <div class="back-link">
              <a
                @click="goBack"
                class="d-flex flex-row align-items-center cursor-pointer"
              >
                <div class="svg-icon">
                  <svg
                    width="7"
                    height="14"
                    viewBox="0 0 7 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="c-line-1"
                      d="M5.8645 12.7285L0.135502 7.00052L5.8645 1.27152"
                      stroke="#071526"
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>
                {{ pageFrom }}
              </a>
            </div>
            <div class="filter-title">
              {{ pageTitle }}
            </div>
            <div class="filter-update-text" v-if="titleDescription">
              {{ titleDescription }}
            </div>
            <div class="d-flex flex-wrap align-items-center ml-auto">
              <div
                class="change-view js-change-view"
                v-if="pageFrom !== 'reviews'"
              >
                <div class="btn-group m-r-20">
                  <div
                    data-type="grid"
                    :class="[
                      'btn btn-svg-icon js-set-view',
                      listing_type == 'grid' ? 'active' : '',
                    ]"
                    v-on:click="showForm('grid')"
                  >
                    <div data-type="grid" class="svg-icon show-grid grid-svd">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30 0H0V30H30V0Z"
                          fill="#D1433A"
                          class="c-fill-1"
                        ></path>
                        <path
                          d="M16.5 13.5V8.5H21.5V13.5H16.5Z"
                          stroke="white"
                          stroke-linecap="square"
                          class="c-line-1"
                        ></path>
                        <path
                          d="M8.5 13.5V8.5H13.5V13.5H8.5Z"
                          stroke="white"
                          stroke-linecap="square"
                          class="c-line-1"
                        ></path>
                        <path
                          d="M16.5 21.5V16.5H21.5V21.5H16.5Z"
                          stroke="white"
                          stroke-linecap="square"
                          class="c-line-1"
                        ></path>
                        <path
                          d="M8.5 21.5V16.5H13.5V21.5H8.5Z"
                          stroke="white"
                          stroke-linecap="square"
                          class="c-line-1"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div
                    :class="[
                      'btn btn-svg-icon js-set-view',
                      listing_type == 'list' ? 'active' : '',
                    ]"
                    v-on:click="showForm('list')"
                  >
                    <div data-type="list" class="svg-icon show-list grid-svd">
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30 0H0V30H30V0Z"
                          fill="white"
                          class="c-fill-1"
                        ></path>
                        <path
                          d="M8.5 12.5V8.5H21.5V12.5H8.5Z"
                          stroke="#8B949F"
                          stroke-linecap="square"
                          class="c-line-1"
                        ></path>
                        <path
                          d="M8.5 21.5V17.5H21.5V21.5H8.5Z"
                          stroke="#8B949F"
                          stroke-linecap="square"
                          class="c-line-1"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="
                  text
                  with-icon
                  d-flex
                  justify-content-between justify-content-end
                  align-items-center
                "
              >
                Sort
                <div class="svg-icon">
                  <svg
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="9.51351"
                      height="2.16216"
                      rx="1.08108"
                      fill="#D1433A"
                    />
                    <rect
                      y="5.18945"
                      width="16"
                      height="2.16216"
                      rx="1.08108"
                      fill="#D1433A"
                    />
                    <rect
                      y="10.3779"
                      width="12.973"
                      height="2.16216"
                      rx="1.08108"
                      fill="#D1433A"
                    />
                  </svg>
                </div>
              </div>
              <div
                class="
                  dropdown
                  bootstrap-select
                  show-tick
                  filters-select
                  for-overview
                "
              >
                <button
                  type="button"
                  class="btn dropdown-toggle btn-light bs-placeholder"
                  data-toggle="dropdown"
                  role="combobox"
                  aria-owns="bs-select-1"
                  aria-haspopup="listbox"
                  aria-expanded="false"
                  title="Featured"
                >
                  <div class="filter-option">
                    <div class="filter-option-inner">
                      <div class="filter-option-inner-inner">
                        {{ selectedSortByFilter }}
                      </div>
                    </div>
                  </div>
                </button>
                <div class="dropdown-menu">
                  <div
                    class="inner show"
                    role="listbox"
                    id="bs-select-1"
                    tabindex="-1"
                    aria-multiselectable="true"
                  >
                    <ul class="dropdown-menu inner show" role="presentation">
                      <li
                        v-for="(category, index) in sortedByCategories"
                        :key="index"
                      >
                        <a
                          @click="
                            selectOrUnSelectValue(category.value, 'sortBy')
                          "
                          :class="{
                            'active selected': isSelectedValue(
                              category.value,
                              'sortBy'
                            ),
                          }"
                          role="option"
                          class="dropdown-item"
                          id="bs-select-5-0"
                          tabindex="0"
                          aria-selected="false"
                          aria-setsize="6"
                          aria-posinset="1"
                          ><span class="bs-ok-default check-mark"></span
                          ><span class="text">{{ category.name }}</span></a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="col-12 px-0 sale-items m-t-20">
          <div class="row position-relative" v-if="listing_type == 'grid'" :class="{'grid-view': listing_type == 'grid'}">
            <div class="col-12">
              <GridSelling6
                :header="false"
                :hasHeart="false"
                :listData="allData"
                :isStreaming="componentType == 'stream' ? true : false"
              />
            </div>
          </div>
          <div class="row position-relative" v-if="listing_type == 'list'" :class="{'grid-view': listing_type == 'list'}">
            <div class="col-12" v-if="allData.length > 0">
              <FullPageMusic
                :listData="allData"
                :type="'song'"
                :stream="isStreaming"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-2 text-center" v-if="loading">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-3 hot-song-showmore-btn" v-if="totalRows > allData.length">
        <div class="col-12 px-0">
          <div class="show-more musicbar_more mt-0">
            <a
              href="javascript:;"
              @click="GetChannelData(getChannelName, true)"
            >
              <span class="show_more_bg">
                <svg
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.24219 0.689941V7.01994"
                    stroke="#071526"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M1 3.85498H7.48702"
                    stroke="#071526"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
                SHOW MORE
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <notifications group="foo" class="notification-style" />
  </div>
</template>
<script type="application/javascript">
import GridSelling6 from "@/components/Common/Elements/GridSelling6";
import SingleMusicListView from "@/components/Common/Elements/SingleMusicListView";
import FullPageMusic from "@/components/DesignGuide/Music/Components/FullPageMusic";

import { getPageData } from "../../../../apis/APIs";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SongAndAlbumChannelWithGridAndList",
  data() {
    return {
      listing_type: "grid",
      allData: [],
      dataLoaded: false,
      loading: false,
      limit: 12,
      offset: 0,
      totalRows: 0,
      componentType: "buy",
      filterApplied: "Latest",
      user_id: "",
      sortBy: "trending",
      sortedByCategories: [
        { value: "trending", name: "Trending" },
        { value: "latest", name: "Latest" },
        { value: "featured", name: "Featured" },
        { value: "low_rating", name: "Low Rating" },
        { value: "high_rating", name: "High Rating" },
        { value: "top_selling", name: "Top Selling" },
      ],
    };
  },

  components: {
    FullPageMusic,
    GridSelling6,
  },

  props: {
    filterUsed: String,
    redirectUrl: String,
    activeHeader: String,
    thirdTxt: String,
    pageFilter: {
      type: String,
      default: "",
    },
    header: Boolean,
  },
  computed: {
    ...mapGetters({
      user: "user_map_to_props",
    }),
    pageFrom() {
      const { fromPage } = this.$route.query;
      if (fromPage) {
        if (fromPage === "reviews") {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.filterApplied = "Featured";
        }
        return fromPage;
      }
      return "Back";
    },
    pageTitle() {
      const { title } = this.$route.query;
      if (title) {
        return title;
      } else if (this.getChannelName) {
        return this.ChannelTitle;
      }
      return "Title";
    },
    titleDescription() {
      const { description } = this.$route.query;
      return description ? description : null;
    },
    getChannelName() {
      const { channelName } = this.$route.params;
      return channelName ? channelName : null;
    },
    ChannelTitle() {
      const { channelName } = this.$route.params;
      if (channelName) {
        return channelName.replaceAll("-", " ");
      }
      return "Title";
    },
    isStreaming() {
      return this.$store.getters.isMusicModeIsStreaming;
    },
    selectedSortByFilter() {
      if (this.sortBy) {
        const index = this.sortedByCategories.findIndex(
          (e) => e.value === this.sortBy
        );
        return this.sortedByCategories[index].name;
      }
      return "Sort by";
    },
  },
  mounted: function () {
    if (localStorage.getItem("componentType")) {
      this.componentType = localStorage.getItem("componentType");
    }
    if (this.getChannelName) {
      this.GetChannelData(this.getChannelName);
    }
  },
  watch: {
    sortBy: {
      handler(val) {
        this.GetChannelData(this.getChannelName, false, true);
      },
    },
  },
  methods: {
    ...mapActions(["fetchUserData"]),
    isSelectedValue(value, type) {
      return this.$data[type] === value;
    },
    selectOrUnSelectValue(value, type) {
      if (this.$data[type]) {
        this.$data[type] = this.$data[type] === value ? null : value;
      } else {
        this.$data[type] = value;
      }
    },
    async GetChannelData(channelName, showMore = false, reset = false) {
      try {
        this.loading = true;
        if (reset) {
          this.offset = 0;
          this.totalRows = 0;
          this.allData = [];
        }
        if (showMore) {
          this.offset = parseInt(this.offset) + this.limit;
        }
        let { data } = await getPageData(
          channelName,
          this.limit,
          this.offset,
          this.sortBy
        );
        this.allData = showMore
          ? [...this.allData, ...data.channels.center.objects.data]
          : data.channels.center.objects.data;
        this.totalRows = data.channels.center.objects.total;
        this.dataLoaded = true;
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    showForm: function (type) {
      this.listing_type = type;
    },
    redirect: function (url) {
      this.$router.push({ name: url });
    },
    async updateFilter(currentFilter) {
      this.filterApplied = currentFilter;
      let user_id = this.user.user_data.user_id;
      try {
        this.loading = true;
        this.offset = 0;
        let data = await getPageData(
          this.$route.name ? this.$route.name : null,
          this.limit,
          this.offset,
          this.sortBy
        );
        this.allData = data.channels.center.objects.data;
        this.totalRows = data.channels.center.objects.total;
        this.dataLoaded = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.fixConttentDiscover{
  overflow: hidden;
  width: 1170px;
  margin: 0 auto;
}

.overview-content {
  .notification-style {
    margin-top: 5rem;
  }
}

</style>
