<template>
  <div>
    <div id="stream" class="flex items-center justify-center w-full">
      <!-- video will be added here -->
    </div>
    <div id="player" class="flex items-center justify-center w-full">
      <!-- video will be added here -->
    </div>
    <input type="text" v-model="token"/>
    <button class="btn" @click="joinStream">Join Stream</button>
    <button class="btn" @click="joinAsASpeaker">Join As Speaker</button>
    <button class="btn" @click="watchStream">Watch Stream</button>
    <button class="btn" @click="disconnect">End Stream</button>
    <button class="btn" @click="leaveStream">Leave Stream</button>
    <button class="btn" @click="muteUnmute">Mute/Un-mute</button>
  </div>
</template>

<script>

import VideoThumbnail from "./Common/VideoThumbnail/VideoThumbnail.vue";
// const { connect, createLocalVideoTrack } = require('twilio-video');


let liveNotification = document.createElement('div');
liveNotification.innerHTML = 'LIVE';
liveNotification.id = 'liveNotification';
liveNotification.classList.add('absolute', 'top-10', 'left-48', 'p-2', 'bg-red-500', 'text-white');

export default {
  name: "TestPage",
  data() {
    return {
      messaging_api_key: 'wewu8maq2gnz',
      messaging_app_secrete : 'mfvcv7tx326rey38x83776swyv94feyn7tqq7hwwwdadu8mz9afax64j9ed2twf8',
      messaging_app_id: '1186900',
      feedback: '',
      reaction: 'natural',
      isActive: false,
      isDisabled: false,
      videoTrack: null,
      mute: true,
      room_sid: "",
      token: ''
    }
  },
  components: {
  },
  methods: {
    async watchStream() {
      const streamPlayer = document.getElementById('player');
      const player = await this.watchStreaming(this.token)
      streamPlayer.appendChild(player.videoElement);
    },
    leaveStream() {
      this.leaveStream()
    },
    disconnect() {
      liveNotification.remove();
      this.endStream()
    },
    muteUnmute() {
     this.mute = !this.mute
      this.muteAndUnmuteOwnMice(this.mute)
    },
    async addLocalVideo() {
      return await createLocalVideoTrack()
    },
    async joinAsASpeaker() {
      const stream = document.getElementById('stream');
      const video = document.getElementsByTagName('video')[0];
      const videoTrack = await this.addLocalVideo()
      const trackElement = videoTrack.attach();
      stream.appendChild(trackElement);
      await this.joinAsSpeaker('RM85e61be8216702065208e302e22838b6')
      stream.insertBefore(liveNotification, video);
    }
  },
};
</script>

<style scoped>
</style>
