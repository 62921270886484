<template>
  <div class="wrap-right-part">
    <div class="related-events-wrapper">
      <div class="title-and-show-all">
      <span class="title">
        {{ title_section }}
      </span>
        <div class="show-all">
          <span>Show More</span>
          <span class="for_hover_gray_btn">
          <svg
            width="7"
            height="11"
            viewBox="0 0 7 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.01562 1L6.1515 5.173L1.01562 9.713"
              stroke="#47505B"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        </div>
      </div>
      <div
        v-for="item in dataRelatedEvents"
        :key="item.id"
        class="related-event-content-section"
      >
        <div class="content_info">
          <router-link :to="{ name: 'events.Detail', params: { id: item.id } }">
          <div class="div-img">
            <img :src="item.artwork_url" alt="event img" />
          </div>
          </router-link>
          <div class="info-part">
            <div class="when">
              {{ started_at_date(item.started_at) }}
              <span v-if="title_section === 'Related Events'"
              ><svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.56225 8.064C8.51237 8.064 10.0932 6.48267 10.0932 4.532C10.0932 2.58133 8.51237 1 6.56225 1C4.61213 1 3.03125 2.58133 3.03125 4.532C3.03125 6.48267 4.61213 8.064 6.56225 8.064Z"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.01197 10.9998C1.01197 10.9998 0.573983 8.51785 4.99598 8.42285C4.99598 8.42285 7.99595 8.42285 8.12995 8.42285C12.23 8.42285 12.276 10.9998 12.276 10.9998"
                  stroke="white"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {{ item.going + item.interested }}</span
              >
            </div>
            <router-link :to="{ name: 'events.Detail', params: { id: item.id } }"><h5>{{ item.title }}</h5></router-link>
            <h6>{{ item.location }}</h6>
            <p>{{ event_time(item.start_time , item.end_time) }}</p>
            <article class="wr_btns_and_price">
              <div v-if="title_section === 'Related Events'" class="btnDiv">
                <button>Interested</button>
                <span></span>
                <button>Going</button>
              </div>
              <div v-if="title_section != 'Related Events'" class="price_info">
                Sold: 240 / 280 • Gross: $ 1,024
              </div>
            </article>
          </div>
        </div>
        <section
          v-if="title_section !== 'Related Events'"
          class="wr_dashboard_btn"
        >
          <button class="for_hover_blue_btn">DASHBOARD</button>
          <div>
            <button class="for_hover_empty_border_blue">EDIT</button>
            <span class="three_dots_menustyle for_hover_gray_btn"
            ><svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.99967 8.66634C8.36786 8.66634 8.66634 8.36786 8.66634 7.99967C8.66634 7.63148 8.36786 7.33301 7.99967 7.33301C7.63148 7.33301 7.33301 7.63148 7.33301 7.99967C7.33301 8.36786 7.63148 8.66634 7.99967 8.66634Z"
                fill="#47505B"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.99967 4.00033C8.36786 4.00033 8.66634 3.70185 8.66634 3.33366C8.66634 2.96547 8.36786 2.66699 7.99967 2.66699C7.63148 2.66699 7.33301 2.96547 7.33301 3.33366C7.33301 3.70185 7.63148 4.00033 7.99967 4.00033Z"
                fill="#47505B"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.99967 13.3333C8.36786 13.3333 8.66634 13.0349 8.66634 12.6667C8.66634 12.2985 8.36786 12 7.99967 12C7.63148 12 7.33301 12.2985 7.33301 12.6667C7.33301 13.0349 7.63148 13.3333 7.99967 13.3333Z"
                fill="#47505B"
                stroke="#47505B"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import dateFormats from "@/mixins/dateFormats.js";
export default {
  mixins: [dateFormats],
  computed: {
    ...mapGetters({
      related_Events: 'GET_RELATED_EVENTS',
    }),
    dataRelatedEvents() {
      return this.related_Events ? this.related_Events.data || [] : [];
    },
    started_at_date() {
      return (start_date) => this.monthOnly(start_date) + ' ' + this.dayOnly(start_date);
    },
    title_section() {
      return this.$route.path.includes("/event-manager/page/dashboard")
        ? "Upcoming Events"
        : "Related Events";
    },

  },
  methods: {
    ...mapActions(["getRelatedEvents", "InterestedGoing"]),
    async fetchRelatedEvents() {
      try {
        const eventId = this.$route.params.id;
        await this.getRelatedEvents({
          event_id: eventId,
          offset: 0,
          limit: 3
        });
      } catch (error) {
        console.error('Failed to fetch related events:', error);
      }
    },

    handleInterest(params) {
      const event = this.dataRelatedEvents.find(event => event.id === params.event_id);
      if (event && params.interest !== event.user_interest) {
        this.InterestedGoing(params).then(() => {
          event.user_interest = params.interest;
        }).catch((error) => {
          console.error('Error in handleInterest:', error);
          this.notificationToast(true, "error", "Something went wrong");
        });
      }
    },
    event_time(start_time,end_time){
      return this.timeFormatAmPm(start_time) +' '+'till'+' '+ this.timeFormatAmPm(end_time);
    },
  },
  created() {
    this.fetchRelatedEvents();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/style/events/relatedeventscard.scss";
</style>
