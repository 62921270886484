<template>
  <div class="rating-component">
    <h3 class="heading">Rating</h3>
    <div v-for="rating in ratings" :key="rating.value" class="rating-item">
      <input
        type="checkbox"
        :id="'rating-' + rating.value"
        v-model="rating.checked"
        @change="toggleRating(rating.value)"
        class="checkbox"
      />
      <label :for="'rating-' + rating.value" class="rating-value">
        {{ rating.value }}
      </label>

      <div class="stars">
        <span
          v-for="star in rating.value"
          :key="star"
          :class="['star', { filled: rating.checked }]"
        >
          ★
        </span>
      </div>

      <div class="bar">
        <div
          class="filled-bar"
          v-if="rating.checked"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ratings: [
        { value: 1, checked: false },
        { value: 2, checked: false },
        { value: 3, checked: false },
        { value: 4, checked: false },
        { value: 5, checked: false },
      ],
    };
  },
  methods: {
    toggleRating(value) {
      this.ratings.forEach((rating) => {
        if (rating.value !== value) {
          this.$emit('rating-filter', value)
          rating.checked = false;
        }
      });
    },
  },
};
</script>

<style scoped>
label {
  margin-bottom: 0rem !important;
}
.rating-value {
  color: #47505B;
  font-family: HelveticaNeueNormal, sans-sarif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.rating-component {
  font-family: Arial, sans-serif;
  width: 100%;
}

.rating-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
}

.checkbox {
  appearance: none;
  width: 16px;
  height: 16px;
  background-color: white;
  border: 1px solid #C4CCD6;
  cursor: pointer;
  transition: background-color 0.3s ease, background-image 0.3s ease;
  margin-right: 14px;
  border-radius: 4px;
}

.checkbox:checked {
  background-color: #D1433A;
  border: 1px solid rgba(209, 67, 58, 0.5);
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='2' fill='%23D1433A'/%3E%3Cpath d='M4.32538 11.7566C3.37089 10.7894 4.72764 9.97541 5.68213 10.9426L7.68213 12.9426L11.6821 9.44212L14.6821 6.44262C15.7321 5.4754 16.8292 6.2435 15.8747 7.30744L8.62059 14.5616C8.2388 14.9485 7.57065 15.0452 7.18886 14.5616L4.32538 11.7566Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.checkbox:not(:checked) {
  background-color: white;
  background-image: none;
  
}

.heading {
  color: #47505B;
  font-family: HelveticaNeueBold, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.85px;
  margin-top: 13px;
  margin-bottom: 13px !important;
}

.stars {
  display: flex;
  margin-left: auto;
  margin-right: 5px;
  white-space: nowrap;
}

.star {
  font-size: 16px;
  color: #C4CCD6;
  margin-right: 2px;
}

.star.filled {
  color: #D1433A;
}

.bar {
  width: 169px;
  height: 3px;
  background-color: #C4CCD6;
  border-radius: 3px;
  margin-left: 10px;
  position: relative;
  max-width: 169px;
}

.filled-bar {
  height: 100%;
  background-color: #D1433A;
  border-radius: 3px;
  transition: width 0.3s ease;
}
</style>
