<template>
    <Mentionable
      :keys="['@', '#']"
      :items="items"
      offset="6"
      :limit="limit"
      insert-space
      placement="bottom-start"
      @open="onOpen"
      @apply="onApply"
      @search="searchData($event)"
      :style="[checkPost === 'color' ? {'width': '100%'  } :  {'width': '82%'}]"
    >
      <slot name="input"></slot>
      <slot name="textarea" v-if="checkPost === 'color'"></slot>
      <template #no-result>
        <div class="issue text-dark">
          {{ loading ? 'Loading...' : 'No result' }}
        </div>
      </template>
        <template #item-@="{ item }" >
          <div class="user">
            {{item.name}}
            <span class="dim">
          ({{ item.search_type }})
        </span>
          </div>
        </template>
      <template #item-#="{ item }">
        <div class="issue">
        <span class="dim">
          {{ item.name }}
        </span>
        </div>
      </template>
    </Mentionable>
</template>

<script>
import {Mentionable} from 'vue-mention'

import { getMentionsData, getMentionsList } from "../../../apis/APIs";

const users = [
  {
    value: 'akryum',
    firstName: 'Guillaume',
  },
  {
    value: 'posva',
    firstName: 'Eduardo',
  },
  {
    value: 'atinux',
    firstName: 'Sébastien',
  },
]
export default {
  name: "MentionedAndTagBox",
  computed: {
    tagsList() {
      return this.$store.getters.getPostTagsList.map(obj => {
        return {
          name: obj.tag,
          value: obj.tag,
        }
      })
    },
  },
  props: {
    checkPost: {
      type: String,
      default: ''
    },
    selectedTab: {
      type: String,
      default: ''
    }
  },
  components: {
    Mentionable,
  },
  data() {
    return {
      userLinks: [],
      wordsToRemove: [],
      text: '',
      items: [],
      loading: false,
      key: null,
      limit: 100
    }
  },
  methods: {
    onOpen(key) {
      this.items = []
      this.key = key
    },
    generateUserLinks(user){
      this.userLinks.push(`<a class="link-text" href="/home/page/user-profile/${user}"'>${user} </a>`)
    },
    generatePageLinks(page){
      this.userLinks.push(`<a class="link-text" href="/page-manager/page/${page.type}/${page.id}"'>${page.name} </a>`)
    },
    onApply(item, key, replacedWith) {
      if(item.search_type === 'user'){
        let val = this.$store.getters.getMentionedList.find(i => i === item.id)
        if(!val){
          this.generateUserLinks(item.name);
          this.wordsToRemove.push(0);
          this.$store.commit('SET_MENTION_LIST_WITH_IDS', item.id)
        }
      }
      if(item.search_type === 'page'){
        let val = this.$store.getters.getMentionedPages.find(i => i === item.id)
        if(!val){
          this.generatePageLinks(item)
          let Words = item.name.split(' ')
          this.wordsToRemove.push(Words.length - 1);
          this.$store.commit('SET_MENTION_PAGES_WITH_IDS', item.id)
        }
      }
      this.$emit("userPageLink", this.userLinks, this.wordsToRemove);
    },

    async getLocation(data) {
      this.user_location = data.locationName;
      this.$store.commit("SET_TYPE_DESCRIPTION", this.user_location);
      this.$store.commit("SET_TYPE", "check_in");
    },

    async searchData(searchText = null) {
      this.loading = true
      if (this.key === '#') {
        this.items = await this.loadTags(searchText)
      } else {
        this.items = await this.loadMentions(searchText)
      }
      this.loading = false
    },
    async loadTags(searchText) {
      if (!searchText) {
        return this.tagsList
      } else {
        const reg = new RegExp(searchText, 'i')
        return this.tagsList.filter(issue => reg.test(issue.name))
      }
    },
    async loadMentions(searchText) {
      if (!searchText) {
        return []
      } else {
        const {data} = await getMentionsData(searchText)
        if(data)
        return data.information.map(user => {
          return {
            id: user.id,
            name: user.name,
            value: user.name,
            search_type: user.search_type,
            type: (user.type) ? user.type : ''
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.v-popover.popper{
  position: absolute;
  top: 10px !important;
  left: 100px !important;
}
//.link-text:hover {
//  font-size: 13px;
//  /* text-underline:#D1433A ; */
//  text-decoration: underline;
//  font-weight: 500;
//  color: #D1433A;
//  cursor: pointer;
//}
.tooltip {
  background-color:transparent !important;

  .wrapper {
    background-color: white !important;

    .tooltip-inner {
      background-color: white !important;
    }

    .tooltip-outer {
      background-color: white;
    }
  }

  &[aria-hidden='true'] {
    background-color: white !important;
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }

  /*Your styles.*/

  display: block !important;
  z-index: 10000;
}

.mention-item {
  background-color: white;
  padding: 4px 10px;
  border-radius: 4px;
  color: black;
}

.mention-selected {
  background: #D1433A;
}
.tooltip {
  &.popover {
    &.vue-popover-theme {
      max-height: 300px !important;
      overflow-y: scroll !important;
    }
  }
}
.tooltip {
  &.popover {
    &.vue-popover-theme .text{
      max-height: 300px !important;
      overflow-y: scroll !important;
      margin-top: 150px;
      margin-left: 100px;
    }
  }
}
</style>
