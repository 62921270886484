<template>
  <div class="w-100 genere-moods-content">
    <div class="wrapper fixGenreMoodMain">
      <div class="row">
        <div class="col">
          <div class="overview-mains g-mood">
            <h1 class="Page_title gen-mood mb-unset"></h1>
            <div class="switch-field">
              <payStreamSwitch />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="gen-mood-tag fixGenMoodTag">
            <ul class="nav nav-tabs" id="myTab" role="tablist">

              <div class="moodDiv">
                <li class="nav-item">
                  <a class="nav-link" :class="{ active: activeTab === 'home' }" id="home-tab" data-toggle="tab"
                    href="#home" role="tab" aria-controls="home" aria-selected="activeTab === 'home'"
                    @click="setActiveTab('home')">
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H46C47.933 0.5 49.5 2.067 49.5 4V46C49.5 47.933 47.933 49.5 46 49.5H4C2.067 49.5 0.5 47.933 0.5 46V4Z"
                        fill="#F9FAFB" stroke="#E6EAF0" />
                      <path
                        d="M15.4524 18.4277C15.2026 18.4277 15 18.9861 15 19.6742V30.7527C15 31.4408 15.2026 31.9992 15.4524 31.9992C15.7021 31.9992 15.9048 31.4408 15.9048 30.7527V19.6742C15.9048 18.9854 15.7021 18.4277 15.4524 18.4277Z"
                        fill="#D1433A" stroke="#D1433A" stroke-width="1.3" />
                      <path
                        d="M19.9763 13C19.7266 13 19.5239 13.5713 19.5239 14.2754V35.2484C19.5239 35.9525 19.7266 36.5238 19.9763 36.5238C20.226 36.5238 20.4287 35.9525 20.4287 35.2484V14.2754C20.4287 13.5706 20.2263 13 19.9763 13Z"
                        fill="#D1433A" stroke="#D1433A" stroke-width="1.3" />
                      <path
                        d="M24.5 18.4277C24.2502 18.4277 24.0476 18.9861 24.0476 19.6742V30.7527C24.0476 31.4408 24.2502 31.9992 24.5 31.9992C24.7497 31.9992 24.9524 31.4408 24.9524 30.7527V19.6742C24.9524 18.9854 24.7497 18.4277 24.5 18.4277Z"
                        fill="#D1433A" stroke="#D1433A" stroke-width="1.3" />
                      <path
                        d="M29.0239 13C28.7742 13 28.5715 13.5713 28.5715 14.2754V35.2484C28.5715 35.9525 28.7742 36.5238 29.0239 36.5238C29.2737 36.5238 29.4763 35.9525 29.4763 35.2484V14.2754C29.4763 13.5706 29.2737 13 29.0239 13Z"
                        fill="#D1433A" stroke="#D1433A" stroke-width="1.3" />
                      <path
                        d="M33.5476 18.4277C33.2979 18.4277 33.0952 18.9861 33.0952 19.6742V30.7527C33.0952 31.4408 33.2979 31.9992 33.5476 31.9992C33.7973 31.9992 34 31.4408 34 30.7527V19.6742C34 18.9854 33.7976 18.4277 33.5476 18.4277Z"
                        fill="#D1433A" stroke="#D1433A" stroke-width="1.3" />
                    </svg>
                    <span> GENRES</span>
                  </a>
                </li>
              </div>

              <div class="moodDiv">
                <li class="nav-item">
                  <a class="nav-link" :class="{ active: activeTab === 'profile' }" id="profile-tab" data-toggle="tab"
                    href="#profile" role="tab" aria-controls="profile" aria-selected="activeTab === 'profile'"
                    @click="setActiveTab('profile')">
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H46C47.933 0.5 49.5 2.067 49.5 4V46C49.5 47.933 47.933 49.5 46 49.5H4C2.067 49.5 0.5 47.933 0.5 46V4Z"
                        fill="#F9FAFB" stroke="#E6EAF0" />
                      <path
                        d="M36.5 23.9535H32.05C31.84 23.9535 31.66 24.0372 31.59 24.1698L29.6 27.6791L26.64 19.2721C26.59 19.1186 26.39 19 26.15 19C25.92 19 25.72 19.1116 25.66 19.2651L22.2 29.0674L20.04 21.8116C19.99 21.6581 19.81 21.5465 19.58 21.5326C19.35 21.5256 19.15 21.6233 19.08 21.7698L17.43 25.2093H13.5C13.22 25.2093 13 25.3628 13 25.5581C13 25.7535 13.22 25.907 13.5 25.907H17.79C18 25.907 18.2 25.8093 18.26 25.6698L19.43 23.2209L21.66 30.7209C21.71 30.8814 21.91 31 22.14 31H22.15C22.38 31 22.58 30.8884 22.64 30.7279L26.15 20.814L28.99 28.914C29.04 29.0605 29.22 29.1721 29.44 29.1791C29.66 29.186 29.86 29.1023 29.94 28.9628L32.39 24.6512H36.5C36.78 24.6512 37 24.4977 37 24.3023C37 24.107 36.78 23.9535 36.5 23.9535Z"
                        fill="#D1433A" stroke="#D1433A" />
                    </svg>
                    <span>
                      MOODS
                    </span>
                  </a>
                </li>
              </div>

              <div class="moodDiv">
                <li class="nav-item">
                  <a class="nav-link" :class="{ active: activeTab === 'releases' }" id="release-tab" data-toggle="tab"
                    href="#releases" role="tab" aria-controls="release" aria-selected="activeTab === 'releases'"
                    @click="setActiveTab('releases')">
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H46C47.933 0.5 49.5 2.067 49.5 4V46C49.5 47.933 47.933 49.5 46 49.5H4C2.067 49.5 0.5 47.933 0.5 46V4Z"
                        fill="#F9FAFB" stroke="#E6EAF0" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M25.4439 22.4618L30.6872 21.4132C31.3897 21.2613 32.0042 20.8392 32.3981 20.2382C32.7921 19.6371 32.9339 18.9053 32.7929 18.2006C32.652 17.4959 32.2395 16.8749 31.6447 16.4716C31.0498 16.0683 30.3203 15.9152 29.6135 16.0452L25.8264 16.8026C25.1936 16.9293 24.6244 17.2712 24.2153 17.7703C23.8063 18.2694 23.5829 18.8948 23.5829 19.5401V27.0547C22.8734 26.6451 22.0485 26.481 21.2363 26.5879C20.424 26.6948 19.6697 27.0668 19.0903 27.6461C18.5109 28.2254 18.1389 28.9796 18.0319 29.7919C17.9249 30.6042 18.0889 31.4291 18.4984 32.1387C18.908 32.8482 19.5403 33.4028 20.2972 33.7165C21.054 34.0301 21.8933 34.0853 22.6847 33.8733C23.4761 33.6614 24.1755 33.1943 24.6744 32.5444C25.1732 31.8945 25.4437 31.0982 25.4439 30.2789V22.4628V22.4618ZM29.9791 17.8699L26.192 18.6273C25.9812 18.6694 25.7914 18.7833 25.655 18.9495C25.5187 19.1158 25.4441 19.3241 25.4439 19.5392V20.5646L30.3225 19.5885C30.5469 19.5393 30.743 19.404 30.8687 19.2117C30.9944 19.0193 31.0395 18.7854 30.9943 18.5601C30.9492 18.3348 30.8175 18.1363 30.6274 18.0072C30.4374 17.8781 30.2042 17.8288 29.9782 17.8699H29.9791ZM23.5829 30.2779C23.5829 29.7844 23.3869 29.311 23.0379 28.962C22.6889 28.613 22.2155 28.417 21.722 28.417C21.2284 28.417 20.7551 28.613 20.4061 28.962C20.0571 29.311 19.861 29.7844 19.861 30.2779C19.861 30.7715 20.0571 31.2449 20.4061 31.5939C20.7551 31.9429 21.2284 32.1389 21.722 32.1389C22.2155 32.1389 22.6889 31.9429 23.0379 31.5939C23.3869 31.2449 23.5829 30.7715 23.5829 30.2779Z"
                        fill="#D1433A" />
                    </svg>
                    <span>
                      NEW RELEASES
                    </span>
                  </a>
                </li>
              </div>

            </ul>



            <!-- tab content -->
            <div class="tab-content" id="myTabContent">

              <!-- genre -->

              <div class="tab-pane fade" :class="{ 'show active': activeTab === 'home' }" id="home" role="tabpanel"
                aria-labelledby="home-tab">
                <!-- <div class="row">
                  <div class="col">
                    <div class="section-title-music">
                      What kind of music are you looking for?
                    </div>
                  </div>
                </div> -->

                <div class="row">
                  <div class="col-sm-2 col-2 genres-select-list" v-for="(eachOp, index) in geners" :key="index">
                    <div class="genres_checkbox_outer">
                      <input :id="'genres_' + index" type="checkbox" name="" @click="selectGenre($event, eachOp.id)"
                        :checked="isSelectedGenre(eachOp.id)" />
                      <label :for="'genres_' + index" class="genres_box">
                        <!--                          <div-->
                        <!--                              :class="['genres_icon svg-icon', eachOp.name+'_class' ]">-->
                        <!--                            <img :src="eachOp.icon_url"/>-->
                        <!--                          </div>-->
                        <div :class="[
                          'genres-themes',
                          isAddedGenre(eachOp.id) ? 'activeGenre' : '',
                        ]" :style="{
                          'mask-image': `url('` + eachOp.icon_url + `')`,
                        }"></div>
                        <div class="genres_info">
                          <p class="genres_title" v-html="eachOp.name"></p>
                        </div>
                        <div class="genres_checkbox">
                          <div class="genres_checkbox_lab">
                            <svg width="7px" height="5px" viewBox="0 0 13 10" version="1.1"
                              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="checked(2)" fill="#D1433A" fill-rule="nonzero">
                                  <polygon id="Path" class="chekmark-check"
                                    points="0.664935065 5.38032418 4.76358812 9.48680081 12.6337662 1.62439985 11.5038734 0.510131007 4.76358812 7.24259274 1.7791807 4.25820853">
                                  </polygon>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                <!-- <div v-if="!geners.length" style="margin-top: -40px;"  ></div> -->
                <div style="margin-top: 30px" v-if="selectedGenresSubGenres.length">
                  <div class="gen_mooods">
                    <ul>
                      <li v-for="(subGen, index) in selectedGenresSubGenres" :key="index" class="cursor-pointer"
                        @click="selectSubGenres($event, subGen.id)">
                        <a :class="{ active: isSelectedSubGenre(subGen.id) }" href="javascript:;">
                          <span v-html="subGen.name"></span>
                        </a>
                      </li>
                    </ul>

                    <div @click="clearGenreFilter" class="curson-pointer clear-history1" v-if="
                      selectedGenres.length > 0 ||
                      selectedGenresSubGenres.length > 0
                    ">
                      Clear all history
                    </div>
                  </div>


                </div>
              </div>

              <!-- mood -->

              <div class="tab-pane fade" :class="{ 'show active': activeTab === 'profile' }" id="profile"
                role="tabpanel" aria-labelledby="profile-tab">
                <!-- <div class="row">
                  <div class="col">
                    <div class="section-title-music">
                      What kind of moods are you looking for?
                    </div>
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-sm-2 col-2 genres-select-list" v-for="(eachOp, index) in moods" :key="index">
                    <div class="genres_checkbox_outer">
                      <input @click="selectMood($event, eachOp.id)" :id="'moods_' + index" type="checkbox" name="" />
                      <label :for="'moods_' + index" class="genres_box">
                        <!--                          <div-->
                        <!--                              :class="['genres_icon svg-icon', eachOp.name+'_class' ]">-->
                        <!--                            <img :src="eachOp.icon_url"/>-->
                        <!--                          </div>-->
                        <div :class="[
                          'moods-themes',
                          isAddedMood(eachOp.id) ? 'activeMood' : '',
                        ]" :style="{
                          'mask-image': `url('` + eachOp.icon_url + `')`,
                        }"></div>
                        <div class="genres_info">
                          <p class="genres_title" v-html="eachOp.name"></p>
                        </div>
                        <div class="genres_checkbox">
                          <div class="genres_checkbox_lab">
                            <svg width="7px" height="5px" viewBox="0 0 13 10" version="1.1"
                              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="checked(2)" fill="#D1433A" fill-rule="nonzero">
                                  <polygon id="Path" class="chekmark-check"
                                    points="0.664935065 5.38032418 4.76358812 9.48680081 12.6337662 1.62439985 11.5038734 0.510131007 4.76358812 7.24259274 1.7791807 4.25820853">
                                  </polygon>
                                </g>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <!-- release -->
              <div class="tab-pane fade" :class="{ 'show active': activeTab === 'releases' }" id="releases"
                role="tabpanel" aria-labelledby="release-tab">
                No data
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- featured channels -->
      <div class="content discover fixContentDiscover">
        <p v-if="loading" class="text-center">Please Wait...</p>
        <div v-for="(singleChannel, index) in allChannels.center" v-bind:key="index">
          <component v-bind:is="myComponentList[singleChannel.alt_name].component" :cname="singleChannel.description"
            :myInd="index" :gridTitle="singleChannel.description" :header="true" :pageTitle="singleChannel.title"
            :redirectLink="myComponentList[singleChannel.alt_name].link" :listData="singleChannel.objects.data"
            :channelType="singleChannel.type" :altName="singleChannel.alt_name"
            v-if="singleChannel.channel_element_view != 'four_grid'" />
        </div>


        <!-- <GridSellingWithSlider :gridTitle="'Featured'" :header="false" :redirectLink="'featured'"/> -->
        <div class="row options geners-moods-options">
          <div class="col-sm-2">
            <div class="btn btn-play-all" @click="playAll">
              <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="svg-play filled">
                <path class="c-line-1"
                  d="M1.93569 11.9999C1.81909 11.998 1.70435 11.9704 1.59969 11.9189C1.48036 11.8487 1.37983 11.7506 1.30668 11.6331C1.23354 11.5155 1.18996 11.382 1.17969 11.2439V1.66692C1.18566 1.53314 1.22298 1.40262 1.28864 1.2859C1.3543 1.16917 1.44645 1.06951 1.55769 0.994924C1.67446 0.928694 1.80782 0.89744 1.94186 0.904887C2.0759 0.912333 2.20497 0.958167 2.31369 1.03692L9.66469 5.82492C9.76802 5.89435 9.85268 5.98813 9.91123 6.09799C9.96977 6.20786 10.0004 6.33044 10.0004 6.45492C10.0004 6.57941 9.96977 6.70199 9.91123 6.81185C9.85268 6.92172 9.76802 7.0155 9.66469 7.08492L2.31369 11.8739C2.20318 11.9529 2.07147 11.9968 1.93569 11.9999"
                  stroke="white" stroke-width="1.3"></path>
              </svg>
              PLAY ALL
            </div>
          </div>
          <div class="col">
            <div class="
                d-flex
                flex-wrap
                justify-content-end
                align-items-end
                h-100
                items-centers
              ">
              <div class="d-flex flex-wrap align-items-end ml-auto">
                <div class="
                    text
                    with-icon
                    d-flex
                    justify-content-between justify-content-end
                    align-items-center
                  ">
                  Filters
                  <div class="filter-total-count" :class="{ active: showFilters }" @click="showFilters = !showFilters">
                    <span class="svg-i">
                      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.75 1H1L7.3 8.36296V12.563L10.45 15V8.36296L16.75 1Z" stroke="white"
                          stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </span>
                    <p class="filter-count">
                      {{ checkCountOfAppliedFilters() }}
                    </p>
                  </div>
                </div>
                <div class="change-view js-change-view">
                  <div class="btn-group">
                    <div data-type="grid" :class="[
                      'btn btn-svg-icon js-set-view',
                      listing_type == 'grid' ? 'active' : '',
                    ]" v-on:click="showForm('grid')">
                      <div data-type="grid" class="svg-icon show-grid grid-svd">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M30 0H0V30H30V0Z" fill="#D1433A" class="c-fill-1"></path>
                          <path d="M16.5 13.5V8.5H21.5V13.5H16.5Z" stroke="white" stroke-linecap="square"
                            class="c-line-1"></path>
                          <path d="M8.5 13.5V8.5H13.5V13.5H8.5Z" stroke="white" stroke-linecap="square"
                            class="c-line-1"></path>
                          <path d="M16.5 21.5V16.5H21.5V21.5H16.5Z" stroke="white" stroke-linecap="square"
                            class="c-line-1"></path>
                          <path d="M8.5 21.5V16.5H13.5V21.5H8.5Z" stroke="white" stroke-linecap="square"
                            class="c-line-1"></path>
                        </svg>
                      </div>
                    </div>
                    <div :class="[
                      'btn btn-svg-icon js-set-view',
                      listing_type == 'list' ? 'active' : '',
                    ]" v-on:click="showForm('list')">
                      <div data-type="list" class="svg-icon show-list grid-svd">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M30 0H0V30H30V0Z" fill="white" class="c-fill-1"></path>
                          <path d="M8.5 12.5V8.5H21.5V12.5H8.5Z" stroke="#8B949F" stroke-linecap="square"
                            class="c-line-1"></path>
                          <path d="M8.5 21.5V17.5H21.5V21.5H8.5Z" stroke="#8B949F" stroke-linecap="square"
                            class="c-line-1"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="row" v-show="showFilters">
          <div class="col-12">
            <GenresMoodsSelling @setType="setValue" />
          </div>
        </div>
        
        <div class="js-container fixTrendingNowMusic">
          <div class="sale-items m-t-40">
            <div class="row justify-content-between">
              <div class="col-8">
                <div class="row position-relative">
                  <div v-for="(singleChannel, index) in allChannels.center" v-bind:key="index">
                    <component v-bind:is="myComponentList[singleChannel.alt_name].component
                      " :cname="singleChannel.description" :myInd="index" :gridTitle="singleChannel.description"
                      :header="true" :pageTitle="singleChannel.title" :redirectLink="myComponentList[singleChannel.alt_name].link
                        " :listData="singleChannel.objects.data" :channelType="singleChannel.type"
                      :altName="singleChannel.alt_name" v-if="
                        singleChannel.channel_element_view == 'four_grid' &&
                        listing_type == 'grid'
                      " />
                    <div class="col-12 pl-1 setT-20" v-if="
                      singleChannel.channel_element_view == 'four_grid' &&
                      listing_type != 'grid'
                    ">
                      <h4 class="title-head top-sign-bottom">
                        {{ singleChannel.title }}
                      </h4>
                      <div class="
                          link-more
                          near_arrow
                          without-slider
                          cursor-pointer
                        " style="top: 0">
                        <a @click="
                          redirectToChannel(
                            singleChannel.type,
                            singleChannel.alt_name,
                            singleChannel.title
                          )
                          ">See all
                          <span class="sl-arrow">
                            <img
                              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgY2xhc3M9InN2Zy1hcnJvdy1yaWd0aCIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZD0iTTEuMjkzNTggMS4zOTQ3OEw3LjM0NTUgNi4zMTI5MkwxLjI5MzU4IDExLjY2MzYiICBzdHJva2U9IiM0NzUwNUIiICBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIg0KICAgICAgICAgIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0KDQo=" />
                          </span>
                        </a>
                      </div>
                      <div class="lists">
                        <div class="row" v-if="singleChannel.objects.data && singleChannel.objects.data.length > 0">
                          <component
                                     v-bind:is="'SongsPopularity'" :cname="singleChannel.description" :myInd="index"
                            :gridTitle="singleChannel.description" :header="true" :redirectLink="myComponentList[singleChannel.alt_name].link" :listData="singleChannel.objects.data" :channelType="singleChannel.type"
                            :altName="singleChannel.alt_name" :pageTitle="singleChannel.title" :showRating="false" />
                        </div>
                        <div v-else><p>No results found!</p></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="content-sidebar">
                  <div v-for="(singleChannel, index) in allChannels.rightbar" v-bind:key="index">
                    <component v-bind:is="myComponentList[singleChannel.alt_name].component
                      " :cname="singleChannel.description" :myInd="index" :gridTitle="singleChannel.description"
                      :header="true" :redirectLink="myComponentList[singleChannel.alt_name].link
                        " :listData="singleChannel.objects.data" :altName="singleChannel.alt_name"
                      :channelType="singleChannel.type" :pageTitle="singleChannel.title"
                      v-if="singleChannel.alt_name !== 'trending-now'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <notifications group="foo" class="notification-style" />
    <full-page-loader :show="loading" />
  </div>
</template>
<script type="application/javascript">
import payStreamSwitch from "@/components/Music/UI-components/payStreamToggleSwitch.vue";
import GenresMoodsSelling from "@/components/Common/UiList/GenresMoodsSelling";
import GridSellingWithSlider from "@/components/Common/Elements/GridSellingWithSlider.vue";
import SongsPopularity from "@/components/Common/Elements/SongsPopularity.vue";
import GridSelling4 from "@/components/Common/Elements/GridSelling4.vue";
import WhoToFollow from "@/components/Common/Elements/WhoToFollow.vue";
import Boosted from "@/components/Common/Elements/Boosted.vue";
import RecentReviews from "@/components/Common/Elements/RecentReviews.vue";
import { mapActions } from "vuex";
import { getChannelData, getMoods } from "../../../apis/APIs";
import { GENRE } from "../../../store/types";

export default {
  data() {
    return {
      activeTab: 'home',
      subGeners: [],
      subGenerCounter: [],
      selectedGenres: [],
      selectedSubGenres: [],
      selectedMoods: [],
      showAd: false,
      filters: {
        sort_by: "",
        type: "",
        time_period: 0,
        start_price: null,
        end_price: null,
        country: "",
      },
      listing_type: "list",
      dataLoaded: false,
      inProgress: false,
      allChannels: [],
      moods: [],
      loading: false,
      showFilters: false,
      trending_now_songs_array: [],
      myComponentList: {
        // Geners Moods
        featured: {
          component: "GridSellingWithSlider",
          link: "featured",
          subComponent: "",
        },
        "trending-now": {
          component: "GridSelling4",
          link: "continue-listening-to",
          subComponent: "",
        },
        "who-to-follow": {
          component: "WhoToFollow",
          link: "who-to-follow",
          subComponent: "",
        },
        recent_played_songs: {
          component: "Boosted",
          link: "continue-listening-to",
          subComponent: "",
        },
        "recent-reviews": {
          component: "RecentReviews",
          link: "recent-views-see-all",
          subComponent: "",
        },
        suggested_podcasters: {
          component: "WhoToFollow",
          link: "who-to-follow",
          subComponent: "",
        },
      },
    };
  },
  components: {
    GenresMoodsSelling,
    payStreamSwitch,
    GridSellingWithSlider,
    SongsPopularity,
    GridSelling4,
    WhoToFollow,
    Boosted,
    RecentReviews,
  },
  async mounted() {
    await this.pageChannels();
    await this.getAllMoods();
    await this.getGenres();
    await this.$store.dispatch("fetchCountries");
  },
  computed: {
    selectedGenresSubGenres() {
      if (this.selectedGenres.length > 0) {
        let subGenres = [];
        for (const genreId of this.selectedGenres) {
          const index = this.geners.findIndex((e) => e.id === Number(genreId));
          if (index !== -1) {
            subGenres = [...subGenres, ...this.geners[index].subgenre];
          }
        }
        return subGenres;
      }
      return [];
    },
    geners() {
      return this.$store.getters.getGenres &&
        this.$store.getters.getGenres.length > 0
        ? this.$store.getters.getGenres
        : [];
    },
  },
  watch: {
    selectedGenres() {
      this.pageChannels();
    },
    selectedSubGenres() {
      this.pageChannels();
    },
    selectedMoods() {
      this.pageChannels();
    },
  },
  methods: {
    setActiveTab(tabName) {
      this.activeTab = tabName;
    },
    isAddedGenre(id) {
      const genreId = this.selectedGenres.findIndex((ele) => ele === id);
      return genreId !== -1;
    },
    isAddedMood(id) {
      const moodId = this.selectedMoods.findIndex((ele) => ele === id);
      return moodId !== -1;
    },
    ...mapActions(["get_geners"]),
    showForm: function (type) {
      this.listing_type = type;
    },
    async pageChannels() {
      try {
        this.allChannels = [];
        await this.$store.dispatch("loading", true);
        let data = await getChannelData(
          "genre_mood",
          this.selectedGenres,
          this.selectedSubGenres,
          this.selectedMoods,
          null,
          this.filters.sort_by,
          this.filters.type,
          this.filters.time_period,
          this.filters.start_price,
          this.filters.end_price,
          this.filters.country
        );
        this.allChannels = data.data.channels;
        if (
          data &&
          data.data &&
          data.data.channels &&
          data.data.channels.center &&
          data.data.channels.center[1] &&
          data.data.channels.center[1].alt_name === "trending-now"
        ) {
          this.trending_now_songs_array = data.data.channels.center[1].objects.data;
        } else {
          this.trending_now_songs_array = [];
        }
        await this.$store.dispatch("loading", false);
      } catch (error) {
        await this.$store.dispatch("loading", false);
      }
    },
    playAll() {
      if (this.trending_now_songs_array.length > 0) {
        this.$store.commit(
          "Set_Play_All_Songs_Array",
          this.trending_now_songs_array
        );
        this.$store.commit("SET_CURRENT_PLAYER", "footerPlayer");
        this.$store.commit("Set_Song_status", true);
      } else {
        console.log("No Trending Now Songs Yet");
      }
    },
    async getAllMoods() {
      try {
        let { data } = await getMoods();
        this.moods = data.data;
      } catch (error) {
        console.error(error);
      }
    },
    redirect: function (url) {
      this.$router.push({ name: url, params: { title: "Hot new singles" } });
    },
    selectGenre(e, value) {
      e.stopPropagation();
      const index = this.selectedGenres.findIndex((e) => e === value);
      if (index !== -1) {
        this.removeUnSelectedGenresSubGenre(value);
        this.selectedGenres.splice(index, 1);
      } else {
        this.selectedGenres.push(value);
      }
    },
    selectMood(e, value) {
      e.stopPropagation();
      const index = this.selectedMoods.findIndex((e) => e === value);
      if (index !== -1) {
        this.selectedMoods.splice(index, 1);
      } else {
        this.selectedMoods.push(value);
      }
    },
    selectSubGenres(e, value) {
      e.stopPropagation();
      const index = this.selectedSubGenres.findIndex((e) => e === value);
      if (index !== -1) {
        this.selectedSubGenres.splice(index, 1);
      } else {
        this.selectedSubGenres.push(value);
      }
    },
    clearGenreFilter() {
      this.selectedGenres = [];
      this.selectedSubGenres = [];
    },
    isSelectedSubGenre(id) {
      const index = this.selectedSubGenres.findIndex((e) => e === id);
      return index !== -1;
    },
    removeUnSelectedGenresSubGenre(id) {
      const genreIdIndex = this.geners.findIndex((e) => e.id === id);
      const SelectedSubGenresOfGenres = this.geners[genreIdIndex].subgenre;
      for (const subGenre of SelectedSubGenresOfGenres) {
        const index = this.selectedSubGenres.findIndex(
          (e) => e.id === subGenre.id
        );
        this.selectedSubGenres.splice(index, 1);
      }
    },
    setValue(val, type) {
      if (type === "price") {
        this.filters["start_price"] = val.start;
        this.filters["end_price"] = val.end;
      } else {
        this.filters[type] = val;
      }
      this.pageChannels();
    },
    isSelectedGenre(id) {
      const index = this.selectedGenres.findIndex((e) => e === id);
      return index !== -1;
    },
    async getGenres() {
      try {
        if (
          !this.$store.getters.getGenres ||
          this.$store.getters.getGenres.length == 0
        ) {
          await this.$store.dispatch("get_geners");
        }
      } catch (error) {
        console.error(error);
      }
    },
    checkCountOfAppliedFilters() {
      return Object.values(this.filters).filter(
        (e) => e !== null && e !== 0 && e !== 0
      ).length;
    },
  },
};
</script>
<style lang="scss" scoped>
.genere-moods-content{
  width: 1170px !important;
  margin: 0 auto;
}

.notification-style {
  margin-top: 5rem;
}

.filter-total-count {
  &.active {
    .svg-i {
      svg {
        path {
          stroke: white !important;
        }
      }
    }
  }

  .svg-i {
    svg {
      path {
        stroke: #D1433A !important;
      }
    }
  }
}

.genres-select-list {
  .genres_checkbox_outer {
    &:hover .genres-themes {
      background-color: #D1433A;
    }

    .genres-themes {
      width: 40px;
      height: 26px;
      background: #47505b;
      mask-position: center;
      mask-repeat: no-repeat;
    }

    .activeGenre {
      background-color: #D1433A;
    }

    &:hover .moods-themes {
      background-color: #D1433A;
    }

    .moods-themes {
      width: 40px;
      height: 26px;
      background: #47505b;
      mask-position: center;
      mask-repeat: no-repeat;
    }

    .activeMood {
      background-color: #D1433A;
    }
  }
}
.geners-moods-options{
  margin-top: 0 !important;
}
</style>
