<template>
  <div class="Signup_pop_overlay">
    <div class="modal fade login-type show" id="login" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      style="display: block; padding-right: 15px" aria-modal="true">
      <div class="modal-dialog fixCross">
        <div class="modal-content">
          <div class="work-exp-popup fixArrowMain">
            <div class="work-experience-heading">
              <h1>{{ is_edit ? "Edit" : "Add" }} Work Experience</h1>
              <div class="close-popup-btn" v-on:click="closeModal()">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="svg-color" d="M13.5 4.5L4.5 13.5" stroke="#47505B" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"></path>
                  <path class="svg-color" d="M4.5 4.5L13.5 13.5" stroke="#47505B" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </div>
            </div>
            <div class="popup-content">
              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
              </div>
              <div v-if="success_message" class="alert alert-success" role="alert">
                {{ success_message }}
              </div>
              <div class="row">
                <div class="col">
                  <div class="w-poup-fields">
                    <label>Company Name</label>
                    <input type="text" v-model="company_name" placeholder="Company Name" />
                    <label class="error_msg error_msg_red" v-if="_handleErrorMessage('company_name')">{{
                      _handleErrorMessage("company_name")
                    }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="w-poup-fields">
                    <label>Location</label>
                    <input type="text" v-model="location" placeholder="Country, City" />
                    <label class="error_msg error_msg_red" v-if="_handleErrorMessage('location')">{{
                      _handleErrorMessage("location")
                    }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="w-poup-fields">
                    <label>Title</label>
                    <input type="text" v-model="title" placeholder="Your Role in the Company" />
                    <label class="error_msg error_msg_red" v-if="_handleErrorMessage('title')">{{
                      _handleErrorMessage("title")
                    }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 month_year">
                  <div>
                    <label>From</label>
                    <div class="d-flex">
                      <div class="fromto-select mr-2">
                        <SelectDropdown
                          :options="months"
                          v-model="from_month"
                          @change="((value) => from_month = value)"
                          :style="'width: 70px; border: 1px solid #E6EAF0;'"
                          hideCrossIcon
                        />
                        <div class="svg-icon">
                          <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L5 5L9 1" stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                        <label class="error_msg error_msg_red" v-if="
                          _handleErrorMessage('from_month') ||
                          _handleErrorMessage('from_year')
                        ">{{
                          _handleErrorMessage("from_month") ||
                          _handleErrorMessage("from_year")
                        }}</label>
                      </div>
                      <div class="fromto-select">
                        <SelectDropdown
                          :options="years"
                          v-model="from_year"
                          @change="((value) => from_year = value)"
                          :style="'width: 70px; border: 1px solid #E6EAF0;'"
                          hideCrossIcon
                        />
                        <div class="svg-icon">
                          <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L5 5L9 1" stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 month_year">
                  <div>
                    <label>To</label>
                    <div class="d-flex">
                      <div class="fromto-select mr-2">
                        <SelectDropdown
                          :options="months"
                          v-model="to_month"
                          @change="((value) => to_month = value)"
                          :style="'width: 70px; border: 1px solid #E6EAF0;'"
                          :disabled="is_currently_work_here"
                          hideCrossIcon
                        />
                        <div class="svg-icon">
                          <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L5 5L9 1" stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                        <label class="error_msg error_msg_red" v-if="
                          _handleErrorMessage('to_month') ||
                          _handleErrorMessage('to_year')
                        ">{{
                          _handleErrorMessage("to_month") ||
                          _handleErrorMessage("to_year")
                        }}</label>
                      </div>
                      <div class="fromto-select">
                        <SelectDropdown
                          :options="years"
                          v-model="to_year"
                          @change="((value) => to_year = value)"
                          :style="'width: 70px; border: 1px solid #E6EAF0;'"
                          hideCrossIcon
                          :disabled="is_currently_work_here"
                        />
                        <div class="svg-icon">
                          <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L5 5L9 1" stroke="#D1433A" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <div class="currently-work">
                    <ul class="unstyled centered fixUnstyled">
                      <li>
                        <input class="checkbox" id="styled-checkbox-1" type="checkbox"
                          v-model="is_currently_work_here" />
                          
                        <label for="styled-checkbox-1">I currently work here</label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="w-poup-fields" style="margin-bottom: -18px;">
                    <label class="description-label">Description </label>
                    <textarea class="textarea-field" v-model="description"
                      placeholder="About your work experience"></textarea>
                    <label class="error_msg error_msg_red" v-if="
                _handleErrorMessage('description')
              ">{{
                        _handleErrorMessage("description")

                      }}</label>
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top: 28px !important;">
                  <div class="col-12 ">
                    <div class="upload-photos-title">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="2" fill="#47505B"/>
                        <path d="M12 14L8 10L12 6" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <span class="upload-title" style="margin-left: 10px;">
                        Upload Photos
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="upload-photos-section" style="margin-top: 10px !important; margin-bottom: 10px !important">
                      <div v-if="preview_list.length > 0">
                        <div v-for="(eachOp, index) in preview_list" :key="'image'+index" class="images-list-container upload-image-list-item">
                          <div class="uploaded-image">
                            <img :src="eachOp">
                            <span @click="deleteImage(index)" class="cross-icon cursor-pointer">
                              <svg width="15" height="15" viewBox="0 0 14 14" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.5 3.5L3.5 10.5" stroke="white" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path d="M3.5 3.5L10.5 10.5" stroke="white" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                              </svg>
                          </span>
                          </div>
                        
                        </div>
                      </div>

                        <div v-if="artwork.length > 0">
                          <div v-for="(eachOp, index) in artwork" :key="'image'+index" class="images-list-container upload-image-list-item">
                          <div class="uploaded-image">
                            <img v-if="eachOp && eachOp.original_url" :src="eachOp.original_url">
                            <!-- <span class="cross-icon cursor-pointer">
                              <svg width="15" height="15" viewBox="0 0 14 14" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.5 3.5L3.5 10.5" stroke="white" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                  <path d="M3.5 3.5L10.5 10.5" stroke="white" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                              </svg>
                          </span> -->
                          </div>
                        </div>
                        </div>
                        
                        <div v-if="(artwork.length === 0 && preview_list.length === 0)" class="upload-photo-section-li images-list-container cursor-pointer upload-image-list-item">
                          <div class="upload-photo-icons">
                            <img :src="require('@/assets/img/add-p.png')">
                            <input type="file" accept="image/x-png,image/gif,image/jpeg"
                                   name="fileToUpload"
                                   @change="uploadImages" id="fileToUpload">
                          </div>
                        </div>

                    </div>
                  </div>
                </div>
              <div class="row popup_button" style="margin-top: 8px">
                <div class="skip_btn work-cancel col-4 px-0">
                  <button v-on:click="closeModal()">CANCEL</button>
                </div>

                <div class="next_step_btn ad-work-exp width220 col-8">
                  <button @click="submit()">
                    {{
                      loading
                        ? "Please Wait..."
                        : `${is_edit ? "EDIT" : "ADD"} WORK EXPERIENCE`
                    }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import { mapActions, mapMutations, mapGetters } from "vuex";
import * as Utils from "../../../components/utils/Utils";
import * as Helper from "../../../apis/Helper.js";
import { EDIT_PROFILE_ERRORS, SUCCESS, ERROR } from "../../../store/types";
import {
  addExperienceOnArtistPage,
  edit_user_work_api,
  updateExperienceOnArtistPage,
  user_work_api,
} from "../../../apis/APIs";
import SelectDropdown from "../../Common/Dropdown/SelectDropdown.vue";

export default {
  data() {
    return {
      work_id: "",

      company_name: "",
      location: "",
      title: "",

      months: Utils.getMonths(),
      years: Utils.getYears(),
      from_month: "",
      from_year: "",

      to_month: "",
      to_year: "",

      is_currently_work_here: false,
      description: "",

      loading: false,

      message: "",
      success_message: "",

      is_edit: false,
      preview_list: [],
      artwork: [],
      tempArtwork: []
    };
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    pageID: {
      type: Number,
      default: null,
    },
  },
  components: {
    SelectDropdown,
  },
  computed: {
    ...mapGetters({
      form_data: "edit_profile_form_map_to_props",
      user: "user_map_to_props",
    }),
    errors() {
      const { errors = [] } = this.form_data || {};
      return errors;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    closeModal: function () {
      this.$parent.closeWindow();
    },
    ...mapActions(["edit_user_profile"]),
    ...mapMutations([
      "updateEditProfileFormData",
      "updateEditProfileUIConstraints",
    ]),
    init() {
      try {
        const {
          company_name,
          currently_working,
          description,
          from_month,
          from_year,
          id,
          location,
          title,
          to_month,
          to_year,
          artwork_url
        } = this.item || {};

        if (!id) return;

        this.company_name = company_name;
        this.location = location;
        this.title = title;

        this.from_month = from_month;
        this.from_year = from_year;

        this.to_month = to_month;
        this.to_year = to_year;

        this.is_currently_work_here = currently_working === 1 ? true : false;
        this.description = description;

        this.work_id = id;

        this.is_edit = true;
        if (artwork_url) {
        this.artwork[0] = artwork_url;
        }
      } catch (error) { }
    },
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex((ele) => ele.fieldName === key) > -1
          ? {
            status: true,
            message:
              this.errors[
                this.errors.findIndex((ele) => ele.fieldName === key)
              ].message,
          }
          : { status: false, message: "" };
      } else return { status: false, message: "" };
    },
    _handleErrorMessage(key) {
      const { message = "" } = this.isError(key);

      return message;
    },
    uploadImages(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          }
          this.tempArtwork.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index++;
        }
        // this.selectedImages = this.images
      }
    },
    onChange(e) {
      const { name, value } = e.target || {};


      this.updateEditProfileFormData({
        [name]: value,
      });
    },
    submit(e) {
      if (e) e.preventDefault();

      // const { user_data } = this.user || {};
      // const { user_id, user_picture, geners, following } = user_data || {};

      const {
        loading,
        title,
        company_name,
        description,
        location,
        from_month,
        from_year,
        to_month,
        to_year,
        is_currently_work_here,
        is_edit,
        work_id,
        tempArtwork,
      } = this;

      if (loading) return;

      let body = { company_name, title, location, from_month, from_year, description, tempArtwork };
      if (!is_currently_work_here) {
        body.to_month = to_month;
        body.to_year = to_year;
      }
      Helper.validate(Object.keys(body), body)
        .then(async ({ status, response }) => {
          if (status) {
            this.updateEditProfileUIConstraints({
              [EDIT_PROFILE_ERRORS]: [],
            });

            try {
              this.message = "";
              this.loading = true;

              // let query = `?company_name=${company_name}&location=${location}&title=${title}&from_mon=${from_month}&from_year=${from_year}&to_mon=${to_month}&to_year=${to_year}&currently_work_here=${Number(is_currently_work_here)}&description=${description}&user_id=${user_id}&action=${is_edit ? "2" : "1"}`;
              //
              // if(work_id){
              //    query = query+`&id=${work_id}`;
              // }

              var formdata = new FormData();
              formdata.append("company_name", company_name);
              formdata.append("title", title);
              formdata.append("from_month", from_month);
              formdata.append("from_year", from_year);
              formdata.append(
                "to_month",
                is_currently_work_here === true ? null : to_month
              );
              formdata.append(
                "to_year",
                is_currently_work_here === true ? null : to_year
              );
              formdata.append("location", location);
              formdata.append("description", description);
              formdata.append(
                "currently_working",
                is_currently_work_here === true ? 1 : 0
              );
              tempArtwork.forEach((image, index) => {
                formdata.append(`artwork[${index}]`, image);
              });


              // else if (from_month > to_month) {
              //   this.message = "From Month Can't greater than To Month"
              // }
              if (parseInt(from_year) > parseInt(to_year)) {
                this.message = "Invalid selected years";
              } else if (description === "" || description === null) {
                this.message = "Description required"
              }
              if (work_id && is_edit) {
                let res;
                if (this.pageID) {
                  res = await updateExperienceOnArtistPage(
                    this.item.id,
                    formdata
                  );
                } else {
                  res = await edit_user_work_api(formdata, work_id);
                }
                const { success, message } = res.data || {};
                this.loading = false;

                if (success === 1) {
                  this.success_message = message;
                  if (this.pageID) {
                    const { information } = res.data || {};
                    this.$store.commit("SET_PAGE_DATA", information);
                    this.$parent.closeWindow();
                  } else {
                    this.$parent.fetchWorkEducationData();
                    this.$parent.closeWindow();
                  }
                  setTimeout(() => (this.success_message = ""), 5000);
                } else {
                  this.message = message;
                }
              } else {
                let res;
                if (this.pageID) {
                  res = await addExperienceOnArtistPage(this.pageID, formdata);
                } else {
                  res = await user_work_api(formdata);
                }
                const { success, message } = res.data || {};
                this.loading = false;

                if (success === 1) {
                  this.success_message = message;
                  if (this.pageID) {
                    const { information } = res.data || {};
                    this.$store.commit("SET_PAGE_DATA", information);
                  } else {
                    this.$parent.fetchWorkEducationData();
                    // this.$parent.closeWindow();
                  }
                  setTimeout(() => {
                    this.$parent.closeWindow();
                  }, 1000);
                  setTimeout(() => (this.success_message = ""), 5000);
                } else {
                  this.message = message;
                }
              }
            } catch (e) {
              this.loading = false;
              this.message = e && e.message ? e.message : "Please try again.";
            }
          } else
            this.updateEditProfileUIConstraints({
              [EDIT_PROFILE_ERRORS]:
                response && response.length ? response : [],
            });
        })
        .catch((err) => console.log(err));
    },
    deleteImage(index) {
      this.preview_list = [];
      this.tempArtwork = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(209, 67, 58, 0.5);
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, background-image 0.3s ease;
  margin-right: 13px;
  vertical-align: middle;
  margin-top: -3px;
}

.checkbox:checked {
  background-color: #D1433A;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='2' fill='%23D1433A'/%3E%3Cpath d='M4.32538 11.7566C3.37089 10.7894 4.72764 9.97541 5.68213 10.9426L7.68213 12.9426L11.6821 9.44212L14.6821 6.44262C15.7321 5.4754 16.8292 6.2435 15.8747 7.30744L8.62059 14.5616C8.2388 14.9485 7.57065 15.0452 7.18886 14.5616L4.32538 11.7566Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.checkbox:not(:checked) {
  background-color: #FFF8F8;
  background-image: none;
}
</style>

<style scoped>

.upload-image-list-item {
    list-style: none;
    width: 100% !important;
    margin-right: 6px !important;
    display: inline-block;
    position: relative;
}

</style>