import {
  getSingleEventDetails,
  getSingleEventMembers,
  getEventRepostMembers,
  getRelatedEvents,
  getEventPosts,
  buyTickets,
  addToCart,
  myCartItems,
  removeCart,
  GetMyContactInfo,
  addContactInfo,
  removeContactInfo,
  promoVerification,
  placeOrder,
  getMyOrderSingle,
  sendTicketViaEmail,
} from "../../apis/APIs";

const INITIAL_STATE = {
  single_Event_Details: "",
  single_Event_Members: "",
  single_Event_Repost_Members: "",
  related_Events: "",
  event_posts: "",
  buy_ticket_info: "",
  myCartItems: [],
  myContactInfo: "",
  contactInfo: {
    first_name: "",
    last_name: "",
    birthdate: "",
    country_name: "",
    zip: "",
    phone: "",
    is_default: false,
  },
  promoData: "",
  placeOrderData: {},
  myOrderSingle: {},
};

export const state = { ...INITIAL_STATE };

export const actions = {
  async getSingleEventDetails({ commit }, event_id) {
    let { data } = await getSingleEventDetails(event_id);
    commit("set_single_Event_Details", data.information);
  },
  async getSingleEventMembers({ commit }, event_id) {
    let { data } = await getSingleEventMembers(event_id);
    commit("set_single_Event_Members", data.information);
  },
  async getEventRepostMembers({ commit }, payload) {
    try {
      const { event_id, offset = 0, limit = 9} = payload;
      let { data } = await getEventRepostMembers(event_id,offset,limit);
      commit("set_single_Event_Repost_Members", data.information);
    } catch (error) {
      console.error('Error fetching event repost members:', error);
    }
  },
  async getRelatedEvents({ commit }, payload) {
    try {
      const { event_id, offset = 0, limit = 3} = payload;
      let { data } = await getRelatedEvents(event_id,offset,limit);
      commit("set_related_Events", data.information);
    } catch (error) {
      console.error('Error fetching related events:', error);
    }
  },
  async getEventPosts({ commit }, payload) {
    try {
      const { id, offset = 0, limit = 8, append = false } = payload;
      const response = await getEventPosts(id, offset, limit);
      
      if (response && response.data && response.data.information) {
        const responseData = {
          data: response.data.information.data,
          information: {
            total: response.data.information.total
          }
        };

        if (append) {
          commit('APPEND_EVENT_POSTS', responseData);
        } else {
          commit('SET_EVENT_POSTS', responseData);
        }
        return responseData;
      }
      
      return null;
    } catch (error) {
      console.error('Error fetching event posts:', error);
      commit('SET_EVENT_POSTS', { data: [], information: { total: 0 } });
      throw error;
    }
  },
  async getbuyTickets({ commit }, event_id) {
    let { data } = await buyTickets(event_id);
    commit("set_buy_ticket_info", data.information);
  },
  async addToCart({}, payload) {
    await addToCart(payload);
  },
  async myCartItems({ commit }) {
    let { data } = await myCartItems();
    commit("set_my_cart_items", data.information);
  },
  async removeCart({}, cart_id) {
    await removeCart(cart_id);
  },
  async removeContactInfo({ commit }, contact_id) {
    await removeContactInfo(contact_id);
    let { data } = await GetMyContactInfo();
    commit("set_my_contact_info", data.information);
  },
  async addContactInfo({ commit }, payload) {
    await addContactInfo(payload);
    let { data } = await GetMyContactInfo();
    commit("set_my_contact_info", data.information);
  },
  async getMyContactInfo({ commit }) {
    let { data } = await GetMyContactInfo();
    commit("set_my_contact_info", data.information);
  },
  async promoVerification({ commit }, params) {
    let { data } = await promoVerification(params.event_id, {
      promoable_type: params.promoable_type,
      code: params.code,
    });
    commit("set_promo_verification", data);
  },
  async placeOrder({ commit }, payload) {
    await placeOrder(payload);
  },
  async getMyOrderSingle({ commit }) {
    let { data } = await getMyOrderSingle();
    commit("set_my_order_single", data.information);
  },
  async sendTicketViaEmail({}, payload) {
    await sendTicketViaEmail(payload.ticket_id, { email: payload.email });
  },
  async addNewPost({ commit, dispatch }, { post, eventId }) {
    try {
      // First, add the new post optimistically
      commit('PREPEND_NEW_POST', post);
      
      // Then refresh the full list to ensure consistency
      await dispatch('getEventPosts', {
        id: eventId,
        offset: 0,
        limit: 8,
        append: false
      });
    } catch (error) {
      console.error('Error adding new post:', error);
      throw error;
    }
  }
};

export const mutations = {
  set_single_Event_Details(state, payload) {
    state.single_Event_Details = payload;
  },
  set_single_Event_Members(state, payload) {
    state.single_Event_Members = payload;
  },
  set_single_Event_Repost_Members(state, payload) {
    state.single_Event_Repost_Members = payload;
  },
  set_related_Events(state, payload){
    state.related_Events = payload;
  },
  set_event_posts(state, payload) {
    state.event_posts = payload;
  },
  set_buy_ticket_info(state, payload) {
    state.buy_ticket_info = payload;
  },
  set_my_cart_items(state, payload) {
    state.myCartItems = payload;
  },
  set_my_contact_info(state, payload) {
    state.myContactInfo = payload;
  },
  set_comtact_info(state, payload) {
    state.contactInfo = payload;
  },
  set_promo_verification(state, payload) {
    state.promoData = payload;
  },
  set_my_order_single(state, payload) {
    state.myOrderSingle = payload;
  },
  UPDATE_EVENT_POSTS(state, payload) {
    state.event_posts = payload;
  },
  SET_EVENT_POSTS(state, payload) {
    state.event_posts = payload;
  },
  APPEND_EVENT_POSTS(state, payload) {
    if (state.event_posts && state.event_posts.data) {
      state.event_posts.data = [...state.event_posts.data, ...payload.data];
      state.event_posts.information = payload.information;
    } else {
      state.event_posts = payload;
    }
  }
};

const getters = {
  GET_SINGLE_EVENT_DETAILS(state) {
    return state.single_Event_Details;
  },
  GET_SINGLE_EVENT_Members(state) {
    return state.single_Event_Members;
  },
  GET_SINGLE_EVENT_REPOST_MEMBERS(state) {
    return state.single_Event_Repost_Members;
  },
  GET_RELATED_EVENTS(state) {
    return state.related_Events
  },
  GET_EVENT_POSTS(state) {
    return state.event_posts;
  },
  GET_BUYTICKET_INFO(state) {
    return state.buy_ticket_info;
  },
  GET_MY_CART_ITEMS(state) {
    return state.myCartItems;
  },
  GET_MY_CONTACT_INFO(state) {
    return state.myContactInfo;
  },
  GET_CONTACT_INFO(state) {
    return state.contactInfo;
  },
  GET_PROMO(state) {
    return state.promoData;
  },
  GET_MY_ORDER_SINGLE(state) {
    return state.myOrderSingle;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
