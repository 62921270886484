<template>
  <div class="card weekly-top artist list-artist-card-small">
    <div class="row no-gutters">
      <div class="col line-1">
        <div class="cover" :class="{'record':type==='record'}">
          <img width="65" :src="item.artwork_url" alt="">
          <!-- <img src="https://devmusic.theplayground.co.uk/content/themes/theplayground/new/img/demo/popular-artist.png" alt="Generic placeholder image">  -->
        </div>
        <div class="description">

          <div class="first-line"><a href="#">{{ truncatedTitle }}</a></div>
          <div class="second-line">
            {{ item.total_tracks }} songs - {{ item.total_albums }} albums
          </div>
        </div>
        <div class="other d-flex ">
          <div v-if="spinner" class="text-center pr-2">
            <b-spinner class="spinner-color" small label="Small Spinner"></b-spinner>
          </div>
          <button v-else class="btn follow-sm-btn" :class="{'active':follow === 'approved'}" @click="followUnfollow(item)">
            <div class="svg-icon">
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="c-line-1 c-fill-1"
                      d="M1 8.00938C3.757 8.00938 6 10.2547 6 13.0145C6 13.5671 6.448 14.0156 7 14.0156C7.552 14.0156 8 13.5671 8 13.0145C8 9.15056 4.86 6.00732 1 6.00732C0.448 6.00732 0 6.45579 0 7.00835C0 7.56092 0.448 8.00938 1 8.00938Z"
                      fill="#D1433A"></path>
                <path class="c-line-1 c-fill-1"
                      d="M1 2.00206C7.0655 2.00206 12 6.94164 12 13.0134C12 13.566 12.448 14.0144 13 14.0144C13.552 14.0144 14 13.566 14 13.0134C14 5.83801 8.1685 0 1 0C0.448 0 0 0.448461 0 1.00103C0 1.5536 0.448 2.00206 1 2.00206Z"
                      fill="#D1433A"></path>
                <path class="c-line-1 c-fill-1"
                      d="M1 14.0148C1.55228 14.0148 2 13.5666 2 13.0137C2 12.4609 1.55228 12.0127 1 12.0127C0.447715 12.0127 0 12.4609 0 13.0137C0 13.5666 0.447715 14.0148 1 14.0148Z"
                      fill="#D1433A"></path>
              </svg>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  name: "listArtistCardSmall",
  data() {
    return {
      showSubMenu: 'none',
      showMore: false,
      follow: '',
      spinner: false
    }
  },
  components: {},
  props: {
    width: String,
    hasTitleHeader: {
      type: Boolean,
      default: true
    },
    type:{
      type:String,
      default:'artist'
    },
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    truncatedTitle() {
      if (this.item.title.length > 40) {
        return this.item.title.slice(0, 40) + "...";
      } else {
        return this.item.title;
      }
    }
  },
  mounted: function () {
    if(this.item){
      this.follow = this.item.follow_action
    }
  },
  methods: {
    getImgUrl(filename) {
      if (filename) {
        var xhr = new XMLHttpRequest();
        xhr.open('HEAD', filename, false);
        xhr.send();
        if (xhr.status == "404") {
          return require(`@/assets/img/placeholders.png`);
        } else {
          return filename;
        }
      } else {
        return require(`@/assets/img/placeholders.png`);
      }
    },
    async followUnfollow(object) {
      this.spinner = true
      const res =  await this.followOrUnfollowPage(object, this.follow)
      if (res.success === 1) {
        this.follow = (this.follow === 'approved') ? null : 'approved';
      }
      this.spinner = false
    }
  }
}
</script>
<style scoped lang="scss">
@import '/src/assets/style/components/popularArtist.scss';
.list-artist-card-small{
  //max-width: 470px;
  border-radius: 50px 5px 5px 50px;
  border: 0;
  margin-bottom: 10px;
  background: #fff!important;
  word-wrap: break-word;
  .line-1{
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 5px;
    .cover{
      border-radius: 100%;
      &.record{
        border-radius: 4px 0 0 4px !important;
      }
      overflow: hidden;
      width: 60px;
      height: 60px;
      margin-right: 9px;
    }
    .description{
      .first-line{
        font-family: HelveticaNeueMedium, sans-serif !important;
        font-weight: 500!important;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 4px;
        a{
          font-family: HelveticaNeueMedium, sans-serif !important;
          font-size: 13px!important;
          line-height: 16px!important;
          color: #071526!important;
          font-weight: 500 !important;
        }
      }
      .second-line{
        font-family: HelveticaNeueMedium, sans-serif;
        font-weight: 400!important;
        font-size: 13px;
        line-height: 16px;
        opacity: 1!important;
        color: #8B949F;
      }
    }
    .other{
      margin-left: auto;
      margin-right: 25px;
      align-items: center;
    }
  }
}
.spinner-color {
  color: #D1433A!important;
}
</style>
<style scoped>
.content .lists.artists .weekly-top.card {
  margin-left: 40px !important;
  width: 440px !important;
  max-width: 440px !important;
}
</style>

