<template>
  <div class="Signup_pop_overlay">
    <div class="modal fade show" id="login" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         style="display: block; padding-right: 15px;" aria-modal="true">
      <div class="modal-dialog">
        <div class="modal-content" style="width: 572px;">
          <div class="">
            <div class="post-option-container">
              <div class="news-feed-heading">
                <h1>News Feed preferences</h1>
                <div class="create-video-close close-modal-btn" v-on:click="closeModal">
                  <a href="javascript:;">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.5 4.5L4.5 13.5" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                      <path d="M4.5 4.5L13.5 13.5" stroke="#47505B" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                    </svg>
                  </a>
                </div>
              </div>
              <div class="feeds-prefrances-list">
                <ul>
                  <li>
                    <div class="feed-labels">
                      <input id="genres_1" type="radio" name="Main" :checked="checkedIfTabIsActive('')" @click="changeType('')">
                      <label class="artist-check_box-main" for="genres_1">
                        <div class="artist-check-svg-icon">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-icons-class" d="M2 12H14" stroke="#47505B" stroke-width="1.5"
                                  stroke-linejoin="round"></path>
                            <path class="svg-icons-class" d="M2 8H14" stroke="#47505B" stroke-width="1.5"
                                  stroke-linejoin="round"></path>
                            <path class="svg-icons-class" d="M2 4H14" stroke="#47505B" stroke-width="1.5"
                                  stroke-linejoin="round"></path>
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">All</div>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="feed-labels" @click="changeType('posts')">
                      <input id="genres_2" type="radio" name="Main" :checked="checkedIfTabIsActive('posts')">
                      <label class="artist-check_box-main" for="genres_2">
                        <div class="artist-check-svg-icon">
                          <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-icons-class"
                                  d="M12.3333 1H2.41667C1.63426 1 1 1.63426 1 2.41667V12.3333C1 13.1157 1.63426 13.75 2.41667 13.75H12.3333C13.1157 13.75 13.75 13.1157 13.75 12.3333V2.41667C13.75 1.63426 13.1157 1 12.3333 1Z"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path class="svg-icons-class"
                                  d="M4.89648 5.95801C5.48329 5.95801 5.95898 5.48231 5.95898 4.89551C5.95898 4.30871 5.48329 3.83301 4.89648 3.83301C4.30968 3.83301 3.83398 4.30871 3.83398 4.89551C3.83398 5.48231 4.30968 5.95801 4.89648 5.95801Z"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path class="svg-icons-class" d="M13.7493 9.49967L10.2077 5.95801L2.41602 13.7497"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Posts</div>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="feed-labels" @click="changeType('video')">
                      <input id="genres_3" type="radio" :checked="checkedIfTabIsActive('video')" name="Main">
                      <label class="artist-check_box-main" for="genres_3">
                        <div class="artist-check-svg-icon">
                          <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-icons-class"
                                  d="M12.3875 1.25H2.6125C1.86001 1.25 1.25 1.86001 1.25 2.6125V12.3875C1.25 13.14 1.86001 13.75 2.6125 13.75H12.3875C13.14 13.75 13.75 13.14 13.75 12.3875V2.6125C13.75 1.86001 13.14 1.25 12.3875 1.25Z"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path class="svg-icons-class" d="M4.375 1.25V13.75" stroke="#47505B" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svg-icons-class" d="M10.625 1.25V13.75" stroke="#47505B" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svg-icons-class" d="M1.25 7.5H13.75" stroke="#47505B" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svg-icons-class" d="M1.25 4.375H4.375" stroke="#47505B" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svg-icons-class" d="M1.25 10.625H4.375" stroke="#47505B" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svg-icons-class" d="M10.625 10.625H13.75" stroke="#47505B" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svg-icons-class" d="M10.625 4.375H13.75" stroke="#47505B" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Video</div>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="feed-labels">
                      <input id="genres_4" type="radio" name="Main">
                      <label class="artist-check_box-main" for="genres_4">
                        <div class="artist-check-svg-icon">
                          <svg width="16" height="12" viewBox="0 0 16 12" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-icons-class"
                                  d="M7.99997 7.58685C8.72914 7.58685 9.32025 6.99574 9.32025 6.26657C9.32025 5.5374 8.72914 4.94629 7.99997 4.94629C7.2708 4.94629 6.67969 5.5374 6.67969 6.26657C6.67969 6.99574 7.2708 7.58685 7.99997 7.58685Z"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path class="svg-icons-class"
                                  d="M10.7974 3.46781C11.1657 3.83566 11.4579 4.2725 11.6572 4.75334C11.8565 5.23418 11.9591 5.74959 11.9591 6.27011C11.9591 6.79062 11.8565 7.30604 11.6572 7.78688C11.4579 8.26771 11.1657 8.70455 10.7974 9.07241M5.19944 9.0658C4.83118 8.69795 4.53903 8.26111 4.3397 7.78027C4.14037 7.29943 4.03778 6.78402 4.03778 6.26351C4.03778 5.74299 4.14037 5.22758 4.3397 4.74674C4.53903 4.2659 4.83118 3.82906 5.19944 3.46121M12.6656 1.59961C13.9032 2.83756 14.5984 4.51635 14.5984 6.26681C14.5984 8.01726 13.9032 9.69606 12.6656 10.934M3.33124 10.934C2.09367 9.69606 1.39844 8.01726 1.39844 6.26681C1.39844 4.51635 2.09367 2.83756 3.33124 1.59961"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Live TV</div>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="feed-labels" @click="changeType('tagged')">
                      <input id="genres_6" type="radio" name="Main" :checked="checkedIfTabIsActive('tagged')">
                      <label class="artist-check_box-main" for="genres_6">
                        <div class="artist-check-svg-icon">
                          <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-icons-class"
                                  d="M13.6056 8.73899L8.74375 13.6021C8.6178 13.7283 8.46823 13.8283 8.30359 13.8966C8.13896 13.9649 7.96248 14 7.78426 14C7.60604 14 7.42956 13.9649 7.26493 13.8966C7.10029 13.8283 6.95072 13.7283 6.82477 13.6021L1 7.78264V1H7.78087L13.6056 6.82629C13.8582 7.08045 14 7.42427 14 7.78264C14 8.14102 13.8582 8.48483 13.6056 8.73899V8.73899Z"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path class="svg-icons-class" d="M4.17676 4.17773H4.18342" stroke="#47505B"
                                  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Tagged</div>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="feed-labels" @click="changeType('events')">
                      <input id="genres_8" type="radio" name="Main" :checked="checkedIfTabIsActive('events')">
                      <label class="artist-check_box-main" for="genres_8">
                        <div class="artist-check-svg-icon">
                          <svg width="15" height="16" viewBox="0 0 15 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-icons-class" d="M5.01367 4.32894V0" stroke="#47505B"
                                  stroke-width="1.5"></path>
                            <path class="svg-icons-class" d="M10.0742 4.32894V0" stroke="#47505B"
                                  stroke-width="1.5"></path>
                            <path class="svg-icons-class"
                                  d="M2.83219 2.11426H12.1678C12.6529 2.11743 13.1173 2.34104 13.4603 2.73683C13.8034 3.13262 13.9973 3.66852 14 4.22824V12.8862C13.9973 13.4459 13.8034 13.9818 13.4603 14.3776C13.1173 14.7734 12.6529 14.997 12.1678 15.0002H2.83219C2.34711 14.997 1.88267 14.7734 1.53966 14.3776C1.19664 13.9818 1.00275 13.4459 1 12.8862V4.22824C1.01773 3.67407 1.21643 3.14813 1.55627 2.756C1.89611 2.36387 2.35191 2.13472 2.83219 2.11426V2.11426Z"
                                  stroke="#47505B" stroke-width="1.5"></path>
                            <path class="svg-icons-class" d="M2.83496 6.94629H12.2578" stroke="#47505B"
                                  stroke-width="1.5"></path>
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Events</div>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="feed-labels">
                      <input id="genres_9" type="radio" name="Main">
                      <label class="artist-check_box-main" for="genres_9">
                        <div class="artist-check-svg-icon">
                          <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-icons-class"
                                  d="M13.6 3.7998H2.4C1.6268 3.7998 1 4.42661 1 5.1998V12.1998C1 12.973 1.6268 13.5998 2.4 13.5998H13.6C14.3732 13.5998 15 12.973 15 12.1998V5.1998C15 4.42661 14.3732 3.7998 13.6 3.7998Z"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path class="svg-icons-class"
                                  d="M10.7992 13.6V2.4C10.7992 2.0287 10.6517 1.6726 10.3892 1.41005C10.1266 1.1475 9.77052 1 9.39922 1H6.59922C6.22792 1 5.87182 1.1475 5.60927 1.41005C5.34672 1.6726 5.19922 2.0287 5.19922 2.4V13.6"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Jobs</div>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="feed-labels">
                      <input id="genres_10" type="radio" name="Main">
                      <label class="artist-check_box-main" for="genres_10">
                        <div class="artist-check-svg-icon">
                          <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-icons-class"
                                  d="M4.25 1.41699L2.125 4.25033V14.167C2.125 14.5427 2.27426 14.9031 2.53993 15.1687C2.80561 15.4344 3.16594 15.5837 3.54167 15.5837H13.4583C13.8341 15.5837 14.1944 15.4344 14.4601 15.1687C14.7257 14.9031 14.875 14.5427 14.875 14.167V4.25033L12.75 1.41699H4.25Z"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                            <path class="svg-icons-class" d="M2.125 4.25H14.875" stroke="#47505B" stroke-width="1.5"
                                  stroke-linecap="round" stroke-linejoin="round"></path>
                            <path class="svg-icons-class"
                                  d="M11.3337 7.08301C11.3337 7.83445 11.0351 8.55512 10.5038 9.08648C9.97244 9.61783 9.25177 9.91634 8.50033 9.91634C7.74888 9.91634 7.02821 9.61783 6.49686 9.08648C5.9655 8.55512 5.66699 7.83445 5.66699 7.08301"
                                  stroke="#47505B" stroke-width="1.5" stroke-linecap="round"
                                  stroke-linejoin="round"></path>
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Products</div>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="feed-labels" @click="changeType('articles')">
                      <input id="genres_11" type="radio" name="Main" :checked="checkedIfTabIsActive('articles')">
                      <label class="artist-check_box-main" for="genres_11">
                        <div class="artist-check-svg-icon">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-icons-class"
                                  d="M8.66699 1.33301H4.00033C3.6467 1.33301 3.30756 1.47348 3.05752 1.72353C2.80747 1.97358 2.66699 2.31272 2.66699 2.66634V13.333C2.66699 13.6866 2.80747 14.0258 3.05752 14.2758C3.30756 14.5259 3.6467 14.6663 4.00033 14.6663H12.0003C12.3539 14.6663 12.6931 14.5259 12.9431 14.2758C13.1932 14.0258 13.3337 13.6866 13.3337 13.333V5.99967L8.66699 1.33301Z"
                                  stroke="#47505B" stroke-width="1.5" stroke-linejoin="round"></path>
                            <path class="svg-icons-class" d="M8.66699 1.33301V5.99967H13.3337" stroke="#47505B"
                                  stroke-width="1.5" stroke-linejoin="round"></path>
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Articles</div>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div class="feed-labels" @click="changeType('polls')">
                      <input id="genres_12" type="radio" name="Main" :checked="checkedIfTabIsActive('polls')">
                      <label class="artist-check_box-main" for="genres_12">
                        <div class="artist-check-svg-icon">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path class="svg-icons-class"
                                  d="M14.1399 10.5934C13.7158 11.5964 13.0525 12.4802 12.2079 13.1676C11.3633 13.855 10.3631 14.325 9.2949 14.5366C8.22668 14.7481 7.12289 14.6948 6.08004 14.3813C5.03719 14.0677 4.08703 13.5034 3.31262 12.7378C2.53822 11.9722 1.96315 11.0286 1.6377 9.98935C1.31225 8.95015 1.24632 7.84704 1.44568 6.77647C1.64503 5.70591 2.10361 4.70047 2.78131 3.84807C3.45901 2.99567 4.3352 2.32226 5.33328 1.88672"
                                  stroke="#47505B" stroke-width="1.5" stroke-linejoin="round"></path>
                            <path class="svg-icons-class"
                                  d="M14.6667 7.99967C14.6667 7.12419 14.4942 6.25729 14.1592 5.44845C13.8242 4.63961 13.3331 3.90469 12.714 3.28563C12.095 2.66657 11.3601 2.17551 10.5512 1.84048C9.74239 1.50545 8.87548 1.33301 8 1.33301V7.99967H14.6667Z"
                                  stroke="#47505B" stroke-width="1.5" stroke-linejoin="round"></path>
                          </svg>
                        </div>
                        <div class="artist-check-svg-text">Polls</div>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
export default {
  data() {
    return {
      currentTab: 'upload-photo',
      genres2: false,
      subGenres: false,
      type : ''
    }
  },
  props: {
    currentType: {
      type: String,
      default: ''
    }
  },
  watch:{
    'route.name'() {
      this.$store.commit('SET_POST_TYPE', '')
    },
    currentType() {
      this.type = this.currentType.toLowerCase()
    }
  },
  components: {},
  computed:{
    route(){
      return this.$route
    },
    getPostType() {
      return this.$store.getters.getPostType
    }
  },
  mounted() {
    if (!this.currentType) {
      this.setPostType()
    }
  },
  methods: {
    closeModal: function () {
      this.$emit('closeModal');
    },
    changeType(type) {
      this.type = type
      this.$store.commit('SET_POST_TYPE', type)
      this.$emit('filter', type)
    },
    setPostType() {
      this.type = this.getPostType
    },
    checkedIfTabIsActive(type) {
      return this.type === type
    }
  },
}
</script>
<style scoped lang="scss">
@media (min-width: 576px) {
  .modal-dialog {
    max-width: unset!important;
    margin: 1.75rem auto;
  }
}
.Signup_pop_overlay {
  .modal {
    .modal-dialog {
      top: 118px;
      width: 560px;

      .modal-content {
        .post-option-container {
          padding: 1.5rem;
        }
      }
    }
  }
}
</style>
