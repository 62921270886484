<template>
  <div class="center_centerbar_wraper mt-4 mb-5">
    <div class="right-setting-bar">
      <div class="setting-header">
        <div class="setting-header-inner d-flex align-items-center">
          <div class="svg-icon">
            <svg
              width="14"
              height="13"
              viewBox="0 0 14 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3333 13V11.3333C13.3333 10.4493 12.9821 9.60143 12.357 8.97631C11.7319 8.35119 10.8841 8 10 8H3.33333C2.44928 8 1.60143 8.35119 0.976311 8.97631C0.351189 9.60143 0 10.4493 0 11.3333V13"
                fill="#47505B"
              />
              <path
                d="M6.66683 6.66667C8.50778 6.66667 10.0002 5.17428 10.0002 3.33333C10.0002 1.49238 8.50778 0 6.66683 0C4.82588 0 3.3335 1.49238 3.3335 3.33333C3.3335 5.17428 4.82588 6.66667 6.66683 6.66667Z"
                fill="#47505B"
              />
            </svg>
          </div>
          <p class="ml-3 mb-0">Basic Info</p>
          <button
          class="btn-view ml-auto mr41"
            type="button"
            @click="redirectToProfilePage(username)"
          >
            <span class="pr-2">
             <EyeIcon/>
            </span>
            View Profile
          </button>
        </div>
        <div class="setting-content-inner">
          <form class="setting-form" @submit="submit">
            <div v-if="message" class="alert alert-danger" role="alert">
              {{ message }}
            </div>
            <div
              v-if="success_message"
              class="alert alert-success"
              role="alert"
            >
              {{ success_message }}
            </div>
            <div class="row">
              <div class="col" style="padding-right: unset;">
                <div class="form-group">
                  <label for="text">First Name</label>
                  <input
                    type="text"
                    class="form-control first-name width200"
                    name="edit_profile_form_first_name"
                    @change="onChange"
                    :value="first_name"
                    placeholder="John"
                  />
                  <label
                    class="error_msg error_msg_red"
                    v-if="_handleErrorMessage('first_name')"
                    >{{ _handleErrorMessage("first_name") }}</label
                  >
                </div>
              </div>
              <div class="col fixBasicInfo">
                <div class="form-group">
                  <label for="text">Last Name</label>
                  <input
                    type="text"
                    class="form-control last-name width200 "
                    name="edit_profile_form_last_name"
                    @change="onChange"
                    :value="last_name"
                    placeholder="Deol"
                  />
                  <label
                    class="error_msg error_msg_red"
                    v-if="_handleErrorMessage('last_name')"
                    >{{ _handleErrorMessage("last_name") }}</label
                  >
                </div>
              </div>
              <div class="col"></div>
            </div>
            <div class="row">
              <div class="col" style="padding-right: unset;">
                <div class="form-group">
                  <label for="Select">I am</label>
                  <SelectDropdown
                    :options="genders"
                    v-model="gender"
                    @change="onSelect"
                    name="edit_profile_form_gender"
                    :style="'width: 200px; border: 1px solid #E6EAF0;'"
                  />
                  <label
                    class="error_msg error_msg_red"
                    v-if="_handleErrorMessage('gender')"
                    >{{ _handleErrorMessage("gender") }}</label
                  >
                </div>
              </div>
              <div class="col fixBasicInfo" >
                <div class="form-group">
                  <label for="Select">Relationship Status</label>
                  <SelectDropdown
                    :options="relationships"
                    v-model="relationship_status"
                    name="edit_profile_form_relationship_status"
                    @change="onSelect"
                    :style="'width: 200px; border: 1px solid #E6EAF0;'"
                  />
                  <label
                    class="error_msg error_msg_red"
                    v-if="_handleErrorMessage('relationship_status')"
                    >{{ _handleErrorMessage("relationship_status") }}</label
                  >
                </div>
              </div>
              <div class="col"></div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="Select">Country</label>
                  <SelectDropdown
                    :options="countries"
                    v-model="selected_country"
                    @change="onSelect"
                    name="edit_profile_form_country"
                    :style="'width: 200px; border: 1px solid #E6EAF0;'"
                  />
                  <label
                    class="error_msg error_msg_red"
                    v-if="_handleErrorMessage('country')"
                    >{{ _handleErrorMessage("country") }}</label
                  >
                </div>
              </div>
              <div class="col-md-8 fixBasicInfo1">
                <div class="form-group website_link">
                  <label for="text">Website</label>
                  <input
                    type="text"
                    class="form-control width500"
                    name="edit_profile_form_website"
                    @change="onChange"
                    v-model="website"
                    placeholder=""
                  />
                  <span class="sm_txt"
                    >Website link must start with http:// or https://</span
                  >
                  <br />
                  <label
                    class="error_msg error_msg_red"
                    v-if="_handleErrorMessage('website')"
                    >{{ _handleErrorMessage("website") }}</label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="Select">Birthdate</label>
                  <SelectDropdown
                    :options="days"
                    name="edit_profile_form_dob_date"
                    v-model="date"
                    @change="onSelect"
                    :style="'width: 200px; border: 1px solid #E6EAF0;'"
                  />
                  <label
                    class="error_msg error_msg_red"
                    v-if="
                      _handleErrorMessage('date') ||
                      _handleErrorMessage('year') ||
                      _handleErrorMessage('month')
                    "
                    >{{
                      _handleErrorMessage("date") ||
                      _handleErrorMessage("year") ||
                      _handleErrorMessage("month")
                    }}</label
                  >
                </div>
              </div>
              <div class="col-md-4 fixBasicInfo1">
                <div class="form-group">
                  <SelectDropdown
                    class="mt-c"
                    :options="months"
                    name="edit_profile_form_dob_month"
                    v-model="month"
                    @change="onSelect"
                    :style="'width: 200px; border: 1px solid #E6EAF0;'"
                  />
                </div>
              </div>
              <div class="col-md-4 fixBasicInfo1">
                <div class="form-group">
                  <SelectDropdown
                    class="mt-c"
                    :options="years"
                    name="edit_profile_form_dob_year"
                    v-model="year"
                    @change="onSelect"
                    :style="'width: 200px; border: 1px solid #E6EAF0;'"
                  />
                </div>
              </div>
              <div class="col"></div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="label-title"
                    >My Favourite Main Music Genre</label
                  >
                  <Select2
                    v-model="selectedGenre"
                    @select="changeGenre($event)"
                    placeholder="Genres"
                    :options="geners"
                    :settings="{ multiple: true }"
                  />
                  <label
                    class="error_msg error_msg_red"
                    v-if="_handleErrorMessage('geners')"
                    >{{ _handleErrorMessage("geners") }}</label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="label-title"
                    >My Favourite Sub-Genres<span class="list-text"
                      > (List 4)</span
                    ></label
                  >
                  <Select2
                    v-model="selectedSubGeneres"
                    placeholder="Sub-Genres"
                    :options="sub_geners"
                    :settings="{ multiple: true }"
                  />
                  <label
                    class="error_msg error_msg_red"
                    v-if="_handleErrorMessage('sub_geners')"
                    >{{ _handleErrorMessage("sub_geners") }}</label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="textarea"
                    >Short About Me
                    <span class="list-text"> (up to 100 characters)</span></label
                  >
                  <textarea
                    class="form-control"
                    name="edit_profile_form_about_me"
                    @change="onChange"
                    :value="about_me"
                    columns="3"
                  ></textarea>
                  <label
                    class="error_msg error_msg_red"
                    v-if="_handleErrorMessage('about_me')"
                    >{{ _handleErrorMessage("about_me") }}</label
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <button type="submit" class="save-btn">
                  {{ loading ? "Please Wait..." : "Save Changes" }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class=""></div>
    </div>
    <full-page-loader :show="loading" />
  </div>
</template>

<script type="application/javascript">
import { mapActions, mapMutations, mapGetters } from "vuex";
import * as Utils from "../../../components/utils/Utils";
import * as Helper from "../../../apis/Helper.js";
import {
  EDIT_PROFILE_ERRORS,
  SUCCESS,
  ERROR,
  USER_DATA,
  EDIT_PROFILE_FORM_COUNTRY,
} from "../../../store/types";
import {
  get_countries_list,
  get_geners_list,
  update_geners_api,
  update_subgeners_api,
} from "../../../apis/APIs";
import Select2 from "v-select2-component";
import EyeIcon from "../../../assets/svgs/EyeIcon.vue";
import SelectDropdown from "../../Common/Dropdown/SelectDropdown.vue";

export default {
  data() {
    return {
      message: "",
      success_message: "",
      dayName: "",
      monthName: "",
      yearName: "",
      myOptions: [
        "op1",
        "op2",
        "op3",
        "op4",
        "op5",
        "op6",
        "op7",
        "op8",
        "op9",
      ],
      fetchedSubGeneres: [],
      country: null,
      geners: [],
      sub_geners: [],
      selected_gener: [],
      selected_sub_gener: [],
      geners_list_loading: false,
      loading: false,
      days: Utils.getMonthDays(new Date().getFullYear(), new Date().getMonth()),
      months: Utils.getMonths(),
      years: Utils.getYears(),
    };
  },
  props: {
    activeHeader: String,
  },
  components: {
    Select2,
    EyeIcon,
    SelectDropdown
  },
  watch: {
    month(newMonth) {
      this.updateDays(this.year, newMonth);
    },
    year(newYear) {
      this.updateDays(newYear, this.month);
    },
  },
  mounted() {
    this.update_basic_info_form();
    this.geners_list();
    this.$store.dispatch("fetchCountries");
    this.fetchUserData();
  },
  beforeDestroy() {
    this.resetEditProfileState();
  },
  computed: {
    ...mapGetters({
      form_data: "edit_profile_form_map_to_props",
      user: "user_map_to_props",
    }),
    email() {
      const { email } = this.form_data || {};

      return email;
    },
    first_name() {
      const { first_name } = this.form_data || {};

      return first_name;
    },
    last_name() {
      const { last_name } = this.form_data || {};

      return last_name;
    },
    gender() {
      const { gender } = this.form_data || {};
      return gender;
    },
    relationship_status() {
      const { relationship_status } = this.form_data || {};
      return relationship_status;
    },
    selected_country: {
      set(newValue) {
        this.updateEditProfileFormData({
          [EDIT_PROFILE_FORM_COUNTRY]: newValue,
        });
      },
      get() {
        const { country } = this.form_data || {};
        if (Array.isArray(country)) {
          return country && country.length > 0 ? country[0].id : "";
        } else {
          return country;
        }
      },
    },
    countries() {
      return this.$store.getters.getCountries.map((country) => {
        return {
          value: country.id,
          label: country.name,
        };
      });
    },
    genders(){
      return [
        { value: "", label: "Select Gender", is_selected: true },
        { label: "Male", value: "M", is_selected: false },
        { label: "Female", value: "F", is_selected: false },
        { label: "Other", value: "O", is_selected: false },
      ]
    },
    relationships(){
      return [
        { value: "", label: "Select Relationship", is_selected: true },
        { label: "Single", value: "Single", is_selected: false },
        { label: "Married", value: "Married", is_selected: false },
      ]
    },
    website() {
      const { website } = this.form_data || {};

      return website;
    },
    date() {
      const { dob_date } = this.form_data || {};

      return dob_date;
    },
    year() {
      const { dob_year } = this.form_data || {};

      return dob_year;
    },
    month() {
      const { dob_month } = this.form_data || {};

      return dob_month;
    },
    about_me() {
      const { about_me } = this.form_data || {};

      return about_me;
    },
    selectedGenre: {
      set(newValue) {
        this.selected_gener = newValue;
      },
      get() {
        const { user_data } = this.user || {};
        const { genres } = user_data || {};
        if (genres && genres.length > 0) {
          return genres.map((ele) => ele.id);
        }
        return [];
      },
    },
    selectedSubGeneres: {
      set(newValue) {
        this.selected_sub_gener = newValue;
      },
      get() {
        const { user_data } = this.user || {};
        const { subGenresIds } = user_data || {};

        if (this.selectedGenre && this.selectedGenre.length) {
          let selected_sub_geners = [];

          selected_sub_geners = subGenresIds.map((obj) => ({
            id: obj.id,
            text: obj.name,
            genre_id: obj.genre_id,
          }));

          return selected_sub_geners && selected_sub_geners.length
            ? selected_sub_geners.map((ele) => ele.id)
            : [];
        }
        return [];
      },
    },
    errors() {
      const { errors = [] } = this.form_data || {};
      return errors;
    },
  },
  methods: {
    ...mapActions([
      "edit_user_profile",
      "update_basic_info_form",
      "fetchUserData",
    ]),
    ...mapMutations([
      "updateEditProfileFormData",
      "updateEditProfileUIConstraints",
      "resetEditProfileState",
      "updateUserUIConstraints",
    ]),
    updateDays(year, month) {
      this.days = Utils.getMonthDays(year, month);
    },
    async geners_list() {
      try {
        this.geners_list_loading = true;
        const res = await get_geners_list();
        const { success, information } = res.data || {};
        if (success === 1) {
          if (information && information.length) {
            this.geners = information.map((ele) => ({
              id: ele.id,
              text: ele.name,
              subgenre: ele.subgenre,
            }));

            this.geners.forEach((ele) => {
              this.changeGenre({ id: ele.id, selected: true });
            });
          }
        }
        this.geners_list_loading = false;
      } catch (error) {
        this.geners_list_loading = false;
      }
    },
    isError(key) {
      if (this.errors && this.errors.length) {
        return this.errors.findIndex((ele) => ele.fieldName === key) > -1
          ? {
              status: true,
              message:
                this.errors[
                  this.errors.findIndex((ele) => ele.fieldName === key)
                ].message,
            }
          : { status: false, message: "" };
      } else return { status: false, message: "" };
    },
    _handleErrorMessage(key) {
      const { message = "" } = this.isError(key);

      return message;
    },
    onChange(e) {
      const { name, value } = e.target || {};


      this.updateEditProfileFormData({
        [name]: value,
      });
    },
    onSelect(name, value) {
      this.updateEditProfileFormData({
        [name]: value,
      });
    },
    submit(e) {
      if (e) e.preventDefault();
      const {
        loading,
        first_name,
        last_name,
        gender,
        date,
        year,
        month,
        website,
        relationship_status,
        selected_country,
        about_me,
      } = this;

      const { user_data } = this.user || {};
      const { id } = user_data || {};

      if (loading) return;

      const selected_geners =
        this.selected_gener && this.selected_gener.length
          ? this.selected_gener
          : [];
      const selected_sub_geners =
        this.selected_sub_gener && this.selected_sub_gener.length
          ? this.selected_sub_gener
          : [];

      const body = {
        first_name,
        last_name,
        gender,
        date,
        month,
        year,
        relationship_status,
        selected_country,
        website,
        about_me,
        geners: selected_geners,
        sub_geners: selected_sub_geners,
      };
      Helper.validate(Object.keys(body), body)
        .then(async ({ status, response }) => {
          if (status) {
            this.updateEditProfileUIConstraints({
              [EDIT_PROFILE_ERRORS]: [],
            });
            try {
              this.message = "";
              this.loading = true;
              if(about_me.length > 180){
                this.message = "The about me may not be greater than 180 characters.";
                window.scroll({
                    top: 100,
                    left: 100,
                    behavior: 'smooth'
                  });
                this.loading= false;
              }
              const { status, message } = await this.edit_user_profile(body);
              await this.fetchUserData();
              if (selected_geners && selected_geners.length > 0) {
                await this.genreRequest(selected_geners);
              }
              if (selected_sub_geners && selected_sub_geners.length > 0) {
                await this.subgenreRequest(selected_sub_geners);
              }
              this.loading = false;
              switch (status) {
                case SUCCESS:
                  this.success_message = message;
                  setTimeout(() => (this.success_message = ""), 5000);
                  break;
                case ERROR:
                  this.message = message;
                  break;
              }
            } catch (e) {
              this.message = e && e.message ? e.message : "Please try again.";
            }
          } else
            this.updateEditProfileUIConstraints({
              [EDIT_PROFILE_ERRORS]:
                response && response.length ? response : [],
            });
        })
        .catch((err) => console.log(err));
    },
    changeGenre(e) {
      const { id, selected } = e || {};

      if (selected) {
        this.geners.forEach((ele) => {
          if (ele && ele.id === id && ele.subgenre && ele.subgenre.length) {
            const data = ele.subgenre.map((ele) => ({
              id: ele.id,
              text: ele.name,
              genre_id: ele.genre_id,
            }));
            this.sub_geners = [...this.sub_geners, ...data];
          }
          // const data = ele.subgenre.map(ele => ({ id: ele.id, text: ele.name, genre_id: ele.genre_id}));
          // this.sub_geners = [...this.sub_geners, ...data];
        });
      } else {
        this.sub_geners = this.sub_geners
          .map((ele) => {
            if (ele.id === id) {
              const index =
                this.selected_sub_gener && this.selected_sub_gener.length
                  ? this.selected_sub_gener.indexOf(ele.id)
                  : -1;
              if (index !== -1) this.selected_sub_gener.splice(index, 1);

              return null;
            } else return ele;
          })
          .filter((ele) => ele);
      }
    },
    async genreRequest(selected_geners) {
      try {
        this.message = "";
        const genreData = new FormData();
        for (let i = 0; i < selected_geners.length; i++) {
          let genre_data = selected_geners[i];
          genreData.append("genreIds[" + i + "]", genre_data);
        }
        const resGenre = await update_geners_api(genreData);
        const { information, message, success } = resGenre.data || {};

        if (information) {
          this.$store.commit("updateUserUIConstraints", {
            [USER_DATA]: information,
          });
        }
        await this.fetchUserData();
        if (success === 1) {
          this.success_message = message;
          setTimeout(() => (this.success_message = ""), 5000);
        } else {
          this.message = message;
        }
      } catch (e) {
        this.message = e && e.message ? e.message : "Please try again.";
      }
    },
    async subgenreRequest(selected_sub_geners) {
      try {
        const subgenreData = new FormData();
        for (let i = 0; i < selected_sub_geners.length; i++) {
          let subgenre_data = selected_sub_geners[i];
          subgenreData.append("subGenreIds[" + i + "]", subgenre_data);
        }
        const resSubgenre = await update_subgeners_api(subgenreData);
        const { information, message, success } = resSubgenre.data || {};

        if (information) {
          this.$store.commit("updateUserUIConstraints", {
            [USER_DATA]: information,
          });
        }
        await this.fetchUserData();
        if (success === 1) {
          this.success_message = message;
          setTimeout(() => (this.success_message = ""), 5000);
        } else {
          this.message = message;
        }
      } catch (e) {}
    },
  },
};
</script>
