<template>
  <div class="boards" v-if="listData">
    <hr class="hr">
    <div class="w-100 mb-3">
      <h4 class="week-heading">WEEKLY TOP  BOARDS </h4>
    </div>
    <div class="row mb-31">
      <div class="col-md-7 col-lg-7 col-sm-12">
        <h4 class="title-head top-sign-bottom">
          Top 100
          {{ getUserCountryCode ? getUserCountryCode : "Global" }} Singles
          <div class="view-chart-btn cursor-pointer">
            <a
              @click="
                redirectToChannel(
                  'country',
                  'top-100-by-country',
                  getUserCountryName,
                  getUserCountryId
                )
              "
            >
              VIEW CHART
              <svg
                width="7"
                height="11"
                viewBox="0 0 7 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L6.135 5.173L1 9.713"
                  stroke="#47505B"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
        </h4>
        <div class="row">
          <SongsPopularity
            :listData="getChannelData('top-100-uk-singles')"
            :showRanking="true"
            :showNumbering="true"
            key="top-100-uk-singles"
          />
        </div>
      </div>
      <div class="col-md-5 col-lg-5 col-sm-12">
        <h4 class="title-head popular-head">
          <span class="artists-label">Popular artists</span>
          <div class="view-chart-btn view-all-btn cursor-pointer">
            <a
              @click="
                redirectToChannel(
                  'artist',
                  'popular-artists',
                  'Popular Artists'
                )
              "
            >
              VIEW ALL
              <svg
                width="7"
                height="11"
                viewBox="0 0 7 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L6.135 5.173L1 9.713"
                  stroke="#47505B"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
        </h4>
        <div class="lists artists round-avatar m-t-34">
          <PopularArtist :listData="getChannelData('popular-artists')" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7 col-lg-7 col-sm-12">
        <h4 class="title-head top-sign-bottom">
          Top 100
          {{ getUserCountryCode ? getUserCountryCode : "Global" }} Albums
          <div class="view-chart-btn cursor-pointer">
            <a
              @click="
                redirectToChannel(
                  'country',
                  'top-100-by-country',
                  getUserCountryName,
                  getUserCountryId
                )
              "
            >
              VIEW CHART
              <svg
                width="7"
                height="11"
                viewBox="0 0 7 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L6.135 5.173L1 9.713"
                  stroke="#47505B"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
        </h4>
        <div class="lists">
          <div class="row">
            <SongsPopularity
              :width="'670 px'"
              :showRanking="true"
              :showNumbering="true"
              :hasTitleHeader="false"
              :listData="getChannelData('top-100-uk-albums')"
              key="top-100-uk-albums"
            />
          </div>
        </div>
      </div>
      <div class="col-md-5 col-lg-5 col-sm-12">
        <h4 class="title-head popular-head">
          <span class="artists-label">Popular Labels</span>
          <div class="view-chart-btn cursor-pointer">
            <a
              @click="
                redirectToChannel('label', 'popular-labels', 'Popular Label')
              "
            >
              VIEW ALL
              <svg
                width="7"
                height="11"
                viewBox="0 0 7 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L6.135 5.173L1 9.713"
                  stroke="#47505B"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
            <!-- <a @click="redirect('popular-label-listing')">
              VIEW ALL
              <svg
                width="7"
                height="11"
                viewBox="0 0 7 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L6.135 5.173L1 9.713"
                  stroke="#47505B"
                  stroke-width="1.3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a> -->
          </div>
        </h4>
        <div class="lists artists round-avatar m-t-34">
          <div class="row">
            <div class="col">
              <PopularLabels :listData="getChannelData('popular-labels')" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import SongsPopularity from "@/components/Common/Elements/SongsPopularity.vue";
import PopularArtist from "@/components/Common/Elements/PopularArtist.vue";
import PopularLabels from "@/components/Common/Elements/PopularLabels.vue";
export default {
  data() {
    return {
      optionWidth: 0,
      isHovering: false,
      audio: null,
    };
  },
  components: {
    PopularLabels,
    PopularArtist,
    SongsPopularity,
  },
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    getUserCountryCode() {
      return this.$store.getters.get_user_country_code;
    },
    getUserCountry() {
      return this.$store.getters.get_user_country;
    },
    getUserCountryId() {
      if (this.getUserCountry.length > 0) {
        return this.getUserCountry[0].id;
      }
      return "global";
    },
    getUserCountryName() {
      if (this.getUserCountry.length > 0) {
        return this.getUserCountry[0].name;
      }
      return "global";
    },
  },
  mounted: function() {},
  methods: {
    getChannelData(name) {
      const index = this.listData.findIndex((obj) => obj.alt_name === name);
      if (index !== null) {
        return this.listData[index].objects.data.slice(0, 5);
      }
      return [];
    },
    redirect: function(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped lang="scss">
.view-all-btn svg {
  margin-left: 24px;
}
.artists-label {
  color:#071526;
  font-family: HelveticaNeueBold, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}
.popular-head {
  width: 440px !important;
  margin-left: 40px !important;
}
.hr {
  height: 1px;
  background-color: #C4CCD6;
}
.pg-view-chart-btn svg {
  margin-left: 20px !important;
}
.mb-31 {
  margin-bottom: 31px;
}

@media screen and (min-width: 2560px) {
  .mb-31 {
    margin-bottom: 1.615vw;
  }
}
</style>
