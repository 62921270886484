<template>
    <div class="page-notifications">
        <div class="row notifications-header">
            <div class="col">
                <h1 class="Page_title">Notifications</h1>
            </div>
        </div>
        <div class="page-notifications-content">
            <div class="notification-panel-left">
                <div class="notification-nav">
                    <nav class="nav-tabs">
                        <a href="#" class="nav-item" :class="{ active: activeTab === 'ALL' }" @click="setActiveTab('ALL')">ALL</a>
                        <a href="#" class="nav-item" :class="{ active: activeTab === 'SOCIAL' }" @click="setActiveTab('SOCIAL')">SOCIAL</a>
                        <a href="#" class="nav-item" :class="{ active: activeTab === 'MUSIC' }" @click="setActiveTab('MUSIC')">MUSIC</a>
                    </nav>
                </div>
                <div class="np-left--body">
                    <div class="notifications-view">
                        <h2 class="view-title">View Notifications</h2>
                        <p class="view-subtitle">Review Your Notifications</p>
                    </div>
                    <ul class="notifications-list-container fixNotificationsList">
                        <NotificationTile
                            :other-notifications="otherNotifications"
                        />
                    </ul>
                </div>
            </div>

            <!-- rightbar -->
            <div class="notification-panel-right">
                <div class="filters-panel">
                    <div class="filters-header">
                        <h3>Filters</h3>
                        <button class="clear-history" @click="clearFilters">Clear all history</button>
                    </div>
                    <div class="filters-divider"></div>
                    <div class="notification-types">
                        <h4>Notification Type</h4>
                        <div class="notification-options">
                            <!-- checkbox -->
                            <label class="notification-option">
                                <input type="checkbox" id="checkbox"
                                class="checkbox" v-model="filters.all" @change="selectAll">
                                <span>All</span>
                            </label>
                            <label class="notification-option">
                                <input type="checkbox" id="checkbox"
                                class="checkbox" v-model="filters.comments">
                                <span>Comments</span>
                            </label>
                            <label class="notification-option">
                                <input type="checkbox" id="checkbox"
                                class="checkbox" v-model="filters.tags">
                                <span>Tags</span>
                            </label>
                            <label class="notification-option">
                                <input type="checkbox" id="checkbox"
                                class="checkbox" v-model="filters.reminders">
                                <span>Reminders</span>
                            </label>
                            <label class="notification-option">
                                <input type="checkbox" id="checkbox"
                                class="checkbox" v-model="filters.friends">
                                <span>Friends</span>
                            </label>
                            <label class="notification-option">
                                <input type="checkbox" id="checkbox"
                                class="checkbox" v-model="filters.requests">
                                <span>Requests</span>
                            </label>
                            <label class="notification-option">
                                <input type="checkbox" id="checkbox"
                                class="checkbox" v-model="filters.birthdays">
                                <span>Birthdays</span>
                            </label>
                            <label class="notification-option">
                                <input type="checkbox" id="checkbox"
                                class="checkbox" v-model="filters.groups">
                                <span>Groups</span>
                            </label>
                            <label class="notification-option">
                                <input type="checkbox" id="checkbox"
                                class="checkbox" v-model="filters.music">
                                <span>Music</span>
                            </label>
                            <label class="notification-option">
                                <input type="checkbox" id="checkbox"
                                class="checkbox" v-model="filters.video">
                                <span>Video</span>
                            </label>
                            <label class="notification-option">
                                <input type="checkbox" id="checkbox"
                                class="checkbox" v-model="filters.liveStream">
                                <span>Live Stream</span>
                            </label>
                            <label class="notification-option">
                                <input type="checkbox" id="checkbox"
                                class="checkbox" v-model="filters.events">
                                <span>Events</span>
                            </label>
                            <label class="notification-option">
                                <input type="checkbox" id="checkbox"
                                class="checkbox" v-model="filters.pages">
                                <span>Pages</span>
                            </label>
                            <label class="notification-option">
                                <input type="checkbox" id="checkbox"
                                class="checkbox" v-model="filters.jobs">
                                <span>Jobs</span>
                            </label>
                            <label class="notification-option">
                                <input type="checkbox" id="checkbox"
                                class="checkbox" v-model="filters.market">
                                <span>Market</span>
                            </label>
                        </div>
                        <div class="search-section">
                            <input type="text" placeholder="Search" v-model="searchQuery" class="search-input">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.498" d="M12.1 6.54306C12.1 3.48174 9.61518 1.00006 6.55 1.00006C3.48482 1.00006 1 3.48174 1 6.54306C1 9.60437 3.48482 12.0861 6.55 12.0861C9.61518 12.0861 12.1 9.60437 12.1 6.54306Z" stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                                <path opacity="0.498" d="M12.5 12.501L14.5 14.501" stroke="#47505B" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NotificationTile from './NotificationTile.vue';
export default {
    name: "PageNotifications",
    components: {
        NotificationTile
    },
    data() {
        return {
            activeTab: 'ALL',
            searchQuery: '',
            filters: {
                all: true,
                comments: false,
                tags: false,
                reminders: false,
                friends: false,
                requests: false,
                birthdays: false,
                groups: false,
                music: false,
                video: false,
                liveStream: false,
                events: false,
                pages: false,
                jobs: false,
                market: false
            }
        }
    },
    computed: {
        otherNotifications() {
            return this.$store.getters.getGetStreamOtherNotifications;
        }
    },
    methods: {
        setActiveTab(tab) {
            this.activeTab = tab;
        },
        clearFilters() {
            Object.keys(this.filters).forEach(key => {
                this.filters[key] = false;
            });
            this.filters.all = true;
            this.searchQuery = '';
        },
        selectAll() {
            const newValue = this.filters.all;
            Object.keys(this.filters).forEach(key => {
                this.filters[key] = newValue;
            });
        },
       
    }
}
</script>

<style scoped>
.page-notifications {
    display: block !important;
}
.page-notifications-content {
    width: calc(770px + 370px + 30px);
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    height: fit-content;
}
.notification-panel-left{
    border: 1px solid #E6EAF0;
    border-radius: 4px;
    width: min-content;
    overflow: hidden;
    position: relative;
    margin-bottom: 70px;
}
.notification-nav {
    width: 770px;
    min-width: 770px;
    height: 70px;
    border-bottom: 1px solid #E6EAF0;
    background-color: #fff;
}

.nav-tabs {
    display: flex;
    height: 100%;
    align-items: center;
    gap: 40px;
    padding: 0 20px;
}

.nav-item {
    text-decoration: none;
    color: #C4CCD6;
    font-size: 13px;
    font-weight: 500;
    padding: 10px 0;
    position: relative;
}

.nav-item.active {
    color: #D1433A;
    padding: 1.4rem 0;
}

.nav-item.active::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #D1433A;
}


.np-left--body{
    padding: 20px;
    padding-bottom: 1px;
    overflow-y: auto;
    overflow-x: hidden;
}
/* notifications view */
.notifications-view {
    width: 730px;
    height: 80px;
    background-color: #D1433A1A;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 10px;
}

.view-title {
    font-size: 16px;
    font-family: HelveticaNeueBold, sans-serif;
    font-weight: 600;
    color: #071526;
    margin: 0;
    margin-bottom: 10px;
    opacity: 0.9;
}

.view-subtitle {
    font-family: HelveticaNeueNormal, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
    color: #D1433A;
    letter-spacing: 0.3px;
}

.notification-panel-right {
    width: 370px;
    height: 660px;
    background: #FFFFFF;
    border: 1px solid #E6EAF0;
    border-radius: 4px;
}

.filters-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.filters-header h3 {
    font-family: Barlow;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
}

.clear-history {
    width: 120px;
    height: 30px;
    border: 1px solid #E6EAF0;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    font-family: Helvetica Neue;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.51px;
    background-color: transparent;
    color: #8B949F;

}

.notification-types {
    padding: 13px 20px;
}

.notification-types h4 {
    margin-bottom: 23px; 
    font-family: Barlow;
    font-size: 17px;
    font-weight: 600;
    line-height: 20.4px;
}

.notification-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.notification-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    color: #47505B;
    margin-bottom: 0;
}

.notification-option input[type="checkbox"] {
    width: 16px;
    height: 16px;
    border: 1px solid #E6EAF0;
    border-radius: 2px;
    cursor: pointer;
}

.search-section {
    margin-top: 20px;
    display: flex;
    border: 1px solid #E6EAF0;
    border-radius: 4px;
    align-items: center;
    padding: 0 15px;
}

.search-input {
    width: 100%;
    border: none;
    height: 30px;
    font-size: 13px;
    outline: none;
    color: #47505B;
}

.search-input::placeholder {
    color: #8895A7;
}
/* notifications list */
.notifications-list-container {
    padding-left: 0!important;
    margin-bottom: unset;
}

.notifications-list {
    margin-top: 10px;
    list-style: none;
}

.notification-item {
    width: 730px;
    height: 70px;
    background: #FFFFFF;
    border: 1px solid #E6EAF0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 10px;
    transition: background-color 0.2s ease;
}

.notification-item:hover {
    background-color: #F8F9FA;
}

.user-avatar {
    width: 51.69px;
    height: 50px;
    gap: 0px;
    opacity: 0px;
}

.user-avatar img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.notification-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.notification-text {
    font-size: 14px;
    color: #47505B;
    margin-bottom: 4px;
}

.user-name {
    font-weight: 600;
    color: #071526;
}

.notification-action {
    margin: 0 4px;
}

.notification-details {
    color: #8895A7;
}

.notification-time {
    font-size: 12px;
    color: #8895A7;
}

.notification-options {
    margin-left: 1px;
}

.options-btn {
    background: none;
    border: none;
    color: #8895A7;
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
}

.options-btn:hover {
    background-color: #F1F3F5;
}
.filters-divider{
    border: 1px solid #E6EAF0;
    opacity: 0.5;
}

/* checkbox */

.checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid rgba(209, 67, 58, 0.5);
    background-color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, background-image 0.3s ease;
    margin-right: 13px;
    border-radius: 4px;
}
.checkbox:not(:checked) {
    background-color: #FFF8F8;
    background-image: none;
}

.checkbox:checked {
    background-color: #D1433A;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='2' fill='%23D1433A'/%3E%3Cpath d='M4.32538 11.7566C3.37089 10.7894 4.72764 9.97541 5.68213 10.9426L7.68213 12.9426L11.6821 9.44212L14.6821 6.44262C15.7321 5.4754 16.8292 6.2435 15.8747 7.30744L8.62059 14.5616C8.2388 14.9485 7.57065 15.0452 7.18886 14.5616L4.32538 11.7566Z' fill='white'/%3E%3C/svg%3E%0A");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.fixNotificationsList .content-block.notify-tile-content-block.d-flex.flex-column .title-n > span{
    font-family: HelveticaNeueBold, sans-serif !important;
}

.fixNotificationsList .content-block.notify-tile-content-block.d-flex.flex-column .title-n > p > span {
    font-family: HelveticaNeueNormal, sans-serif !important;
}
</style>