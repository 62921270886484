/**
 * Common Constants
 */
export const SUCCESS = "success";
export const UPDATE = "update";
export const LOADING = "loading";
export const SKELETON = "skeleton";
export const SPINNER = "spinner";
export const FOR = "spinner_for";
export const DISPLAY = "display";
export const ERROR = "error";
export const NOT_FOUND = "not_found";
export const MESSAGE = "message";
export const STATUS = "status";
export const PENDING = "pending";
export const EMPTY = "empty";
export const RESPONSE = "response";
export const LOG_OUT = "log_out";
export const TOKEN_NOT_FOUND = "token_not_found";
export const ACTIVE = 1;
export const DEACTIVE = 0;
export const DISCOUNT = "Discount not greater than 100";
export const OFFSET = "offset";
export const LIMIT = "limit";
export const LIST = "list";
export const LAST_FETCH = "last_fetch";
export const OFFERS_ALL_PRODUCTS = "all_products";
export const OFFERS_ALL_CATEGORY_PRODUCTS = "category_products";
export const OFFERS_ALL_SUB_CATEGORY_PRODUCTS = "sub_category_products";
export const OFFERS_PRODUCT_PRODUCTS = "custom_products";
export const EVENT_MODAL = "event_modal";

export const DOCUMENTS = "documents";
export const VIDEOS = "videos";
export const TOAST = "toast";
export const TOAST_SHOW = "toast_show";
export const TOAST_TITLE = "toast_title";
export const TOAST_TEXT = "toast_text";
export const TOAST_DELAY = "toast_delay";
export const TOAST_TYPE = "toast_type";
export const LOCATION_DATA = "location_data";

//Server response
export const SERVER_USER_DATA = "user_data";
export const SERVER_SUCCESS = "success";
export const SERVER_ERROR = "error";
export const SERVER_NOT_VALID = "not_valid";
export const SERVER_NOT_CHANGED = "not_changed";
export const SERVER_PRESENT = "present";
export const SERVER_EMAIL_PRESENT = "email_present";
export const SERVER_PHONE_PRESENT = "phone_present";
export const SERVER_NO_VALUE = "no_value";
export const SERVER_NOT_AUTHORIZED = "not_authorized";
export const SERVER_EMPTY_OBJECT = "empty_object";
export const SERVER_BAD_REQUEST = "bad_request";
export const SERVER_VALIDATION_ERROR = "validation_error";
export const SERVER_MODEL_VALIDATION_ERROR = "model_validation_error";
export const SERVER_VERIFICATION_ERROR = "verification_error";
export const SERVER_FORBIDDEN = "forbidden";
export const SERVER_LOGGED_IN = "logged_in";
export const SERVER_LOGGED_OUT = "logged_out";
export const SERVER_INTERNAL_SERVER_ERROR = "internal_server_error";
export const SERVER_DOC_TYPE_DRIVING_LICENSE = "driving_license";
export const SERVER_DOC_TYPE_ADDRESS_PROOF = "address_proof";
export const SERVER_DOC_TYPE_OTHER = "other";

/** ---------------------------------------------- */

/**
 * Header data
 * */
export const HEADER = [];
export const HEADER_ROOT = "header";
export const HEADER_UPDATE = "header_update";
export const HEADER_KEY = "header_key";

//UI constants
export const HEADER_PROPS = "header_props";
export const HEADER_IS_SHOW = "header_is_show";
/** ------------------------------ */

/** Local database */
export const SM_LOGIN_DATA = "sm_login_data";
export const SM_LOGIN_DATA_EXPIRES_IN = 7 * 24 * 60 * 60;
export const SM_LOGIN_REMEMBER_ME = "sm_login_remember_me";
/** ------------------------------ */

/**
 * Sidebar data
 * */
export const SIDEBAR_ROOT = "sidebar";
export const SIDEBAR_UPDATE = "sidebar_update";
export const SIDEBAR_KEY = "sidebar_key";

//UI constants
export const SIDEBAR_PROPS = "sidebar_props";
export const SIDEBAR_IS_SHOW = "sidebar_is_show";
export const SIDEBAR_PAGE_PATH = "sidebar_page_path";
/** ------------------------------ */

/**
 * Footer data
 * */
export const FOOTER_ROOT = "footer";
export const FOOTER_UPDATE = "footer_update";
export const FOOTER_KEY = "footer_key";
export const FOOTER_FORM = "footer_form";

//UI constants
export const FOOTER_PROPS = "footer_props";
export const FOOTER_IS_SHOW = "footer_is_show";
export const FOOTER_IS_SIGNUP_BTN = "footer_is_signup_btn";

/** ------------------------------ */

/**
 * Login constant key
 * */
export const LOGIN_ROOT = "login";
export const LOGIN_UPDATE = "login_update";
export const LOGIN_RESET = "login_reset";
export const LOGIN_KEY = "login_key";

//UI constants
export const LOGIN_ERRORS = "login_errors";
export const LOGIN_REQUEST_STATUS = "login_request_status";
export const LOGIN_REQEUST_LOADING = "login_request_loading";
export const LOGIN_REQEUST_SESSION_LOADING = "login_request_session_loading";
export const LOGIN_REQUEST_SESSION_STATUS = "login_request_session_status";
export const LOGIN_REQEUST_LOGOUT_LOADING = "login_request_logout_loading";

//Form constants
export const LOGIN_FORM = "login_form";
export const LOGIN_FORM_EMAIL = "login_form_email";
export const LOGIN_FORM_PASSWORD = "login_form_password";
export const LOGIN_FORM_REMEMBER_ME = "login_form_remember_me";
/** ------------------------------ */

/**
 * Forgot password constant key
 * */
export const FORGOT_PASSWORD_ROOT = "forgot_password";
export const FORGOT_PASSWORD_UPDATE = "forgot_password_update";
export const FORGOT_PASSWORD_RESET = "forgot_password_reset";
export const FORGOT_PASSWORD_KEY = "forgot_password_key";

//UI constants
export const FORGOT_PASSWORD_ERRORS = "forgot_password_errors";
export const FORGOT_PASSWORD_REQUEST_STATUS = "forgot_password_request_status";
export const FORGOT_PASSWORD_REQEUST_LOADING =
  "forgot_password_request_loading";

//Form constants
export const FORGOT_PASSWORD_FORM = "forgot_password_form";
export const FORGOT_PASSWORD_FORM_EMAIL = "forgot_password_form_email";
/** ------------------------------ */

/**
 * Reset password constant key
 * */
export const RESET_PASSWORD_ROOT = "reset_password";
export const RESET_PASSWORD_UPDATE = "reset_password_update";
export const RESET_PASSWORD_RESET = "reset_password_reset";
export const RESET_PASSWORD_KEY = "reset_password_key";

//UI constants
export const RESET_PASSWORD_ERRORS = "reset_password_errors";
export const RESET_PASSWORD_REQUEST_STATUS = "reset_password_request_status";
export const RESET_PASSWORD_REQEUST_LOADING = "reset_password_request_loading";

//Form constants
export const RESET_PASSWORD_FORM = "reset_password_form";
export const RESET_PASSWORD_FORM_UID = "reset_password_form_uid";
export const RESET_PASSWORD_FORM_TOKEN = "reset_password_form_token";
export const RESET_PASSWORD_FORM_PASSWORD = "reset_password_form_password";
export const RESET_PASSWORD_FORM_CONFIRM_PASSWORD =
  "reset_password_form_confirm_password";
/** ------------------------------ */

/**
 * User data
 * */
export const USER_ROOT = "user";
export const USER_UPDATE = "user_update";
export const USER_KEY = "user_key";

export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const REFRESHING_TOKEN = "refreshing_token";
export const ACCESS_TOKEN_EXPIRES_AT = "access_token_expires_at";
export const REFRESH_TOKEN_EXPIRES_AT = "refresh_token_expires_at";

export const GET_STREAM_TOKEN = "get_stream_token";
//UI constants
export const USER_DATA = "user_data";
/** ------------------------------ */

/**
 * System data
 * */
export const SYSTEM_DATA_ROOT = "system_data";
export const SYSTEM_DATA_UPDATE = "system_data_update";
export const SYSTEM_DATA_KEY = "system_data_key";

//UI constants
export const SYSTEM_DATA_IS_AUTHENTICATED = "system_data_is_authenticated";
export const SYSTEM_DATA_IS_WELCOME_PAGE = "system_data_is_welcome_page";
export const SYSTEM_DATA_PAGE_TITLE = "system_data_page_title";
export const SYSTEM_DATA_ACCREDITED_INVESTOR_DATA =
  "system_data_accredited_investor_data";
export const SYSTEM_DATA_MAIN_CONTAINER_FULL_ADD_CLASS =
  "system_data_main_container_full_add_class";

/** ------------------------------ */

/** --------Dashboard Constant------------- */

export const DASHBOARD_ROOT = "dashboard";
export const DASHBOARD_UPDATE = "dashboard_update";
export const DASHBOARD_RESET = "dashboard_reset";
export const DASHBOARD_KEY = "dashboard_key";
export const DASHBOARD_REQUEST_STATUS = "dashboard_request_sattus";

export const DASHBOARD_DATA = "dashboard_data";
export const DASHBOARD_LOADING = "dashboard_loading";
/** ---------------------------------------- */

/**
 * Signup constant key
 * */
export const SIGNUP_ROOT = "signup";
export const SIGNUP_UPDATE = "signup_update";
export const SIGNUP_RESET = "signup_reset";
export const SIGNUP_KEY = "signup_key";

//UI constants
export const SIGNUP_ERRORS = "signup_errors";
export const SIGNUP_REQUEST_STATUS = "signup_request_status";
export const SIGNUP_REQEUST_LOADING = "signup_request_loading";

//Form constants
export const SIGNUP_FORM = "signup_form";

export const SIGNUP_FORM_FIRST_NAME = "signup_form_first_name";
export const SIGNUP_FORM_LAST_NAME = "signup_form_last_name";
export const SIGNUP_FORM_GENDER = "signup_form_gender";
export const SIGNUP_FORM_EMAIL = "signup_form_email";
export const SIGNUP_FORM_DOB_DAY = "signup_form_dob_day";
export const SIGNUP_FORM_DOB_MONTH = "signup_form_dob_month";
export const SIGNUP_FORM_DOB_YEAR = "signup_form_dob_year";
export const SIGNUP_FORM_USERNAME = "signup_form_username";
export const SIGNUP_FORM_PASSWORD = "signup_form_password";
export const SIGNUP_FORM_CONFIRM_PASSWORD = "signup_form_confirm_password";
export const SIGNUP_FORM_LOCATION= "signup_form_location"

export const SIGNUP_FORM_I_AGREE_TERMS_AND_PRIVACY_POLICY =
  "signup_form_i_aggree_terms_and_privacy_policy";
export const SIGNUP_FORM_SUBSCRIBE_TO_AUDIOGROUND_NEWSLETTER =
  "signup_form_subscribe_to_audioground_newsletter";

/** ------------------------------ */

/**
 * Edit profile constant key
 * */
export const EDIT_PROFILE_ROOT = "edit_profile";
export const EDIT_PROFILE_UPDATE = "edit_profile_update";
export const EDIT_PROFILE_RESET = "edit_profile_reset";
export const EDIT_PROFILE_KEY = "edit_profile_key";

//UI constants
export const EDIT_PROFILE_ERRORS = "edit_profile_errors";
export const EDIT_PROFILE_REQUEST_STATUS = "edit_profile_request_status";
export const EDIT_PROFILE_REQEUST_LOADING = "edit_profile_request_loading";

//Form constants
export const EDIT_PROFILE_FORM = "edit_profile_form";
export const EDIT_PROFILE_FORM_EMAIL = "edit_profile_form_email";
export const EDIT_PROFILE_FORM_FIRST_NAME = "edit_profile_form_first_name";
export const EDIT_PROFILE_FORM_LAST_NAME = "edit_profile_form_last_name";
export const EDIT_PROFILE_FORM_GENDER = "edit_profile_form_gender";
export const EDIT_PROFILE_FORM_COUNTRY = "edit_profile_form_country";
export const EDIT_PROFILE_FORM_WEBSITE = "edit_profile_form_website";
export const EDIT_PROFILE_FORM_DOB_DATE = "edit_profile_form_dob_date";
export const EDIT_PROFILE_FORM_DOB_MONTH = "edit_profile_form_dob_month";
export const EDIT_PROFILE_FORM_DOB_YEAR = "edit_profile_form_dob_year";
export const EDIT_PROFILE_FORM_ABOUT_ME = "edit_profile_form_about_me";
export const EDIT_PROFILE_FORM_RELATIONSHIP_STATUS =
  "edit_profile_form_relationship_status";
/** ------------------------------ */

/**
 * Post
 * */
export const POST_ROOT = "post";
export const POST_UPDATE = "post_update";
export const POST_RESET = "post_reset";
export const POST_KEY = "post_key";

//UI constants
export const CREATE_POST_ERRORS = "create_post_errors";
export const CREATE_POST_REQUEST_STATUS = "create_post_request_status";
export const CREATE_POST_LOADING = "create_post_request_loading";
export const CREATE_POST_SELECTED_OPTION = "create_post_selected_option";

export const POST_LIST_REQUEST_STATUS = "post_list_request_status";
export const POST_LIST_LOADING = "post_list_request_loading";
export const POST_LIST_DATA = "post_list_data";
export const CREATE_POST_ERROR_MESSAGE = "create_post_error_message";

//Form constants
export const CREATE_POST_FORM = "create_post_form";
export const CREATE_POST_FORM_VALUE = "create_post_form_post";
export const CREATE_POST_FORM_POST_COLOR_TEXT =
  "create_post_form_color_post_text";
export const CREATE_POST_FORM_POST_COLOR = "create_post_form_color_post_color";
export const CREATE_POST_FORM_FEELING_TEXT = "create_post_form_feeling_text";
export const CREATE_POST_FORM_FEELING = "create_post_form_feeling";

export const CREATE_POST_FORM_ARTICLE_TITLE = "create_post_form_article_title";
export const CREATE_POST_FORM_ARTICLE_CONTENT =
  "create_post_form_article_content";
export const CREATE_POST_FORM_ARTICLE_COVER = "create_post_form_article_cover";
export const CREATE_POST_FORM_ARTICLE_CATEGORY =
  "create_post_form_article_category";
export const CREATE_POST_FORM_ARTICLE_TAGS = "create_post_form_article_tags";
export const CREATE_POST_FORM_IMAGES = "create_post_form_images";
export const CREATE_POST_FORM_SELECTED_IMAGES =
  "create_post_form_selected_images";
export const CREATE_POST_FORM_IS_ALBUM = "create_post_form_is_album";
export const CREATE_POST_FORM_ALBUM_NAME = "create_post_form_album_name";

export const CREATE_POST_FORM_CREATE_VIDEO = "create_post_form_create_video";
export const CREATE_POST_FORM_CREATE_VIDEO_NAME =
  "create_post_form_create_video_name";

export const CREATE_POST_FORM_SHARED_WITH = "create_post_form_shared_with";

export const CREATE_POST_FORM_POLLS = "create_post_form_polls";

export const PIN_POST = "pin_post";

/**
 * config
 * */
export const RIGHT_BAR = "right_bar";
export const AFFILIATION_TOKEN = "affiliation_token";

// system
export const COUNTRIES = "countries";
export const COUNTRIES_LOADING = false;
export const ROLES = "roles";
export const SHOW_CROPPER = "show_cropper";
export const LIST_OPTION = "list_option";
export const CHAT_OPTION = "chat_option";
export const CHAT_TYPE = "chat_type";
export const TYPING = "typing";
export const IS_TYPING = "is_typing";
export const TYPING_USER = "typing_user";
export const CHAT_ATTACHMENT = "chat_attachment";
export const VIEW_ALL_CHAT_LISTING = "view_all_chat_listing";
export const VIEW_ALL_CHAT_LISTING_TAB = "view_all_chat_listing_tab";
export const HEIGHT = "height";
export const WIDTH = "width";
export const LEFT = "LEFT";
export const OPEN = "open";
export const LIST_TYPE = "list_type";
export const RIGHT_BAR_CHECK = "right_bar_check";
export const ROUTE_STACK = "Route_stack";
export const GENRES = "genres";
export const CURRENT_SONG_ALBUM_FAVORITE = "current_song_album_favorite";
export const CURRENT_SONG_ALBUM_FAVORITE_COUNTS =
  "current_song_album_favorite_counts";

// system

// post new section
export const POST_ACTIVE_TAB = "post_active_tab";
export const POST = "post";
export const POST_COLOR = "post_color";
export const POST_COLOR_TEXT = "post_color_text";
export const ACCESS = "access";
export const SHORT_CONTENT = "short_content";
export const CONTENT = "full_content";
export const TAGS_LIST = "tag_list";
export const MENTION_LIST = "mentioned_list";
export const MENTION_PAGES = "mentioned_pages";
export const LINKS = "link";
export const CATEGORY = "category";
export const TYPE = "post_type";
export const TYPE_DESCRIPTION = "post_type_description";
export const POST_TYPE_ID = "post_type_id";
export const PICTURES = "artwork";
export const PICTURES_ID = "artwork_id";
export const POST_VIDEOS = "video";
export const ANNOUNCEMENT = "announcement";
export const ALBUM = "album_title";
export const POLL_OPTION = "poll_options";
export const POLL_EXPIRY = "poll_options";
export const POST_LIST = "post_list";
export const OTHER_USER_ID = "other_user_id";
export const SINGLE_POST = "single_post";
export const POST_ERROR = "post_error";
export const POST_LIMIT = "limit";
export const POST_OFFSET = "offset";
export const MEDIA_OFFSET = "media_offset";
export const MEDIA_LIMIT = "media_limit";
export const MEDIA_ALBUM = "media_album";
export const MEDIA_LIST = "media_list";
export const MEDIA_TOTAL = "media_total";
export const ANNOUNCEMENT_LIMIT = "announcemet_limit";
export const ANNOUNCEMENT_OFFSET = "announcement_offset";
export const POST_TOTAL = "total";
export const ANNOUNCEMENT_TOTAL = "announcement_total";
export const UPCOMING_EVENT_LIMIT = "upcoming_event_limit";
export const UPCOMING_EVENT_OFFSET = "upcoming_event_offset";
export const UPCOMING_EVENT_TOTAL = "upcoming_event_total";
export const PAST_EVENT_LIMIT = "past_event_limit";
export const PAST_EVENT_OFFSET = "past_event_offset";
export const PAST_EVENT_TOTAL = "past_event_total";
export const POST_TYPE = "post_type";
export const POST_TAG = "post_tag";
export const CITY = "city";
export const COUNTRY = "country";
export const POST_ACTIVITIES = "post_activities";
export const ARTICLE_POST_CATEGORIES = "article_post_categories";
export const ALL_HASH_TAGS = "all_hash_tags";
export const TOTAL_TAGS_COUNT = "total_count_tags";
export const LOADING_TAGS = "loading_tags";
export const POST_TAGS_LIST = "post_tags_list";
export const POST_FOLLOWER_TAG = "post_follower_tag";
export const PAGE_ROLES = "page_roles";
export const PAGE_PARTNER_ROLES = "page_partner_roles";
export const SEARCH_PARTNERS = "search_partners";
export const PAGES_TYPE = "pages_type";
export const SEARCH_ARTISTS = "search_artists";
export const SELECTED_FILTERS = "selected_filters";
export const SELECTED_CATEGORY = "selected_category";
export const SELECTED_RATING = "selected_rating";
export const SELECTED_SUBCATEGORY = "selected_subcategory";
export const SELECTED_LOCATION = "selected_location";
export const SELECTED_GENRE = "selected_genre";
export const SELECTED_SORT = "selected_sort";
export const ARTICLE = "article";
export const EDIT = "edit";
export const TRENDING_VIEW_CHECK = "trending_view_check";
// post new section

// page mew section
export const PAGE_ACTIVE_TAB = "page_active_tab";
export const PAGE = "page";
export const PAGE_NAME = "page_name";
export const PAGE_ADDRESSES = "page_address";
export const PAGE_CATEGORY = "category_id";
export const PAGE_SUB_CATEGORY = "sub_category_id";
export const PAGE_ABOUT = "about";
export const PAGE_PROFILE = "artwork";
export const PAGE_COVER = "cover_image";
export const PAGE_TYPE = "type";
export const PAGE_SPECIFICATION = "specification_id";
export const DISCOVER_PAGES = "all_pages";
export const LIKED_PAGES = "liked_pages";
export const USER_PAGES = "user_pages";
export const TOTAL_PAGES = "total";
export const PAGE_OFFSET = "offset";
export const PAGE_LIMIT = "limit";
export const PAGES = "pages";
export const CURRENT_PAGE = "current_page";
export const PAGE_POSTS = "page_posts";
export const PAGE_ANNOUNCEMENTS = "page_announcements";
export const MEMBER_REQUESTS = "member_requests";
export const MEMBER_REQUESTS_OFFSET = "member_requests_offset";
export const MEMBER_REQUESTS_LIMIT = "member_requests_limit";
export const UPCOMING_PAGE_EVENTS = "upcoming_page_events";
export const PAGE_PAST_EVENTS = "page_past_events";
export const PAGE_CATEGORIES = "page_categories";
export const SELECTED_PAGE_CATEGORIES = "selected_page_categories";
export const ADMINS = "admins";
export const MODERATORS = "moderators";
export const EDITORS = "editors";
export const ADVERTISER = "advertiser";
export const PARTNER_ARTIST = "partner_artist";
export const PARTNER_BOOKING_AGENT = "partner_booking_agent";
export const PARTNER_DISTRIBUTOR = "partner_distributor";
export const PARTNER_MANAGEMENT = "partner_management";
export const PARTNER_PUBLISHING = "partner_publishing";
export const PARTNER_RECORD_LABEL = "partner_record_label";
export const PARTNER_SPONSOR = "partner_sponsor";
export const PARTNER_INVITES = "partner_invites";
export const PARTNER_LISTING = "partner_listing";
export const FAVORITE_PAGE_LISTING = "favorite_page_listing";
// Verification Form
export const VERIFICATION_FORM = "verification_form";
export const EMAIL = "email";
export const FULL_NAME = "full_name";
export const ARTIST_NAME = "artist_name";
export const PHONE_NO = "phone_no";
export const EXT = "ext";
export const AFFILIATION_TO_ARTIST = "affiliation_to_artist";
export const VERIFICATION_DESCRIPTION = "description";
export const AGREEMENT = "agreement";

// Friends Search for Admin Section
export const FRIENDS_SEARCH = "friends_search";

// page mew section

//profile  friends section start
export const MY_FRIENDS_LIST_NOT_HAVING_MEMBERS =
  "my_friends_list_not_having_members";
export const My_Friends_List = "my_friends_list";
export const My_Friends_Offset = "my_friends_offset";
export const My_Friends_Limit = "my_friends_limit";
export const My_Friends_Total = "my_friends_total";

export const My_Followers_List = "my_followers_list";
export const My_Followers_Offset = "my_followers_offset";
export const My_Followers_Limit = "my_followers_limit";
export const My_Followers_Total = "my_followers_total";

export const My_Followings_List = "my_following_list";
export const My_Followings_Offset = "my_following_offset";
export const My_Followings_Limit = "my_following_limit";
export const My_Followings_Total = "my_following_total";

export const Add_Favourite_User_Id = "add_favourite_user_id";
export const Add_Favourite_Object_Type = "add_favourite_object_type";
export const Add_Favourite_Action = "add_favourite_action";

export const Widgets_Friends_List = "widgets_friends_list";
//profile  friends section end

//Market
////Product
//////Song
export const TEMP_SONG_FORM = "temp_song_form";
export const EMPTY_TEMP_SONG_FORM = "empty_temp_song_form";
export const UPLOAD_SONG_PAGE_FORM = "upload_song_page_form";
export const EMPTY_UPLOAD_SONG_PAGE_FORM = "upload_song_page_form";

export const COMPLETE_STEP_UPLOAD = "complete_step_upload";
export const SONG_ID_TEMP = "temp_song_id";
export const PAGE_ID = "page_id";
export const TITLE = "title";
export const PODCAST_TYPE = "type";
export const PODCAST_CATEGORY_ID = "podcast_category_id";
export const URL = "url";
export const DESCRIPTION = "description";
export const GENRE = "genre";
export const MOOD = "mood";
export const SUB_GENRE = "sub_genre";
export const TAGS = "tags";
export const METADATA_LANGUAGE = "metadata_language";
export const PRIMARY_ARTIST = "primary_artist";
export const FEATURE_ARTIST = "feature_artist";
export const COMPOSER = "composer";
export const PRODUCER = "producer";
export const RE_MIXER = "remixer";
export const RECORD_LABEL = "record_label";
export const PUBLISHER = "publisher";
export const RELEASED_AT = "released_at";
export const ISRC = "isrc";
export const COPYRIGHT = "copyright";
export const EXPLICIT_CONTENT = "explicit_content";
export const CREATE_ISRC = "create_isrc";
export const ALL_RIGHTS_RESERVED = "all_rights_reserved";
export const CREATION_COMMON = "creative_common";
export const PRICING_TYPE = "pricing_type";
export const SALE_DATE = "sale_date";
export const PREORDER_INFO = "preorder_info";
export const LICENSE_ID = "license_id";
export const CLEAR_FOR_SALE_COUNTRIES = "clear_for_sale_countries";
export const CLEAR_FOR_SALE_TERRITORY = "clear_for_sale_territory";
export const CLEAR_FOR_NOT_SALE_COUNTRIES = "clear_not_for_sale_countries";
export const CLEAR_FOR_NOT_SALE_TERRITORY = "clear_not_for_sale_territory";
export const ENABLE_DIRECT_DOWNLOAD = "enable_direct_download";
export const ENABLE_OFFLINE_LISTING = "enable_offline_listening";
export const INCLUDE_RES = "include_rss_feed";
export const DISPLAY_EMBED_CODE = "display_embed_code";
export const ENABLE_APP_PLAYBACK = "enable_app_playback";
export const ENABLE_COMMENT = "enable_comment";
export const DISPLAY_COMMENT = "display_comment";
export const DISPLAY_STATS = "display_stats";
export const _TYPE = "type";
export const PRIVACY = "privacy";
export const FILE = "file";
export const DRAFT = "draft";

//////Song

//////Album
export const SONG = "song";
export const EMPTY_SONG = "empty_song";
export const SONGS = "songs";
export const SONGS_IDS_TEMP = "temp_songs_ids";
export const SONGS_NAMES_TEMP = "names";
export const TEMP_SONGS_FORM = "temp_songs_form";
export const EMPTY_TEMP_SONGS_FORM = "empty_temp_songs_form";
export const UPLOAD_ALBUM_PAGE_FORM = "upload_album_page_form";
export const EMPTY_UPLOAD_ALBUM_PAGE_FORM = "upload_album_page_form";
export const SONG_DETAILS = "song_details";
export const SONG_DOWNLOAD_PRICE = "download_track_pricing";
export const ALBUM_DOWNLOAD_PRICE = "download_album_pricing";
export const PODCAST_DOWNLOAD_PRICE = "download_podcast_pricing";
export const LET_FUN_PAY_TRACK = "let_fan_pay_track";
export const LET_FUN_PAY_ALBUM = "let_fan_pay_album";
//////Album

//Market
////Product
//////Sample

export const SAMPLE_ID_TEMP = "temp_sample_id";
export const TEMP_SAMPLE_FORM = "temp_sample_form";
export const EMPTY_TEMP_SAMPLE_FORM = "empty_temp_sample_form";
export const UPLOAD_SAMPLE_PAGE_FORM = "upload_sample_page_form";
export const EMPTY_UPLOAD_SAMPLE_PAGE_FORM = "upload_sample_page_form";

export const FILE_TYPE = "file_type";
export const SAMPLE_TYPE = "sample_type";
export const BEAT_TYPE = "beat_type";
export const BPM = "bpm";
export const CHARACTER = "character";
export const INSTRUMENT = "instrument";
export const KEY = "key";
export const SAMPLE_DETAILS = "sample_details";

//////Album

//Artist Page Follower Section start
export const Artist_Followers_List = "artist_followers_list";
export const Artist_Followers_Offset = "artist_followers_offset";
export const Artist_Followers_Limit = "artist_followers_limit";
export const Artist_Followers_Total = "artist_followers_total";
export const Artist_Followers_User_Type = "artist_followers_user_type";

export const Artist_Followings_List = "artist_followings_list";
export const Artist_Followings_Offset = "artist_followings_offset";
export const Artist_Followings_Limit = "artist_followings_limit";
export const Artist_Followings_Total = "artist_followings_total";
export const Artist_Followings_User_Type = "artist_followings_user_type";

export const Artist_Mutual_Followings_List = "artist_mutual_followings_list";
export const Artist_Mutual_Followings_Offset =
  "artist_mutual_followings_offset";
export const Artist_Mutual_Followings_Limits =
  "artist_mutual_followings_limits";
export const Artist_Mutual_Followings_Total = "artist_mutual_followings_total";
export const Artist_Mutual_Followings_User_Type =
  "artist_mutual_following_user_type";

export const Similar_Artists_List = "similar_artists_list";
export const Similar_Artists_Offset = "similar_artists_offset";
export const Similar_Artists_Limit = "similar_artists_limit";
export const Similar_Artists_Total = "similar_artists_total";
//Artist Page Follower Section end
//Page Events List
export const PAGE_EVENTS = "page_events";
//Page Music Section start
export const Page_Popular_Tracks_List = "page_popular_tracks_list";
export const Page_Latest_Song_List = "page_latest_song_list";
export const Song_Play_Index = "song_play_index";
export const Album_Play_Index = "album_play_index";
export const Play_Type = "play_type";

export const Page_Music_Filtered_List = "page_music_filtered_list";
export const Page_Music_Filtered_List_Total = "page_music_filtered_list_total";
export const Page_Music_Filtered_List_Offset = "page_music_friends_list_offset";
export const Page_Music_Filtered_List_Limit = "page_music_friends_list_limit";
export const Page_Music_Filtered_List_Payload =
  "page_music_filtered_list_payload";
export const COLLABORATOR_ID = "collaborator_id";
export const COLLABORATOR_ROLE_ID = "collaborator_role_id";
export const PROFIT = "profit";
export const COLLABORATORS = "collaborators";
//Page Music section end

//Music Footer Start
export const Music_Songs_List = "music_songs_list";
export const Album_Play_Status = "album_play_status";
export const Last_Play_Array_Length = "last_play_array_length";
export const Add_To_Queue = "add_to_queue";
export const Add_To_Queue_Songs_Array = "add_to_queue_songs_array";
export const Update_Song_Status = "update_song_status";
export const Play_All_Songs_Array = "play_all_songs_array";
//Music Footer End
// Playlist Start:

export const PLAY_LIST = "playlist";
export const TERM = "term";
export const Play_List_Modal_Open = "playlist_list_modal_open";
export const Playlist_Modal_Open_Check = "playlist_modal_Open_check";
export const My_Playlist_List = "my_playlist_list";
export const Playlist_Loader = "my_playlist_loader";
export const Tracks_And_Albums_List = "tracks_and_albums_list";
export const Tracks_And_Albums_List_Total = "tracks_and_albums_list_total";
export const Tracks_And_Albums_Loader = "tracks_and_albums_loader";
export const Add_To_Playlist_Songs_Array = "add_to_playlist_songs_array";
// Playlist End:

export const MUSIC_MODE = "music_mode";
export const STREAMING_MODE = "streaming";

//Song
export const CURRENT_SONG = "current_song";
export const SONG_COMMENTS = "song_comments";
export const SONG_COMMENT_OFFSET = "song_comment_offset";
export const SONG_COMMENT_LIMIT = "song_comment_limit";
export const SONG_COMMENT_TOTAL = "song_comment_total";
export const SONG_PLAY_COUNT = "song_play_count";
//

//List Option
export const LIST_OPTION_FAVORITE = "list_option_favorite";
export const LIST_OPTION_FAVORITE_ID = "list_option_favorite_id";

//Music Favorte
export const FAVORITE_SONGS_LIST = "favorite_songs_list";
export const FAVORITE_SONGS_TOTAL = "favorite_songs_total";
export const FAVORITE_SONGS_LOADER = "favorite_songs_loader";
export const FAVORITE_ALBUMS_LIST = "favorite_albums_list";
export const FAVORITE_ALBUMS_TOTAL = "favorite_albums_total";
export const FAVORITE_ALBUMS_LOADER = "favorite_album_loader";
export const FAVORITE_ARTISTS_LIST = "favorite_artists_list";
export const FAVORITE_ARTISTS_TOTAL = "favorite_artists_total";
export const FAVORITE_ARTISTS_LOADER = "favourite_artists_loader";
export const FAVORITE_LIMIT = "favorite_limit";
export const FAVORITE_OFFSET = "favorite_offset";
//navigation bars

// GET STREAM
export const GET_STREAM_ACCESS_TOKEN = "get_stream_access_token";
export const SUBSCRIPTION = "subscription";
export const NOTIFICATIONS = "notifications";
export const NOTIFICATION_CLIENT = "notification_client";
export const USER = "user";
export const FRIENDS_REQUEST_NOTIFICATIONS = "friend_request_notifications";
export const OTHER_NOTIFICATIONS = "other_notifications";
export const NEW_NOTIFICATIONS = "new_notifications";
export const OLD_NOTIFICATIONS = "old_notifications";
export const ACTIVITY_NOTIFICATIONS = "activity_notifications";
export const LISTENED_SONGS = "listened_songs";
export const TOTAL_READ = "total_read";
export const TOTAL_SEEN = "total_seen";
export const CHAT_CLIENT = "chat_client";
export const GET_STREAM_MESSAGING_ACCESS_TOKEN =
  "get_stream_messaging_access_token";
export const ONLINE_USERS = "online_users";
export const LAST_MESSAGES = "last_messages";
export const LAST_MESSAGES_GROUPS = "last_messages_groups";
export const LAST_MESSAGES_CLUBROOM = "last_messages_clubroom";
export const LAST_MESSAGES_PARTYROOM = "last_messages_partyroom";
export const SET_PAGE_ROOMS = "set_page_rooms";
export const SET_PAGE_TEXT_ROOMS = "set_page_text_rooms";
export const SET_PAGE_AUDIO_VIDEO_ROOMS = "set_page_audio_video_rooms";
export const GET_STREAM_FRIENDS = "get_stream_friends";
export const UNREAD_MESSAGES_COUNT = "unread_messages_count";
export const ALL_ONLINE_USERS = "ALL_ONLINE_USERS";
export const SEARCHED_MESSAGE_LIST = "searched_message_list";
export const ADD_MEMBER_CHECK = "add_member_check";
export const PAGES_TO_MESSAGES = "pages_to_messages";
export const PAGES_TO_MESSAGES_OBJECT = "pages_to_messages_object";
export const PAGES_TO_MESSAGES_CHECK = "pages_to_messages_check";
export const PAGES_TO_MESSAGES_TYPE = "pages_to_messages_type";
//GET STREAM

//Save Previous Route for Article
export const PREVIOUS_ROUTE = "previous_route";

//Save Active Chat
export const ACTIVE_CHAT_USER = "active_chat_user";
export const ACTIVE_CHANNEL = "active_channel";
export const MUTABLE_ACTIVE_CHANNEL = "mutable_active_channel";
export const ACTIVE_GROUP = "active_group";
export const ACTIVE_CHAT = "active_chat";
export const ACTIVE_FULL_CHAT = "active_full_chat";
export const FULL_CHAT_ACTIVE_CHANNEL = "full_chat_active_channel";
export const ACTIVE_FULL_CHAT_USER = "active_full_chat_user";
export const ACTIVE_FULL_CHAT_TYPE = "active_full_chat_type";
export const ACTIVE_FULL_CHAT_PREVIOUS_TYPE = "active_full_chat_previous_type";
export const ACTIVE_ALL_IMAGES = "active_all_images";
export const ACTIVE_ALL_FILES = "active_all_files";
export const ACTIVE_ALL_AUDIOS = "active_all_audios";
export const ACTIVE_ALL_LINKS = "active_all_links";
export const ACTIVE_ALL_VOICES = "active_all_voices";
export const ACTIVE_ALL_GIFS = "active_all_gifs";
export const ACTIVE_ALL_GROUP_MEMBERS = "active_all_group_members";
export const ACTIVE_CHAT_ID = "active_chat_id";
export const ACTIVE_FULL_CHAT_USER_BACKEND = "active_full_chat_user_backend";
export const REDIRECT_TO_SETTING_PAGE = "redirect_to_settings_page";
export const REDIRECT_TO_SETTING_PAGE_CHECK = "redirect_to_setting_page_check";
export const REDIRECT_TO_SETTING_PAGE_TAB = "redirect_to_settings_page_tab";
export const LEFT_BAR_LISTING_ACTIVE_TAB = "left_bar_listing_active_tab";
export const CREATE_EVENT_MODAL_CHECK = "create_event_modal_check";
//MusicPlayer
export const CURRENT_PLAYER = "current_player";
export const CURRENT_PLAY_TYPE_ID = "current_play_type_ID";
export const CURRENT_PLAY_ID = "current_play_id";
export const ACTIVITY_ID = "activity_id";

//LaunchRoom
export const LAUNCH_ROOM = "launch_room";
export const LAUNCH_ROOM_INFO = "launch_room_info";
export const ROOM_INFO_NAME = "room_info_name";
export const ROOM_INFO_TYPE = "room_info_type";
export const ROOM_PAGE_TYPE = "room_page_type";
export const ROOM_PAGE_ID = "room_page_id";
export const ROOM_INFO_DESCRIPTION = "room_info_description";
export const ROOM_INFO_COVER = "room_info_cover";
export const ROOM_INFO_PRIVACY = "room_info_privacy";
export const LAUNCH_ROOM_USERS = "launch_room_users";
export const ROOM_USERS_IDS = "room_users_ids";
export const LAUNCH_ROOM_SCHEDULE = "launch_room_schedule";
export const IS_ROOM_SCHEDULE = "is_room_schedule";
export const ROOM_SCHEDULE_START_DATE = "room_schedule_start_date";
export const ROOM_SCHEDULE_START_TIME = "room_schedule_start_time";
export const ROOM_SCHEDULE_END_DATE = "room_schedule_end_date";
export const ROOM_SCHEDULE_END_TIME = "room_schedule_end_time";
export const ACTIVE_LAUNCH_ROOM_FROM_ROOM = "active_launch_room_from_room";
// LaunchRoom End

//TWILIO
export const TWILIO_ACCESS_TOKEN = "twilio_access_token";
export const TWILIO_ROOM = "twilio_room";
export const TWILIO_GRANT_ACCESS_ROOM_TOKEN = "twilio_grant_access_room_token";
export const TWILIO_PLAYER_STREAM_ID = "twilio_player_stream_id";
export const TWILIO_MEDIA_PROCESSOR_ID = "twilio_media_processor_id";
export const TWILIO_PLAYBACK_GRANT_ID = "twilio_playback_grant_id";
export const TWILIO_GRANT_ACCESS_TOKEN = "twilio_grant_access_token";
// END TWILIO

export const HOME_NAV = {
  name: "HOME",
  type_icon: "home.svg",
  type_color: "#D1433A",
  header_icon: "icon_navigation.svg",
  header_icon_collapsed: "collapse_icon_navigation.svg",
  list: [
    {
      type: "list-item",
      name: "Profile",
      icon: "Profile",
      link: "",
      url: "/home/page/user-profile/",
      show: true,
      user_profile: true,
    },
    {
      type: "list-item",
      name: "Messages",
      icon: "Messages",
      link: "",
      url: "/chat-manager/chat",
      show: true,
    },
    {
      type: "list-item",
      name: "Setting",
      icon: "Settings",
      link: "",
      url: "/home/settings",
      show: true,
    },
    {
      type: "list-item",
      name: "Friends",
      icon: "Friends",
      link: "",
      url: "/home/page/people",
      show: true,
    },
    {
      type: "list-heading",
      name: "Favourites",
      icon: "xyz",
      link: "",
      url: "/not-found",
      show: false,
    },
    {
      type: "list-item",
      name: "Favorites",
      icon: "Favorites",
      link: "",
      url: "/home/page/favorites",
      show: true,
    },
    {
      type: "list-item",
      name: "News Feeds",
      icon: "News-Feed",
      link: "",
      url: "/home/page/news-feed",
      show: true,
    },
    {
      type: "list-item",
      name: "My Articles",
      icon: "My-Articles",
      link: "",
      url: "/home/page/article",
      show: true,
    },

    {
      type: "list-heading",
      name: "Explore",
      icon: "xyz",
      link: "",
      url: "/not-found",
      show: false,
    },

    {
      type: "list-item",
      name: "Pages",
      icon: "Pages",
      link: "",
      url: "/home/page/pages",
      function: "setPageTypeFunction",
      parameter: "artist",
      show: true,
    },
    {
      type: "list-item",
      name: "Clubrooms",
      icon: "Clubs",
      link: "",
      function: "setPageTypeFunction",
      parameter: "clubroom",
      url: "/home/page/clubrooms",
      show: true,
    },
    {
      type: "list-item",
      name: "Partyrooms",
      icon: "Parties",
      link: "",
      function: "setPageTypeFunction",
      parameter: "partyroom",
      url: "/home/page/partyrooms",
      show: true,
    },
    {
      type: "list-item",
      name: "Events",
      icon: "Events",
      link: "",
      url: "/home/page/events",
      show: true,
    },
    {
      type: "list-item",
      name: "Television",
      icon: "Television",
      link: "",
      url: "/not-found",
      show: true,
    },
    {
      type: "list-item",
      name: "Funding",
      icon: "Funding",
      link: "",
      url: "/not-found",
      show: true,
    },
    {
      type: "list-item",
      name: "Market",
      icon: "Market",
      link: "",
      url: "/not-found",
      show: true,
    },
    {
      type: "list-item",
      name: "Blogs",
      icon: "Blogs",
      link: "",
      url: "/not-found",
      show: true,
    },
    {
      type: "list-item",
      name: "Forums",
      icon: "Forums",
      link: "",
      url: "/home/page/forum",
      show: true,
    },
    {
      type: "list-item",
      name: "Jobs",
      icon: "Jobs",
      link: "",
      url: "/not-found",
      show: true,
    },
  ],
};
export const MUSIC_NAV = {
  name: "MUSIC",
  type_color: "#6E64FF",
  type_icon: "music.svg",
  header_icon: "icon_navigation.svg",
  header_icon_collapsed: "collapse_icon_navigation.svg",
  list: [
    {
      type: "list-item",
      name: "EXPLORE",
      icon: "Explore",
      link: "",
      url: "",
      show: false,
      child_list: true,
      child: [
        {
          type: "list-heading",
          name: "Overview",
          link: "",
          url: "/music/page/overview",
          show: false,
        },
        {
          type: "list-heading",
          name: "Genre & Moods",
          link: "",
          url: "/music/page/genres-moods",
          show: false,
        },
        {
          type: "list-heading",
          name: "New Release",
          link: "",
          url: "/music/page/new-releases",
          show: false,
        },
        {
          type: "list-heading",
          name: "Charts",
          link: "",
          url: "/music/page/charts",
          show: false,
        },
        {
          type: "list-heading",
          name: "Podcasts",
          link: "",
          url: "/music/page/podcasts",
          show: false,
        },
        {
          type: "list-heading",
          name: "Reviews",
          link: "",
          url: "/music/page/Reviews",
          show: false,
        },
      ],
    },
    {
      type: "list-item",
      name: "FEED",
      icon: "Feed",
      link: "",
      url: "",
      show: true,
      child_list: false,
    },
    {
      type: "list-item",
      name: "MY MUSIC",
      icon: "My-Music",
      link: "",
      url: "",
      show: false,
      child_list: true,
      child: [
        {
          type: "list-heading",
          name: "Favorite",
          link: "",
          url: "/music/page/Favorites",
          show: false,
        },
        {
          type: "list-heading",
          name: "Genre & Moods",
          link: "",
          url: "#",
          show: false,
        },
      ],
    },
    {
      type: "horizontal-line",
      show: true,
    },
    {
      type: "list-item",
      name: "Add Playlist",
      icon: "New-Product",
      link: "",
      url: "",
      show: true,
      child_list: false,
    },
    // {
    //   type: "list-item",
    //   name: "Playlist",
    //   icon: "Playlist",
    //   link: "",
    //   url: "",
    //   show: true,
    //   child_list: false,
    // },
    // {
    //   type: "list-item",
    //   name: "Playlist",
    //   icon: "Playlist",
    //   link: "",
    //   url: "",
    //   show: true,
    //   child_list: false,
    // },
    // {
    //   type: "list-item",
    //   name: "Playlist",
    //   icon: "Playlist",
    //   link: "",
    //   url: "",
    //   show: true,
    //   child_list: false,
    // },
  ],
};
export const EVENT_NAV = {
  name: "EVENTS",
  type_icon: "events.svg",
  type_color: "#AE4EA9",
  header_icon: "icon_navigation.svg",
  header_icon_collapsed: "collapse_icon_navigation.svg",
  list: [
    {
      type: "list-item",
      name: "EXPLORE",
      icon: "Explore",
      link: "",
      url: "",
      show: false,
      child_list: true,
      child: [
        {
          type: "list-heading",
          name: "Overview",
          link: "",
          url: "",
          show: false,
        },
        {
          type: "list-heading",
          name: "Find Events",
          link: "",
          url: "",
          show: false,
        },
        {
          type: "list-heading",
          name: "Livestreaming",
          link: "",
          url: "",
          show: false,
        },
        {
          type: "list-heading",
          name: "Gigs",
          link: "",
          url: "",
          show: false,
        },
        {
          type: "list-heading",
          name: "Clubs",
          link: "",
          url: "",
          show: false,
        },
        {
          type: "list-heading",
          name: "Festivals",
          link: "",
          url: "",
          show: false,
        },
      ],
    },
    {
      type: "list-item",
      name: "FEED",
      icon: "Feed",
      link: "",
      url: "",
      show: true,
      child_list: false,
    },
    {
      type: "list-item",
      name: "MY EVENTS",
      icon: "My-Music",
      link: "",
      url: "",
      show: false,
      child_list: true,
      child: [
        {
          type: "list-heading",
          name: "Favourites",
          link: "",
          url: "",
          show: false,
        },
        {
          type: "list-heading",
          name: "Genre & Moods",
          link: "",
          url: "",
          show: false,
        },
      ],
    },
    {
      type: "horizontal-line",
      show: true,
    },
    {
      type: "list-item-shadow",
      name: "Add Event",
      icon: "New-Product",
      link: "",
      url: "",
      show: true,
      child_list: false,
    },
  ],
};
export const JOB_NAV = {
  name: "JOBS",
  type_icon: "jobs.svg",
  type_color: "#0CAACD",
  header_icon: "icon_navigation.svg",
  header_icon_collapsed: "collapse_icon_navigation.svg",
  list: [
    {
      type: "list-item",
      name: "EXPLORE",
      icon: "Explore",
      link: "",
      url: "",
      show: false,
      child_list: true,
      child: [
        {
          type: "list-heading",
          name: "Music Jobs",
          link: "",
          url: "",
          show: false,
        },
        {
          type: "list-heading",
          name: "Find Talent",
          link: "",
          url: "",
          show: false,
        },
        {
          type: "list-heading",
          name: "Find Companies",
          link: "",
          url: "",
          show: false,
        },
      ],
    },
    {
      type: "list-item",
      name: "FEED",
      icon: "Feed",
      link: "",
      url: "",
      show: true,
      child_list: false,
    },
    {
      type: "list-item",
      name: "MY JOBS",
      icon: "My-Music",
      link: "",
      url: "",
      show: false,
      child_list: true,
      child: [
        {
          type: "list-heading",
          name: "Favourites",
          link: "",
          url: "",
          show: false,
        },
      ],
    },
    {
      type: "horizontal-line",
      show: true,
    },
    {
      type: "list-item-shadow",
      name: "New Job",
      icon: "New-Product",
      link: "",
      url: "",
      show: true,
      child_list: false,
    },
  ],
};
export const MARKET_NAV = {
  name: "MARKET",
  type_icon: "market.svg",
  type_color: "#15CA86",
  header_icon: "icon_navigation.svg",
  header_icon_collapsed: "collapse_icon_navigation.svg",
  list: [
    {
      type: "list-item",
      name: "EXPLORE",
      icon: "Explore",
      link: "",
      url: "",
      show: false,
      child_list: true,
      child: [
        {
          type: "list-heading",
          name: "Overview",
          link: "",
          url: "",
          show: false,
        },
        {
          type: "list-heading",
          name: "Samples & Beats",
          link: "",
          url: "",
          show: false,
        },
        {
          type: "list-heading",
          name: "Merch",
          link: "",
          url: "",
          show: false,
        },
      ],
    },
    {
      type: "list-item",
      name: "FEED",
      icon: "Feed",
      link: "",
      url: "",
      show: true,
      child_list: false,
    },
    {
      type: "list-item",
      name: "MY MARKET",
      icon: "My-Music",
      link: "",
      url: "",
      show: false,
      child_list: true,
      child: [
        {
          type: "list-heading",
          name: "Favourites",
          link: "",
          url: "",
          show: false,
        },
        {
          type: "list-heading",
          name: "My Orders",
          link: "",
          url: "",
          show: false,
        },
      ],
    },
    {
      type: "horizontal-line",
      show: true,
    },
    {
      type: "list-item-shadow",
      name: "New Product",
      icon: "New-Product",
      link: "",
      url: "",
      show: true,
      child_list: false,
    },
  ],
};
export const TELEVISION_NAV = {
  name: "TELEVISION",
  type_icon: "television.svg",
  type_color: "red",
  header_icon: "icon_navigation.svg",
  header_icon_collapsed: "collapse_icon_navigation.svg",
  list: [
    {
      type: "list-item",
      name: "EXPLORE",
      icon: "Explore",
      link: "",
      url: "",
      show: false,
      child_list: true,
      child: [
        {
          type: "list-heading",
          name: "Live Videos",
          link: "",
          url: "",
          show: false,
        },
        {
          type: "list-heading",
          name: "Video",
          link: "",
          url: "",
          show: false,
        },
      ],
    },
    {
      type: "list-item",
      name: "FEED",
      icon: "Feed",
      link: "",
      url: "",
      show: true,
      child_list: false,
    },
    {
      type: "list-item",
      name: "MY TELEVISION",
      icon: "My-Music",
      link: "",
      url: "",
      show: false,
      child_list: true,
      child: [
        {
          type: "list-heading",
          name: "Favourites",
          link: "",
          url: "",
          show: false,
        },
        {
          type: "list-heading",
          name: "Recently Viewed",
          link: "",
          url: "",
          show: false,
        },
      ],
    },
    {
      type: "horizontal-line",
      show: true,
    },
    {
      type: "list-item-shadow",
      name: "Add Video",
      icon: "New-Product",
      link: "",
      url: "",
      show: true,
      child_list: false,
    },
    {
      type: "list-item-shadow",
      name: "Live Video",
      icon: "Engage-Buttons",
      link: "",
      url: "",
      show: true,
      child_list: false,
    },
  ],
};
export const NAVIGATION_BARS = {
  home: HOME_NAV,
  music: MUSIC_NAV,
  event: EVENT_NAV,
  job: JOB_NAV,
  market: MARKET_NAV,
  television: TELEVISION_NAV,
};
//navigation bars

//managers sidebar
export const PAGE_NAV = {
  name: "PAGE MANAGER",
  type_icon: "page.svg",
  type_color: "#071526",
  select_artist: true,
  header_icon: "icon_navigation.svg",
  header_icon_collapsed: "collapse_icon_navigation.svg",
  list: [
    {
      type: "list-item",
      name: "Home",
      icon: "Home",
      link: "",
      url: "/page-manager/page/artist/",
      show: true,
    },
    {
      type: "list-item",
      name: "Inbox",
      icon: "Inbox",
      link: "",
      url: "/chat-manager/chat/",
      show: true,
    },
    {
      type: "list-item",
      name: "Dashboard",
      icon: "Dashboard",
      link: "",
      url: "/home/page/dashboard/",
      show: true,
    },
    {
      type: "list-item",
      name: "Insights",
      icon: "Insights",
      link: "",
      url: "/home/page/insights/",
      show: true,
    },
    {
      type: "list-item",
      name: "Ads Center",
      icon: "Ads-Centre",
      link: "",
      url: "/home/page/ads-center/",
      show: true,
    },
    {
      type: "list-item",
      name: "Finance",
      icon: "Finances",
      link: "",
      url: "/home/page/finance/",
      show: true,
    },
    {
      type: "list-heading",
      name: "PRODUCT & SERVICES",
      icon: "xyz",
      link: "",
      url: "/home/page/product-service/",
      show: false,
    },
    {
      type: "list-item",
      name: "Events",
      icon: "Events1",
      link: "",
      url: "/event-manager/page/",
      show: true,
    },
    {
      type: "list-item",
      name: "Jobs",
      icon: "Jobs1",
      link: "",
      url: "/jobs-manager/page/",
      show: true,
    },
    {
      type: "list-item",
      name: "Shop",
      icon: "Products",
      link: "",
      url: "/market-manager/page/",
      show: true,
    },

    {
      type: "list-heading",
      name: "SETTINGS",
      icon: "xyz",
      link: "",
      url: "/page-manager/page/list-heading/",
      show: false,
    },

    {
      type: "list-item",
      name: "Edit Page",
      icon: "Edit-Page",
      link: "",
      url: "/home/page/pages",
      child_list: true,
      child: [
        {
          type: "list-heading",
          name: "Basic Info",
          link: "",
          url: "/page-manager/page/page-information/",
          show: false,
        },
        {
          type: "list-heading",
          name: "About & Skills",
          link: "",
          url: "/page-manager/page/about-and-skills/",
          show: false,
        },
        {
          type: "list-heading",
          name: "Work & education",
          link: "",
          url: "/page-manager/page/work-and-education/",
          show: false,
        },
        {
          type: "list-heading",
          name: "Projects",
          link: "",
          url: "/page-manager/page/projects/",
          show: false,
        },
        {
          type: "list-heading",
          name: "Availability",
          link: "",
          url: "/page-manager/page/availability/",
          show: false,
        },
        {
          type: "list-heading",
          name: "Location",
          link: "",
          url: "/page-manager/page/location/",
          show: false,
        },
        {
          type: "list-heading",
          name: "Contact",
          link: "",
          url: "/page-manager/page/contact-information/",
          show: false,
        },
      ],
      show: true,
    },
    {
      type: "list-item",
      name: "Settings",
      icon: "General-Settings",
      link: "",
      child_list: true,
      child: [
        {
          type: "list-heading",
          name: "Page Setting",
          link: "",
          url: "/page-manager/page/general-settings/",
          show: false,
        },
        {
          type: "list-heading",
          name: "Work Setting",
          link: "",
          url: "/page-manager/page/work-settings/",
          show: false,
        },
        {
          type: "list-heading",
          name: "Payment Setting",
          link: "",
          url: "/page-manager/page/payment-settings/",
          show: false,
        },
      ],
      url: "",
      show: true,
    },
    {
      type: "list-item",
      name: "Notifications",
      icon: "Notifications",
      link: "",
      url: "/page-manager/page/artist-notifications/",
      show: true,
    },
    {
      type: "list-item",
      name: "Partners",
      icon: "Partners",
      link: "",
      url: "/page-manager/page/partner/",
      show: true,
    },
    {
      type: "list-item",
      name: "Engage Buttons",
      icon: "Engage-Buttons",
      link: "",
      url: "/page-manager/page/engagement/",
      show: true,
    },
    {
      type: "list-item",
      name: "Verifications",
      icon: "Verification",
      link: "",
      url: "/page-manager/page/verification/",
      show: true,
    },
    {
      type: "list-item",
      name: "Admins",
      icon: "Admins",
      link: "",
      url: "/page-manager/page/admins/",
      show: true,
    },
  ],
};
export const CLUB_NAV = {
  name: "CLUB MANAGER",
  type_icon: "page.svg",
  type_color: "#071526",
  select_artist: true,
  return: true,
  return_url: "/page-manager/page/club/",
  return_icon: "Page-Manager",
  return_text: "Club Page",
  header_icon: "icon_navigation.svg",
  header_icon_collapsed: "collapse_icon_navigation.svg",
  list: [
    {
      type: "list-item",
      name: "Club Information",
      icon: "Edit-Page",
      link: "",
      url: "/page-manager/page/club/page-information/",
      show: true,
    },
    {
      type: "list-item",
      name: "General Settings",
      icon: "General-Settings",
      link: "",
      url: "/page-manager/page/club/general-settings/",
      show: false,
    },
    {
      type: "list-item",
      name: "Member Requests",
      icon: "Inbox",
      link: "",
      url: "/page-manager/page/club/member-requests/",
      show: true,
    },
    {
      type: "list-item",
      name: "Events",
      icon: "Events1",
      link: "",
      url: "/event-manager/page/",
      show: true,
    },
    {
      type: "list-item",
      name: "Admins",
      icon: "Admins",
      link: "",
      url: "/page-manager/page/admins/",
      show: true,
    },
    {
      type: "list-item",
      name: "Insights",
      icon: "Insights",
      link: "",
      url: "/home/page/insights/",
      show: true,
    },
  ],
};
export const PARTY_NAV = {
  name: "PARTY MANAGER",
  type_icon: "page.svg",
  type_color: "#071526",
  select_artist: true,
  return_url: "/page-manager/page/party/",
  return: true,
  return_icon: "Page-Manager",
  return_text: "Party Page",
  header_icon: "icon_navigation.svg",
  header_icon_collapsed: "collapse_icon_navigation.svg",
  list: [
    {
      type: "list-item",
      name: "Party Information",
      icon: "Edit-Page",
      link: "",
      url: "/page-manager/page/party/page-information/",
      show: true,
    },
    {
      type: "list-item",
      name: "General Settings",
      icon: "General-Settings",
      link: "",
      url: "/page-manager/page/party/general-settings/",
      show: false,
    },
    {
      type: "list-item",
      name: "Member Requests",
      icon: "Inbox",
      link: "",
      url: "/page-manager/page/party/member-requests/",
      show: true,
    },
    {
      type: "list-item",
      name: "Events",
      icon: "Events1",
      link: "",
      url: "/event-manager/page/",
      show: true,
    },
    {
      type: "list-item",
      name: "Admins",
      icon: "Admins",
      link: "",
      url: "/page-manager/page/admins/",
      show: true,
    },
    {
      type: "list-item",
      name: "Insights",
      icon: "Insights",
      link: "",
      url: "/home/page/insights/",
      show: true,
    },
  ],
};
export const EVENT_MANAGE_NAV = {
  name: "EVENT MANAGER",
  type_icon: "events.svg",
  type_color: "#AE4EA9",
  select_artist: true,
  return: true,
  return_url: "event",
  return_icon: "Page-Manager",
  return_text: "Page Manager",
  header_icon: "icon_navigation.svg",
  header_icon_collapsed: "collapse_icon_navigation.svg",
  list: [
    {
      type: "list-item",
      name: "Dashboard",
      icon: "Insights",
      link: "",
      url: "/event-manager/page/dashboard",
      show: true,
    },
    {
      type: "list-item",
      name: "Events",
      icon: "Events1",
      link: "",
      url: "/event-manager/page/events",
      show: true,
    },
    {
      type: "list-item",
      name: "Tickets",
      icon: "Tickets",
      link: "",
      url: "/event-manager/page/tickets",
      show: true,
    },
    {
      type: "list-item",
      name: "Orders",
      icon: "Orders",
      link: "",
      url: "/event-manager/page/orders",
      show: true,
    },
    {
      type: "horizontal-line",
      show: true,
    },
    {
      type: "list-item",
      name: "Insights",
      icon: "Insights",
      link: "",
      url: "/event-manager/page/insights",
      show: true,
    },
    {
      type: "list-item",
      name: "Ads",
      icon: "Ads-Centre",
      link: "",
      url: "/event-manager/page/ads",
      show: true,
    },
    {
      type: "list-item",
      name: "Settings",
      icon: "General-Settings",
      link: "",
      url: "/event-manager/page/settings",
      show: true,
    },
    {
      type: "horizontal-line",
      show: true,
    },
    {
      type: "list-item-shadow",
      name: "New Job",
      icon: "New-Product",
      link: "",
      url: "",
      show: true,
      child_list: false,
    },
  ],
};
export const JOB_MANAGE_NAV = {
  name: "JOBS MANAGER",
  type_icon: "jobs.svg",
  type_color: "#0CAACD",
  select_artist: true,
  return: true,
  return_url: "/not-found",
  return_text: "Page Manager",
  return_icon: "Page-Manager",
  employee_switch: true,
  header_icon: "icon_navigation.svg",
  header_icon_collapsed: "collapse_icon_navigation.svg",
  list: [
    {
      type: "list-item",
      name: "Dashboard",
      icon: "Dashboard",
      link: "",
      url: "/home/settings",
      show: true,
    },
    {
      type: "list-item",
      name: "Jobs",
      icon: "Jobs1",
      required_type: "Employer",
      link: "",
      url: "/home/page/people",
      show: true,
    },
    {
      type: "list-item",
      name: "Talent",
      icon: "Talent",
      required_type: "Employer",
      link: "",
      url: "/home/page/people",
      show: true,
    },
    {
      type: "list-item",
      name: "Offers",
      icon: "Offers",
      required_type: "Employee",
      link: "",
      url: "/home/page/people",
      show: true,
    },
    {
      type: "list-item",
      name: "Proposals",
      icon: "Proposals",
      required_type: "Employee",
      link: "",
      url: "/home/page/people",
      show: true,
    },
    {
      type: "list-item",
      name: "Reports",
      icon: "Reports",
      link: "",
      url: "/not-found",
      show: true,
    },
    {
      type: "list-item",
      name: "Ads",
      icon: "Ads-Centre",
      required_type: "Employer",
      link: "",
      url: "/home/page/news-feed",
      show: true,
    },
    {
      type: "list-item",
      name: "Settings",
      icon: "General-Settings",
      link: "",
      url: "/home/page/people",
      show: true,
    },
    {
      type: "horizontal-line",
      show: true,
    },
    {
      type: "list-item-shadow",
      name: "New Job",
      icon: "New-Product",
      link: "",
      url: "",
      show: true,
      child_list: false,
    },
  ],
};
export const SHOP_MANAGE_NAV = {
  name: "SHOP MANAGER",
  type_icon: "page.svg",
  type_color: "#15CA86",
  select_artist: true,
  return: true,
  return_url: "event",
  return_text: "Page Manager",
  return_icon: "Page-Manager",
  header_icon: "icon_navigation.svg",
  header_icon_collapsed: "collapse_icon_navigation.svg",
  list: [
    {
      type: "list-item",
      name: "Dashboard",
      icon: "Dashboard",
      link: "",
      url: "/market-manager/page/",
      show: true,
    },
    {
      type: "list-item",
      name: "Products",
      icon: "Products",
      link: "",
      url: "/not-found",
      show: true,
    },
    {
      type: "list-item",
      name: "Licenses",
      icon: "Licenses",
      link: "",
      url: "/home/page/people",
      show: true,
    },
    {
      type: "list-item",
      name: "Memberships",
      icon: "Memberships",
      link: "",
      url: "/not-found",
      show: true,
    },
    {
      type: "horizontal-line",
      show: true,
    },
    {
      type: "list-item",
      name: "Negotiations",
      icon: "Negotiations",
      link: "",
      url: "/home/page/news-feed",
      show: true,
    },
    {
      type: "list-item",
      name: "Orders",
      icon: "Orders",
      link: "",
      url: "/not-found",
      show: true,
    },
    {
      type: "horizontal-line",
      show: true,
    },
    {
      type: "list-item",
      name: "Ads",
      icon: "Ads-Centre",
      link: "",
      url: "/home/page/news-feed",
      show: true,
    },
    {
      type: "list-item",
      name: "Settings",
      icon: "General-Settings",
      link: "",
      url: "/not-found",
      show: true,
    },
    {
      type: "horizontal-line",
      show: true,
    },
    {
      type: "list-item-shadow",
      name: "New Product",
      icon: "New-Product",
      link: "",
      url: "/market-manager/page/add-product/",
      show: true,
      child_list: false,
    },
  ],
};
export const MANGERS_BARS = {
  page: PAGE_NAV,
  club: CLUB_NAV,
  party: PARTY_NAV,
  event: EVENT_MANAGE_NAV,
  job: JOB_MANAGE_NAV,
  market: SHOP_MANAGE_NAV,
};
//managers sidebar

/** ------------------------------ */
