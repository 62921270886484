import * as Utils from "../../components/utils/Utils";
import {
  Add_Favourite_Action,
  Add_Favourite_Object_Type,
  Add_Favourite_User_Id,
  My_Followers_Limit,
  My_Followers_List,
  My_Followers_Offset,
  My_Followers_Total,
  My_Followings_Limit,
  My_Followings_List,
  My_Followings_Offset, My_Followings_Total,
  My_Friends_Limit,
  My_Friends_List, MY_FRIENDS_LIST_NOT_HAVING_MEMBERS,
  My_Friends_Offset,
  My_Friends_Total,
  Widgets_Friends_List
} from "../types";
import {
  getSentRequests,
  addFriendApi,
  getPendingFriendRequests,
  getFriendsSuggestion,
  responseFriendRequest,
  removeSuggestedFriend,
  removeSentRequest,
  getBirthdays,
  searchFriends,
  exploreFriends,
  getCities,
  getWorkPlace,
  getSchools,
  getfilteredUsers,
  getMonthBirthdays,
  getMyFriends,
  getMyFollowers,
  getMyFollowings,
  changeFollowStatus,
  updateFriendStatus,
  searchProfileFriends,
  getFriendsListWithOutHavingMembersOfThatPage, blockedUsersListing
} from "../../apis/APIs";
import { ERROR, POST_LIMIT, POST_OFFSET, SUCCESS } from "../types";

const INITIAL_STATE = {
  explore_friend_list:[],
  friends_suggestion:[],
  total_friend_suggestion_count:null,
  total_friend_explore_count:null,
  pending_friend_requests:[],
  sent_friend_requests:[],
  total_pending_friend_request_count:null,
  total_sent_request_count:null,
  total_friend_birthdays_count:null,
  today_birthdays:[],
  tomorrow_birthdays:[],
  next_week_birthdays:[],
  month_birthdays:[],
  today_birthday_count:null,
  tomorrow_birthday_count:null,
  next_week_birthday_count:null,
  monthly_birthday_count:null,
  total_birthdays_count:null,
  searchFriendsList:[],
  blockedUsersList:[],
  offset:0,
  limit:0,
  citiesLists:[],
  featuredCities:[],
  workSpaceLists:[],
  featuredWorkSpace:[],
  schoolsList:[],
  featuredSchoolList:[],
  filteredPayload:null,
  filtered_friends_list:[],
  total_filtered_friend_counts:null,
  search:'',
  city:'',
  work:'',
  school:'',
  day:'',
  monthlyBirthdaysList:[],
  current_date:'',
  [My_Friends_List]: [],
  [My_Friends_Offset]: 0,
  [My_Friends_Limit]: 10,
  [My_Friends_Total]: 0,
  [My_Followers_List]: [],
  [My_Followers_Offset]: 0,
  [My_Followers_Limit]: 10,
  [My_Followers_Total]: 0,
  [My_Followings_List]: [],
  [My_Followings_Offset]: 0,
  [My_Followings_Limit]: 10,
  [My_Followings_Total]: 0,
  [Add_Favourite_User_Id]: null,
  [Add_Favourite_Object_Type]: null,
  [Add_Favourite_Action]: null,
  [Widgets_Friends_List]: null,
  [MY_FRIENDS_LIST_NOT_HAVING_MEMBERS] : [],
  addFriendMessage:null,
}
export const state = {...INITIAL_STATE};

export const getters = {
  
  getSearchedFriendsList(state) {
    return state.searchFriendsList
  },
  getBlockedUsers(state){
    return state.blockedUsersList
  },
  getCitiesList(state){
    return state.citiesLists
  },
  featuredCitiesList(state){
    return state.featuredCities
  },
  getSchoolsList(state){
    return state.schoolsList
  },
  featuredSchoolList(state){
    return state.featuredSchoolList
  },
  getWorksList(state){
    return state.workSpaceLists
  },
  featuredWorkSpaceList(state){
    return state.featuredWorkSpace
  },
  getMyFriends(state){
    return state[My_Friends_List]
  },
  getFriendsTotal(state){
    return state[My_Friends_Total]
  },
  getMyFollowers(state){
    return state[My_Followers_List]
  },
  getFollowersTotal(state){
    return state[My_Followers_Total]
  },
  getMyFollowings(state){
    return state[My_Followings_List]
  },
  getFollowingsTotal(state){
    return state[My_Followings_Total]
  },
  getWidgetFriends(state){
    return state[Widgets_Friends_List];
  },
  getFriendsListWithoutMembersInIt(state) {
    return state[MY_FRIENDS_LIST_NOT_HAVING_MEMBERS]
  },

}

export const mutations = {
  setSearchedFriendsList(state, payload) {
    state.searchFriendsList = payload
  },
  setMyFriends(state, payload){
    try{
      state[My_Friends_List] = payload
    }catch (e) {
      console.error(e.message, 'unable to get friends')
    }
  },
  join_with_setMyFriends(state,payload){
    try{
      let friends = state[My_Friends_List]
      state[My_Friends_List] = [...friends, ...payload]
    }catch (e) {
      console.error(e.message, 'unable to get friends')
    }
  },
  setFriendsTotal(state, payload){
    try{
      state[My_Friends_Total] = payload
    }catch (e) {
      console.error(e.message, 'unable to get friends total')
    }
  },
  setMyFollowers(state, payload){
    try{
      state[My_Followers_List] = payload
    }catch (e) {
      console.error(e.message, 'unable to get followers')
    }
  },
  join_with_setMyFollowers(state, payload){
    try{
      let myFollowers = state[My_Followers_List]
      state[My_Followers_List] = [...myFollowers, ...payload]
    }catch (e) {
      console.error(e.message, 'unable to get followers')
    }
  },
  setFollowersTotal(state, payload){
    try{
      state[My_Followers_Total] = payload
    }catch (e) {
      console.error(e.message, 'unable to get followers total')
    }
  },
  setMyFollowings(state, payload){
    try{
      state[My_Followings_List] = payload
    }catch (e) {
      console.error(e.message, 'unable to get followings')
    }
  },
  join_with_setMyFollowings(state, payload){
    try{
      let myFollowings = state[My_Followings_List]
      state[My_Followings_List] = [...myFollowings, ...payload]
    }catch (e) {
      console.error(e.message, 'unable to get followings')
    }
  },
  setFollowingsTotal(state, payload){
    try{
      state[My_Followings_Total] = payload
    }catch (e) {
      console.error(e.message, 'unable to get followings total')
    }
  },
  removeFromFriends(state, payload){
    let list = state[My_Friends_List].indexOf(payload)
    state[My_Friends_List].splice(list, 1)
    state[My_Friends_Total] -= 1;
  },
  // updateStatusAll(state,payload){
  //   if(state[My_Followers_List].filter(follower =>follower.id === payload.user.id)[0]){
  //     let follower = state[My_Followers_List].filter(follower =>follower.id === payload.user.id)[0]
  //     follower.follow_status = payload.action;
  //   }
  //   if(state[My_Followings_List].filter(following =>following.id === payload.user.id)[0]){
  //     let following = state[My_Followers_List].filter(follower =>follower.id === payload.user.id)[0]
  //     following.follow_status = payload.action;
  //   }
  //
  // }
  setWidgetFriends(state,payload){
    try{
      state[Widgets_Friends_List] = payload
    }catch (e) {
      console.error(e.message, 'unable to set widgets friend list')
    }
  },
  setFriendsListWithout(state, payload) {
    try {
      state[MY_FRIENDS_LIST_NOT_HAVING_MEMBERS] = payload
    } catch (e) {
      console.error(e.message, 'unable to set friends list')
    }
  }
}

export const actions = {
  async getFriendsSuggestion({state, commit, dispatch}, clearlist){
    return new Promise(async (resolve, reject) => {
      try {
        dispatch('loading', true)
        if(clearlist === 'yes'){
          state.friends_suggestion = [];
          state.explore_friend_list = [];
          state.filtered_friends_list = [];
          state.total_friend_explore_count = null;
          state.total_friend_suggestion_count = null;
          state.total_filtered_friend_counts = null;
        }
        state.offset = state.friends_suggestion.length;
        // state.limit = state.friends_suggestion.length;
        state.limit = 12;
        const {data} = await getFriendsSuggestion(state.offset, state.limit);
        if(data.information.total === 0){
            state.offset = state.explore_friend_list.length;
            //state.limit = state.explore_friend_list.length;
            state.limit = 12;
           const {data} = await exploreFriends(state.offset, state.limit);
            let explore_friends = state.explore_friend_list;
            state.explore_friend_list = [...explore_friends, ...data.information.data]
            state.total_friend_explore_count = data.information.total;
        }else{
          let friends_suggestion_list = state.friends_suggestion;
          state.friends_suggestion = [...friends_suggestion_list, ...data.information.data]
          state.total_friend_suggestion_count = data.information.total;
        }
        //state.friends_suggestion = data.information.data;
        dispatch('loading', false)
        resolve({
          status: SUCCESS,
          message: "",
          data
        });
      } catch (error) {
        dispatch('loading', false)
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async fetchBlockUsersData({state, commit, dispatch}, value = '') {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch('loading', true)
        state.blockedUsersList = [];
        const {data} = await blockedUsersListing(value);
        state.blockedUsersList = [...data.information.data]
        dispatch('loading', false)
        resolve({
          status: SUCCESS,
          message: "",
          data
        });
      }
      catch (error) {
        dispatch('loading', false)
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async getfilteredUsers({state, commit, dispatch}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch('loading', true)
        state.friends_suggestion = [];
        state.explore_friend_list = [];
        state.total_friend_suggestion_count = null;
        state.total_friend_explore_count = null;
        if(payload !== null){
          state.filteredPayload = payload
        }
        // state.offset = state.filtered_friends_list.length;
        //state.limit = state.filtered_friends_list.length;
        state.offset = payload ? 0 : state.filtered_friends_list.length;
        state.limit = 12;
        state.search = (state.filteredPayload.search) ? state.filteredPayload.search : '';
        state.city = (state.filteredPayload.city) ? state.filteredPayload.city : '';
        state.work = (state.filteredPayload.work) ? state.filteredPayload.work : '';
        state.school = (state.filteredPayload.school) ? state.filteredPayload.school : '';
        const {data} = await getfilteredUsers(state.offset, state.limit, state.search, state.work, state.city, state.school);
        // let filtered_list = state.filtered_friends_list;
        // state.filtered_friends_list = [...filtered_list, ...data.information.data]
        state.filtered_friends_list = payload ? data.information.data : [...state.filtered_friends_list, ...data.information.data]
        state.total_filtered_friend_counts = data.information.total;
        dispatch('loading', false)
      }
      catch (error) {
          dispatch('loading', false)
          reject({
            status: ERROR,
            message: error.message ? error.message : "Please try again."
          });
        }
      })
  },
  async getPendingFriendRequests({state, commit, dispatch}){
    return new Promise(async (resolve, reject) => {
      try {
        state.offset = state.pending_friend_requests.length;
        //state.limit = state.pending_friend_requests.length;
        state.limit = 12;
        const {data} = await getPendingFriendRequests(state.offset, state.limit);
        //state.pending_friend_requests = data.information.data;
        let pending_friends_list = state.pending_friend_requests;
        state.pending_friend_requests = [...pending_friends_list, ...data.information.data]
        state.total_pending_friend_request_count = data.information.total;
        dispatch('loading', false)
        resolve({
          status: SUCCESS,
          message: "",
          data
        });
      } catch (error) {
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },

  async getSentRequests({ state, commit, dispatch }, returnKey = 'sent_friend_requests') {
    try {

      //FIXME: Did not understand the purpose of this line
      //state.offset = state.sent_friend_requests.length;

      state.offset = 0;
      state.limit = 12;

      const { data } = await getSentRequests(state.offset, state.limit);
      const { information } = data;

      state.sent_friend_requests = [
        ...state.sent_friend_requests,
        ...information.data
      ];
      state.total_sent_request_count = information.total;

      return { [returnKey]: data || {} };
    } catch (error) {
      throw error
    }
  },

  async getBirthdays({state,dispatch}, day){
    return new Promise(async (resolve, reject) => {
      try {
        dispatch('loading', true)
        state.day = day;
        if(state.day === ''){
          state.offset = 0;
          state.limit = 4;
        }
        else if(state.day === 'today'){
          state.offset = 0;
         // state.limit = state.today_birthday_count;
          state.limit = 4;
        }else if(state.day === 'tomorrow'){
          state.offset = 0;
          //state.limit = state.tomorrow_birthday_count;
          state.limit = 4;
        }else if(state.day === 'next_week'){
          state.offset = 0;
          //state.limit = state.next_week_birthday_count;
          state.limit = 4;
        }else if(state.day === 'month'){
          state.offset = 0;
          //state.limit = state.monthly_birthday_count;
          state.limit = 4;
        }
        const {data} = await getBirthdays(state.day, state.offset, state.limit);
        state.today_birthdays = data.information.today.data;
        state.tomorrow_birthdays = data.information.tomorrow.data;
        state.next_week_birthdays = data.information.next_week.data;
        state.month_birthdays = data.information.month.data;

        state.today_birthday_count = data.information.today.total;
        state.tomorrow_birthday_count = data.information.tomorrow.total;
        state.next_week_birthday_count = data.information.next_week.total;
        state.monthly_birthday_count = data.information.month.total;
        state.total_birthdays_count = data.information.month.total;
        dispatch('loading', false)
        resolve({
          status: SUCCESS,
          message: "",
          data
        });
      }catch(error){
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async getMonthlyBirthdays({state, dispatch}, date){
    return new Promise(async (resolve, reject) => {
        try {
          dispatch('loading', true)
          state.current_date = date;
          const {data} = await getMonthBirthdays(state.current_date);
          state.monthlyBirthdaysList = data.information.month.data
          dispatch('loading', false)
          resolve({
            status: SUCCESS,
            message: "",
            data
          });
        }catch(error){
          reject({
            status: ERROR,
            message: error.message ? error.message : "Please try again."
          });
        }
    })
  },
  async getCities({state,dispatch}){
    return new Promise(async (resolve, reject) => {
      try {
        const {data} = await getCities();
        state.citiesLists = data.information;
        state.featuredCities = data.featured_city;
      }catch(error){
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async getWorkPlace({state,dispatch}){
    return new Promise(async (resolve, reject) => {
      try {
        const {data} = await getWorkPlace();
        state.workSpaceLists = data.information;
        state.featuredWorkSpace = data.featured_work;
      }catch(error){
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async getSchools({state,dispatch}){
    return new Promise(async (resolve, reject) => {
      try {
        dispatch('loading', true)
        const {data} = await getSchools();
        state.schoolsList = data.information;
        state.featuredSchoolList = data.featured_school;
        dispatch('loading', false)
        resolve({
          status: SUCCESS,
          message: "",
          data
        });
      }catch(error){
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async addFriendApi({state,commit,dispatch}, payload){
    return new Promise(async (resolve, reject) =>{
      try{
        dispatch('loading', true)
        let obj = {
          friend_id : payload.id
        }
        const {data} = await addFriendApi(obj);
        dispatch('loading', false)
        resolve({
          status: SUCCESS,
          message: "",
          data
        });
        if(data.success === 1){
          let index = state.friends_suggestion.indexOf(payload)
          if(state.friends_suggestion.length !== 0){
            state.friends_suggestion.splice(index, 1)
            state.total_friend_suggestion_count -= 1;
          }else{
            let explore_index = state.explore_friend_list.indexOf(payload)
            state.explore_friend_list.splice(explore_index, 1)
            state.total_friend_explore_count -= 1;
          }
          if(state.filtered_friends_list.length !== 0){
            state.filtered_friends_list.splice(index, 1)
            state.total_filtered_friend_counts -= 1;
          }
          state.sent_friend_requests.push(payload)
          state.total_sent_request_count += 1;
        }
      }catch(error){
        dispatch('loading', false)
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async removeSuggestedFriend({state,dispatch}, payload) {
    return new Promise(async (resolve, reject) => {
        try {
          dispatch('loading', true)
          let obj = {
            user_id : payload.id
          }
          const {data} = await removeSuggestedFriend(obj);
          dispatch('loading', false)
          resolve({
            status: SUCCESS,
            message: "",
            data
          });
          if(data.success === 1) {
            if (state.friends_suggestion.length !== 0) {
              let index = state.friends_suggestion.indexOf(payload)
              state.friends_suggestion.splice(index, 1)
            } else if (state.explore_friend_list.length !== 0) {
              let explore_index = state.explore_friend_list.indexOf(payload)
              state.explore_friend_list.splice(explore_index, 1)
              state.total_friend_explore_count -= 1;
            } else if (state.filtered_friends_list.length !== 0) {
              let filter_index = state.filtered_friends_list.indexOf(payload)
              state.filtered_friends_list.splice(filter_index, 1)
              state.total_filtered_friend_counts -= 1;
            }
          }
        }catch(error){
          dispatch('loading', false)
          reject({
            status: ERROR,
            message: error.message ? error.message : "Please try again."
          });
        }
    })
  },
  async removeSentRequest({state,dispatch, commit}, payload){
    return new Promise(async (resolve, reject) => {
      try {
        dispatch('loading', true)
        let obj = {
          user_id : payload.id
        }
        const {data} = await removeSentRequest(obj);
        dispatch('loading', false)
        resolve({
          status: SUCCESS,
          message: "",
          data
        });
        if(data.success === 1){
          let index = state.sent_friend_requests.indexOf(payload)
          state.sent_friend_requests.splice(index, 1)
          state.total_sent_request_count -= 1;
          commit('removeFromFriends', payload);
        }
      }catch(error){
        dispatch('loading', false)
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async responseFriendRequest({state,dispatch}, payload){
    return new Promise(async (resolve, reject) => {
      try {
        dispatch('loading', true)
        let obj = {
          request_id : payload.payload.id,
          action : payload.action
        }
        const {data} = await responseFriendRequest(obj);
        dispatch('loading', false)
        resolve({
          status: SUCCESS,
          message: "",
          data
        });
        if(data.success === 1){
          if(payload.action === 1){
            payload.payload.status = 'confirmed';
          }else if(payload.action === 0){
            payload.payload.status = 'rejected';
          }
        }
      } catch (error) {
        dispatch('loading', false)
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async searchFriends({state,dispatch,commit}, searchKey){
    return new Promise(async (resolve, reject) => {
      try {
        const {data} = await searchFriends(searchKey);
        commit('setSearchedFriendsList', data.information.data)
        resolve('Resolved');
      }catch(error){
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
      })
  },

  async getMyFriends({ state, dispatch, commit }, payload, returnKey = 'my_friends') {
    try {

      const isShowMore = payload.showMore;
      const offset = isShowMore ? state[My_Friends_List].length : 0;
      const limit = 10;

      state[My_Friends_Offset] = offset;
      state[My_Friends_Limit] = limit;

      const fetchFriendsData = payload.friendsSearchKey
        ? await searchProfileFriends(offset, limit, payload.search_type, payload.friendsSearchKey)
        : await getMyFriends(offset, limit);

      const data = fetchFriendsData.data;

      const friendsData = data.information.data;
      if (isShowMore) {
        commit('join_with_setMyFriends', friendsData);
      } else {
        commit('setWidgetFriends', friendsData.slice(0, 8));
        commit('setMyFriends', friendsData);
      }

      commit('setFriendsTotal', data.information.total);

      return { [returnKey]: data || {} };
    } catch (error) {
      throw error;
    }
  },

  async getWidgetsFriends({state,dispatch,commit}){
    return new Promise(async (resolve, reject) => {
      try {
        dispatch('loading', true)
        const {data} = await getMyFriends(0, 8);
        commit('setWidgetFriends', data.information.data)
        dispatch('loading', false)
        resolve({
          status: SUCCESS,
          message: "",
          data
        });
      }catch(error) {
        dispatch('loading', false)
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },

  async getMyFollowers({ state, dispatch, commit }, payload, returnKey = 'my_followers') {
    try {
      const isShowMore = payload.showMore;
      const offset = isShowMore ? state[My_Followers_List].length : 0;
      const limit = 10;

      state[My_Followers_Offset] = offset;
      state[My_Followers_Limit] = limit;

      const fetchFollowersData = payload.friendsSearchKey
        ? await searchProfileFriends(offset, limit, payload.search_type, payload.friendsSearchKey)
        : await getMyFollowers(offset, limit);

      const data = fetchFollowersData.data;

      const followersData = data.information.data;
      if (isShowMore) {
        commit('join_with_setMyFollowers', followersData);
      } else {
        commit('setMyFollowers', followersData);
      }

      commit('setFollowersTotal', data.information.total);

      return { [returnKey]: data || {} };
    } catch (error) {
      throw error;
    }
  },

  async getMyFollowings({ state, dispatch, commit }, payload, returnKey = 'my_followings') {
    try {
      const isShowMore = payload.showMore;
      const offset = isShowMore ? state[My_Followings_List].length : 0;
      const limit = 10;

      state[My_Followings_Offset] = offset;
      state[My_Followings_Limit] = limit;

      const fetchFollowingsData = payload.friendsSearchKey
        ? await searchProfileFriends(offset, limit, payload.search_type, payload.friendsSearchKey)
        : await getMyFollowings(offset, limit);

      const data = fetchFollowingsData.data;

      const followingsData = data.information.data;
      if (isShowMore) {
        commit('join_with_setMyFollowings', followingsData);
      } else {
        commit('setMyFollowings', followingsData);
      }

      commit('setFollowingsTotal', data.information.total);

      return { [returnKey]: data || {} };
    } catch (error) {
      throw error
    }
  },

  async changeFollowStatus({state,dispatch,commit}, payload){
    return new Promise(async (resolve, reject) => {
      try {
        let obj = {
          id : payload.id,
          object_type : payload.object_type,
          action : (payload.action)?1:0,
          // page_id: payload.page_id
        }
        const {data} = await changeFollowStatus(obj);
        if(data.success === 1){
          if(payload.user){
            payload.user.follow_status = payload.action
            //commit('updateStatusAll',payload)
          }
        }
        resolve({
          status: SUCCESS,
          message: "",
          data
        });
      }
      catch (error){
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async updateFriendStatus({state,dispatch,commit}, payload){
    return new Promise(async (resolve, reject) => {
      try {
        dispatch('loading', true)
        let obj = {
          friend_id: payload.user.friend_status.id,
          close_friends : payload.close_friends,
          acquaintance : payload.acquaintance,
        }
        const {data} = await updateFriendStatus(obj);
        if(data.success === 1){
          if(payload.user){
            payload.user.friend_status.close_friends = payload.close_friends
            payload.user.friend_status.acquaintance = payload.acquaintance
          }
        }
        dispatch('loading', false)
        resolve({
          status: SUCCESS,
          message: "",
          data
        });
      }
      catch (error){
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  },
  async getFriendListWithoutHavingMembers({state,dispatch,commit}, page_id){
    return new Promise(async (resolve, reject) => {
      try {
        const {data} = await getFriendsListWithOutHavingMembersOfThatPage(page_id);
        const {information} = data || {}
        if(data.success === 1){
          commit('setFriendsListWithout', information)
        }
        resolve({
          status: SUCCESS,
          message: "",
          data
        });
      }
      catch (error){
        reject({
          status: ERROR,
          message: error.message ? error.message : "Please try again."
        });
      }
    })
  }
}
export default {
  state,
  actions,
  getters,
  mutations,
};


