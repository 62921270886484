<template>
  <div
    :class="[
      'dropdown-menu more-dropdown',
      rightBarCheckOption && showOption
        ? 'fixed-position show'
        : showOption
        ? 'absolute-position show'
        : '',
    ]"
    aria-labelledby="moreDropdown"
    :style="{ top: heightOfOption + 'px', left: widthOfOption + 'px' }"
    v-click-outside="clickOutside"
  >
<!--    {{favoriteAction}}-->
    <a
      class="dropdown-item item-selling js-save-wishlist"
      @click="addToWishlist"
      v-if="
        (listType === 'default_option_list' ||
          listType === 'queue_option_list' ||
          listType === 'history_option_list') &&
        isSelling === true
      "
    >
      <span>Add to Wishlist</span>
    </a>
    <a
      class="dropdown-item item-selling js-save-wishlist"
      href="javascript:void(0)"
      v-if="
        (listType === 'default_option_list' ||
          listType === 'queue_option_list' ||
          listType === 'history_option_list') &&
        isSelling === true
      "
    >
      <span>Buy</span>
      <div class="more-price">${{ getPrice(songObject) }}</div>
    </a>
    <a
      class="dropdown-item item-selling js-save-wishlist"
      href="javascript:void(0)"
      @click="addToQueue"
      v-if="listType === 'default_option_list' && isSelling === false"
    >
      <span>Add to Queue</span>
    </a>
    <a
      class="dropdown-item item-selling js-save-wishlist"
      href="javascript:void(0)"
      @click="removeFromQueue"
      v-if="listType === 'queue_option_list'"
    >
      <span>Remove From Queue</span>
    </a>
    <a
      @click="addToPlaylist"
      class="dropdown-item js-share-album"
      href="javascript:void(0)"
      v-if="
        (listType === 'default_option_list' ||
          listType === 'queue_option_list' ||
          listType === 'history_option_list') &&
        isSelling === false
      "
    >
      <span>Add to Playlist</span>
      <span class="sh_tab">
        <svg
          width="6"
          height="11"
          viewBox="0 0 6 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L5.3 5.231L1 9.833"
            stroke="#47505B"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="c-line-1"
          ></path>
        </svg>
      </span>
    </a>
    <a
      class="dropdown-item item-selling js-save-wishlist cursor-pointer"
      @click="favouriteUnfavourite($event)"
      v-if="
        listType === 'default_option_list' || listType === 'queue_option_list'
      "
    >
      <span>{{
          songObject.favorite === true ? "Un-Favorite" : "Favorite"
      }}</span>
    </a>
    <div
      class="dropdown-item js-rate with-submenu"
      @mouseenter="showSubMenu = 'block'"
      @mouseleave="showSubMenu = 'none'"
      v-if="
        listType === 'default_option_list' ||
        listType === 'queue_option_list' ||
        listType === 'history_option_list'
      "
    >
      <span>Rate</span>
      <div class="more-rate margin-right-this">{{ getRating }}</div>
      <div class="svg-icon arrow-icon d-flex ml-auto">
        <svg
          width="6"
          height="11"
          viewBox="0 0 6 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="c-line-1"
            d="M1 1L5.3 5.231L1 9.833"
            stroke="#47505B"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </div>
      <div
        class="submenu"
        :class="{ left: listOptionOnLeft }"
        :style="{ display: showSubMenu }"
        @mouseover="showSubMenu = 'block'"
        @mouseout="showSubMenu = 'none'"
      >
        <div class="submenu-item">
          <div class="text">Click to Rate</div>
          <div
            class="submenu-stars with-js"
            @click="$event.target.classList.toggle('selected')"
          >
            <star-rating
              @rating-selected="setRating"
              v-model="selected_rating"
              v-bind:increment="1"
              v-bind:max-rating="5"
              :clearable="true"
              :inline="true"
              inactive-color="#47505B"
              active-color='#D1433A'
              v-bind:star-size="10"
            />
          </div>
        </div>
        <div class="submenu-item">
          <div class="text">Average Rating</div>
          <div class="submenu-stars">
            <star-rating
              :read-only="true"
              v-model="getAvgRating"
              v-bind:increment="0.5"
              v-bind:max-rating="5"
              :clearable="true"
              :inline="true"
              inactive-color="#47505B"
              active-color="#D1433A"
              v-bind:star-size="10"
            />
          </div>
        </div>
        <div class="submenu-item rate">{{ getTotalRatings }} Ratings</div>
        <div
          class="submenu-item sub-see-all d-flex ml-auto"
          @click="
            redirectToFullViewPage(
              $event,
              songObject && songObject.type ? songObject.type : '',
              id
            )
          "
        >
          See all
          <div class="svg-icon">
            <svg
              width="6"
              height="11"
              viewBox="0 0 6 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                class="c-line-1"
                d="M1 1L5.3 5.231L1 9.833"
                stroke="#47505B"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <a
      class="dropdown-item js-repost-album cursor-pointer"
      href="javascript:void(0)"
      @click="repost($event)"
      v-if="
        listType === 'default_option_list' ||
        listType === 'queue_option_list' ||
        listType === 'history_option_list'
      "
    >
      <span>Repost</span>
    </a>
    <a
      class="dropdown-item js-share-album"
      href="javascript:void(0)"
      @click="share($event)"
      v-if="
        listType === 'default_option_list' ||
        listType === 'queue_option_list' ||
        listType === 'history_option_list'
      "
    >
      <span>Share</span>
      <span class="sh_tab">
        <svg
          width="6"
          height="11"
          viewBox="0 0 6 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1L5.3 5.231L1 9.833"
            stroke="#47505B"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="c-line-1"
          ></path>
        </svg>
      </span>
    </a>
    <PostShare
      :post="songObject"
      ref="share"
      :share_media_check="true"
      :fullViewPageCheck="true"
    />
  </div>
</template>
<script type="application/javascript">
import { addReviewOnSongAlbumPodcast, addToFavorite, addToWishlist } from "../../../apis/APIs";
import StarRating from "vue-star-rating";
import PostShare from "../../Home/NewsFeed/PostItem/PostShare";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    StarRating,
    PostShare,
  },
  data() {
    return {
      showSubMenu: "none",
      loading: false,
      user_id: null,
      selected_rating: 0,
      total_rating: 0,
    };
  },
  props: {
    music_type: {
      type: String,
      default: "song",
    },
    music_type_id: {
      type: Number,
      default: 1,
    },
    favorite: {
      type: Number,
      default: 0,
    },
  },
  mounted: function () {
    this.user_id = this.user.user_data.user_id;
  },
  computed: {
    ...mapGetters({
      user: "user_map_to_props",
    }),
    isSelling() {
      return !this.$store.getters.isMusicModeIsStreaming;
    },
    showOption() {
      return this.$store.getters.getListOptionValue;
    },
    heightOfOption() {
      return this.$store.getters.getListOptionHeight;
    },
    widthOfOption() {
      return this.$store.getters.getListOptionWidth;
    },
    rightBarCheckOption() {
      return this.$store.getters.getListOptionRightBarCheck;
    },
    listType() {
      return this.$store.getters.getListOptionType;
    },
    listOptionOnLeft() {
      return this.$store.getters.getPositionOfListOptionOnLeft;
    },
    songObject() {
      return this.$store.getters.getAddToPlaylistSongsArray;
    },
    getRating() {
      return this.songObject &&
        this.songObject.avg &&
        this.songObject.avg.length > 0
        ? parseInt(this.songObject.avg)
        : "NA";
    },
    getAvgRating() {
      return this.songObject &&
        this.songObject.avg &&
        this.songObject.avg.length > 0
        ? parseInt(this.songObject.avg)
        : 0;
    },
    getTotalRatings() {
      return this.total_rating ? this.total_rating : (this.songObject &&
        this.songObject.rating &&
        this.songObject.rating.total
        ? this.songObject.rating.total
        : 0);
    },
    type() {
      return this.songObject && this.songObject.type
        ? this.songObject.type.charAt(0).toUpperCase() +
            this.songObject.type.slice(1)
        : "";
    },
    id() {
      return this.songObject && this.songObject.id ? this.songObject.id : "";
    },
    getPageId() {
      return this.songObject && this.songObject.page && this.songObject.page.id
        ? this.songObject.page.id
        : "";
    },
    getCurrentSongOrAlbumFavorite() {
      return this.$store.getters.getCurrentSongOrAlbumFavorite;
    },
    getCurrentListOptionSongOrAlbumFavoriteCounts() {
      return this.$store.getters.getCurrentSongOrAlbumFavoriteCounts;
    },
  },
  watch: {
    $route() {
      if (this.$route.name !== null) {
        this.clickOutside();
      }
    },
  },
  methods: {
    async addToWishlist(){
      const body = {
        object_id: this.songObject.id,
        object_type: this.songObject.type
      }
      const { data } = await addToWishlist(body);
      const { status, message } = data || {}
      if(status === 200) {
        this.notificationToast(true, `Wishlist`, message, 5000, 'success')
      } else {
        this.notificationToast(true, `Wishlist`, message, 5000, 'error')
      }
    },
    clickOutside() {
      this.$store.commit("SET_LIST_OPTION", false);
    },
    setRating: function (rating) {
      this.selected_rating = rating;
      this.submitRating();
    },
    repost(event) {
      this.$refs.share.repost(event, null, this.getPageId);
    },
    share(event) {
      this.$refs.share.openShareModel(event);
    },
    async favouriteUnfavourite(e) {
      this.$store.commit("SET_EMPTY_LIST_OPTION_FAVORITE");
      let object = this.songObject;
      const res = await this.setFavourite(
        e,
        object,
        "",
        undefined
      );
      if (res.success === 1) {
        this.$store.commit(
          "SET_LIST_OPTION_FAVORITE",
          res.newFavoriteStatus
        );
        this.$store.commit("SET_LIST_OPTION_FAVORITE_ID", this.id);
        if (this.getCurrentListOptionSongOrAlbumFavoriteCounts) {
          let counts = this.getCurrentListOptionSongOrAlbumFavoriteCounts;
          res.newFavoriteStatus === true ? counts++ : counts--;
          this.$store.commit("SET_CURRENT_SONG_ALBUM_FAVORITE_COUNTS", counts);
        }
        await this.notificationToast(true, "Favorite", this.getCurrentSongOrAlbumFavorite === true
          ? "Un-Favourite Successfully!"
          : "Favourite Successfully!", 5000, 'success')
        this.$store.commit("SET_LIST_OPTION", false);
      } else {
        await this.notificationToast(true, "Favorite", this.getCurrentSongOrAlbumFavorite === true
          ? "Unable to Un-Favourite!"
          : "Unable to Favourite!", 5000, 'error')
      }
    },
    addToQueue() {
      this.$store.dispatch("addSongsToQueue");
    },
    removeFromQueue() {
      this.$store.dispatch("removeFromQueue");
    },
    addToPlaylist() {
      this.$store.commit("SET_PLAY_LIST_MODAL", { value: true, check: 'add-to-playlist' });
      this.$store.commit("SET_LIST_OPTION", false);
    },
    async submitRating() {
      try {
        const formData = new FormData();
        formData.append(
          "reviewable_type",
          this.type && this.type === "Music Podcast" ? "Podcast" : this.type
        );
        formData.append("reviewable_id", this.id);
        formData.append("comment", "");
        formData.append("rating", this.selected_rating);
        const { data } = await addReviewOnSongAlbumPodcast(formData);
        if (data.success === 1) {
          // this.bootStrapSmallToaster('Rating', 'success', 'Rated Successfully!')
          this.total_rating = this.getTotalRatings + 1;
          await this.notificationToast(true, `Rating`, "Rated Successfully!", 5000, 'success')
          this.$store.commit("SET_LIST_OPTION", false);
        } else {
          await this.notificationToast(true, `Rating`, "Unable to Rate", 5000, 'error')
        }
        this.selected_rating = 0;
      } catch (error) {
        console.error(error);
        this.addReviewLoading = false;
      }
    },
    ...mapActions(["fetchUserData"]),
    //  addToFavoriteToggle:function(){
    //     alert('21');
    //  }
  },
};
</script>
<style scoped lang="scss">
.fixed-position {
  position: fixed !important;
}
</style>
