var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.listData)?_c('div',{staticClass:"boards"},[_c('hr',{staticClass:"hr"}),_vm._m(0),_c('div',{staticClass:"row mb-31"},[_c('div',{staticClass:"col-md-7 col-lg-7 col-sm-12"},[_c('h4',{staticClass:"title-head top-sign-bottom"},[_vm._v(" Top 100 "+_vm._s(_vm.getUserCountryCode ? _vm.getUserCountryCode : "Global")+" Singles "),_c('div',{staticClass:"view-chart-btn cursor-pointer"},[_c('a',{on:{"click":function($event){return _vm.redirectToChannel(
                'country',
                'top-100-by-country',
                _vm.getUserCountryName,
                _vm.getUserCountryId
              )}}},[_vm._v(" VIEW CHART "),_c('svg',{attrs:{"width":"7","height":"11","viewBox":"0 0 7 11","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 1L6.135 5.173L1 9.713","stroke":"#47505B","stroke-width":"1.3","stroke-linecap":"round","stroke-linejoin":"round"}})])])])]),_c('div',{staticClass:"row"},[_c('SongsPopularity',{key:"top-100-uk-singles",attrs:{"listData":_vm.getChannelData('top-100-uk-singles'),"showRanking":true,"showNumbering":true}})],1)]),_c('div',{staticClass:"col-md-5 col-lg-5 col-sm-12"},[_c('h4',{staticClass:"title-head popular-head"},[_c('span',{staticClass:"artists-label"},[_vm._v("Popular artists")]),_c('div',{staticClass:"view-chart-btn view-all-btn cursor-pointer"},[_c('a',{on:{"click":function($event){return _vm.redirectToChannel(
                'artist',
                'popular-artists',
                'Popular Artists'
              )}}},[_vm._v(" VIEW ALL "),_c('svg',{attrs:{"width":"7","height":"11","viewBox":"0 0 7 11","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 1L6.135 5.173L1 9.713","stroke":"#47505B","stroke-width":"1.3","stroke-linecap":"round","stroke-linejoin":"round"}})])])])]),_c('div',{staticClass:"lists artists round-avatar m-t-34"},[_c('PopularArtist',{attrs:{"listData":_vm.getChannelData('popular-artists')}})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7 col-lg-7 col-sm-12"},[_c('h4',{staticClass:"title-head top-sign-bottom"},[_vm._v(" Top 100 "+_vm._s(_vm.getUserCountryCode ? _vm.getUserCountryCode : "Global")+" Albums "),_c('div',{staticClass:"view-chart-btn cursor-pointer"},[_c('a',{on:{"click":function($event){return _vm.redirectToChannel(
                'country',
                'top-100-by-country',
                _vm.getUserCountryName,
                _vm.getUserCountryId
              )}}},[_vm._v(" VIEW CHART "),_c('svg',{attrs:{"width":"7","height":"11","viewBox":"0 0 7 11","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 1L6.135 5.173L1 9.713","stroke":"#47505B","stroke-width":"1.3","stroke-linecap":"round","stroke-linejoin":"round"}})])])])]),_c('div',{staticClass:"lists"},[_c('div',{staticClass:"row"},[_c('SongsPopularity',{key:"top-100-uk-albums",attrs:{"width":'670 px',"showRanking":true,"showNumbering":true,"hasTitleHeader":false,"listData":_vm.getChannelData('top-100-uk-albums')}})],1)])]),_c('div',{staticClass:"col-md-5 col-lg-5 col-sm-12"},[_c('h4',{staticClass:"title-head popular-head"},[_c('span',{staticClass:"artists-label"},[_vm._v("Popular Labels")]),_c('div',{staticClass:"view-chart-btn cursor-pointer"},[_c('a',{on:{"click":function($event){return _vm.redirectToChannel('label', 'popular-labels', 'Popular Label')}}},[_vm._v(" VIEW ALL "),_c('svg',{attrs:{"width":"7","height":"11","viewBox":"0 0 7 11","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 1L6.135 5.173L1 9.713","stroke":"#47505B","stroke-width":"1.3","stroke-linecap":"round","stroke-linejoin":"round"}})])])])]),_c('div',{staticClass:"lists artists round-avatar m-t-34"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('PopularLabels',{attrs:{"listData":_vm.getChannelData('popular-labels')}})],1)])])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 mb-3"},[_c('h4',{staticClass:"week-heading"},[_vm._v("WEEKLY TOP BOARDS ")])])
}]

export { render, staticRenderFns }