<template>
  <div class="wrapReposts">
    <div class="titleDetalsRightPart">
      <h3>Reposts</h3>
      <div class="showAll">
        Show All
        <span>
          <svg
            width="7"
            height="11"
            viewBox="0 0 7 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.01562 1L6.1515 5.173L1.01562 9.713"
              stroke="#47505B"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
    </div>
    <div class="imgReposts">
      <div v-for="(item, index) in dataReposts" :key="index" >
        <img :src="item.artwork_url" :alt="item.name" @click="redirectWidgetsToProfilePage($event, item.username ? item.username : '')" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {

  computed: {
    ...mapGetters({
      single_Event_Repost_Members: 'GET_SINGLE_EVENT_REPOST_MEMBERS',
    }),
    dataReposts() {
      return this.single_Event_Repost_Members ? this.single_Event_Repost_Members.data || [] : [];
    }
  },
  methods: {
    ...mapActions({
      getEventRepostMembers: 'getEventRepostMembers',
    }),
    redirectWidgetsToProfilePage: function(e, user_name) {
      e.stopPropagation();
      this.$router.push("/home/page/user-profile/" + user_name);
    },
    async fetchEventRepostMembers() {
      try {
        const eventId = this.$route.params.id;
        await this.getEventRepostMembers({
          event_id: eventId,
          offset: 0,
          limit: 9
        });
      } catch (error) {
        console.error('Failed to fetch event repost members:', error);
      }
    },
  },
  created() {
    this.fetchEventRepostMembers();
  },
};
</script>

<style lang="scss" scoped>
.wrapReposts {
  .imgReposts {
    display: flex;    
    div {
      width: 40px;
      height: 40px;
      background: #c3c3c3;
      border: 3px solid #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: -9px;
      img{
        border-radius: 50%;
        height: 40px;
        width: 40px;
      }
    }
  }
}
</style>
