<template>
    <div class="notification-options">
        <button class="options-btn" @click="toggleOptionsMenu">
            <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.49036 2.99194C1.78508 2.99194 2.07317 2.9042 2.31822 2.73982C2.56327 2.57544 2.75426 2.3418 2.86704 2.06844C2.97982 1.79508 3.00933 1.49429 2.95184 1.20409C2.89434 0.913897 2.75242 0.647336 2.54403 0.438117C2.33563 0.228898 2.07012 0.0864184 1.78107 0.0286951C1.49201 -0.0290285 1.1924 0.000597398 0.920122 0.113826C0.64784 0.227054 0.415118 0.4188 0.251382 0.664815C0.0876475 0.91083 0.000253994 1.20007 0.000253981 1.49595C0.000253964 1.89271 0.157247 2.27322 0.436696 2.55378C0.716146 2.83433 1.09516 2.99194 1.49036 2.99194V2.99194Z" fill="#8B949F"/>
                <path d="M1.49036 8.97498C1.78508 8.97498 2.07317 8.88724 2.31822 8.72285C2.56327 8.55847 2.75426 8.32483 2.86704 8.05147C2.97982 7.77811 3.00933 7.47732 2.95184 7.18712C2.89434 6.89693 2.75242 6.63037 2.54403 6.42115C2.33563 6.21193 2.07012 6.06945 1.78107 6.01173C1.49201 5.954 1.1924 5.98363 0.920122 6.09686C0.64784 6.21009 0.415118 6.40183 0.251382 6.64785C0.0876475 6.89386 0.000253994 7.1831 0.000253981 7.47898C0.000253964 7.87574 0.157247 8.25625 0.436696 8.53681C0.716146 8.81736 1.09516 8.97498 1.49036 8.97498V8.97498Z" fill="#8B949F"/>
                <path d="M1.49036 14.9596C1.78508 14.9596 2.07317 14.8719 2.31822 14.7075C2.56327 14.5431 2.75426 14.3094 2.86704 14.0361C2.97982 13.7627 3.00933 13.4619 2.95184 13.1717C2.89434 12.8815 2.75242 12.615 2.54403 12.4058C2.33563 12.1965 2.07012 12.0541 1.78107 11.9963C1.49201 11.9386 1.1924 11.9682 0.920122 12.0815C0.64784 12.1947 0.415118 12.3865 0.251382 12.6325C0.0876475 12.8785 0.000253994 13.1677 0.000253981 13.4636C0.000253964 13.8604 0.157247 14.2409 0.436696 14.5214C0.716146 14.802 1.09516 14.9596 1.49036 14.9596V14.9596Z" fill="#8B949F"/>
            </svg>
        </button>
        <div v-if="isOpen" class="options-popup">
            <div class="option-item" @click="handleOptionClick('remove')">
                Remove this notification
            </div>
            <div class="option-item" @click="handleOptionClick('fewer')">
                Get fewer notifications like this
            </div>
            <div class="option-item" @click="handleOptionClick('turnOff')">
                Turn off all notifications from this Page
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from '../../utils/EventBus';


export default {
    name: 'NotificationOptionsPopup',
    props: {
        id: Number // Unique ID for each dropdown
    },
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        toggleOptionsMenu(e) {
            e.stopPropagation();

            if (!this.isOpen) {
                EventBus.$emit('close-all-popups');
            }

            this.isOpen = !this.isOpen;
        },
        handleOptionClick(action) {
            this.$emit('option-selected', action);
            this.isOpen = false;
        },
        closeMenu() {
            this.isOpen = false;
        }
    },
    mounted() {
        EventBus.$on('close-all-popups', this.closeMenu);

        document.addEventListener('click', (e) => {
            if (!this.$el.contains(e.target)) {
                this.isOpen = false;
            }
        });
    },
    beforeDestroy() {
        EventBus.$off('close-all-popups', this.closeMenu);
        document.removeEventListener('click', this.closeMenu);
    }
};
</script>

<style scoped>
.notification-options {
    position: absolute;
    right: 0%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;

}

.options-btn {
    background: none;
    border: none;
    color: #8895A7;
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.options-btn:hover {
    background-color: #F1F3F5;
}

.options-popup {
    position: absolute;
    top: 10%;
    right: 20px;
    width: 240px;
    background: #FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 10000;
    padding: 10px 0;
}

.option-item {
    padding: 7px 20px;
    font-size: 12px;
    color: #47505B;
    cursor: pointer;
    transition: background-color 0.2s ease;
    height: 30px;
    font-family: HelveticaNeueNormal, sans-serif;
}

.option-item:hover {
    background-color: #D1433A1A;
    color: #D1433A;
}
</style>