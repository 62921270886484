<template>
  <div class="modal fade playlist show " id="playlist" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" >
    <div class="modal-dialog pg-modal-dialog">
      <div class="modal-content" style="height: 100%">
        <div class="close-button for-playlist" data-dismiss="modal" aria-label="Close" v-on:click="closeModal">
          <div class="svg-icon">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="c-line-1" d="M1 1.25L14 13.75" stroke="#47505B" stroke-width="1.5"></path>
              <path class="c-line-1" d="M13.75 1L1.25 14" stroke="#47505B" stroke-width="1.5"></path>
            </svg>
          </div>
        </div>
        <div class="head-block d-flex flex-row align-items-center">
          <div class="text queue-head">Your Queue</div>
        </div>
        <ul class="nav nav-tabs main-tabs d-flex justify-content-center" id="myTab" role="tablist">
          <li :class="['nav-item', (activeTab === 'up_next') ? 'active-tab-style': 'deActive-tab-style']" role="presentation">
            <a @click="changeActiveTab('up_next')" class="nav-link nav-link-border active" id="next-tab" data-toggle="tab" href="#next" role="tab" aria-controls="next" aria-selected="true">
              Up Next
            </a>
          </li>
          <li :class="['nav-item', (activeTab === 'history_tab') ? 'active-tab-style': 'deActive-tab-style']" role="presentation" style="margin-left: 20px;">
            <a @click="changeActiveTab('history_tab')" class="nav-link nav-link-border" id="history-tab" data-toggle="tab" href="#history" role="tab" aria-controls="history" aria-selected="false">history</a>
          </li>
        </ul>
        <div class="tab-content scroll-style" id="myTabContent">
          <div :class="['tab-pane fade', (up_next_songs_list.length > 11) ? 'up_next_scroll' : '', (activeTab === 'up_next') ? 'show active' : '']"
               id="next" role="tabpanel" aria-labelledby="next-tab">
            <perfect-scrollbar>
              <div class="block-resume d-flex flex-row align-items-center">
                <div class="text">
                  Music Playback is {{ (SongStatus === false) ? 'Paused' : 'Playing'}}
                </div>
                <div class="btn" @click="playPauseSong">{{ (SongStatus === false) ? 'Resume' : 'Pause'}}</div>
              </div>
              <div class="item-block d-flex flex-row align-items-center"
                   v-for="(next_song, index) in up_next_songs_list" :key="index">
                <div class="cover">
                  <div class="svg-icon">
                    <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.2">
                        <path
                            d="M4.62731 27.1672C6.63062 27.1672 8.25463 25.575 8.25463 23.6109C8.25463 21.6469 6.63062 20.0547 4.62731 20.0547C2.624 20.0547 1 21.6469 1 23.6109C1 25.575 2.624 27.1672 4.62731 27.1672Z"
                            stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                        <path
                            d="M20.3724 22.3244C22.3757 22.3244 23.9997 20.7322 23.9997 18.7682C23.9997 16.8041 22.3757 15.2119 20.3724 15.2119C18.3691 15.2119 16.7451 16.8041 16.7451 18.7682C16.7451 20.7322 18.3691 22.3244 20.3724 22.3244Z"
                            stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                        <path d="M8.25537 23.6101V6.28455L23.9237 1V18.6922" stroke="#47505B" stroke-width="1.5"
                              stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                      </g>
                    </svg>
                  </div>
                  <div class="svg-icon">
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2998 4.5V11.2H9.7998V4.5H11.2998Z"
                            fill="#D1433A"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3999 2.40039V12.8004H12.8999V2.40039H14.3999Z"
                            fill="#D1433A"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 0.400391V15.3004H16V0.400391H17.5Z"
                            fill="#D1433A"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M6.49999 1.82836L2.30803 5.10014H1.5V10.2001H2.09844L6.49999 13.4757V1.82836ZM6.49936 14.102L6.49983 14.0999L6.49936 14.102ZM7.30923 0.0720158C7.78848 0.259551 7.95566 0.709647 7.9954 1.06731L7.99999 1.1086V14.1917L7.9954 14.233C7.95909 14.5598 7.8162 15.0206 7.34151 15.2208C6.93505 15.3923 6.55248 15.2437 6.39222 15.1716C6.20466 15.0872 6.03553 14.9722 5.93273 14.9001H5.90154L1.60155 11.7001H0V3.60014H1.79196L5.67181 0.571952C5.68269 0.562539 5.69697 0.55037 5.71423 0.53605C5.75057 0.505892 5.80136 0.465179 5.86257 0.42003C5.97821 0.334717 6.15947 0.212242 6.36705 0.123277C6.5409 0.0487698 6.91403 -0.0826269 7.30923 0.0720158Z"
                            fill="#D1433A"></path>
                    </svg>
                  </div>
                </div>
                <div class="number">{{ (9 > index ) ? '0' + (1 + index) : 1+ index}}.</div>
                <div class="d-flex flex-column ">
                  <div class="title artist-name-width">{{ (next_song.page) ? next_song.page.title: 'Artist Name' }}</div>
                  <div class="subtitle artist-name-width">{{ next_song.title ? next_song.title : 'Song Name' }}</div>
                </div>
                <div class="time d-flex ml-auto">{{ next_song.duration ? convertSecondsIntoMinuets(next_song.duration) : '00.00' }}</div>
                <div class="more dropright" @click="openListOption($event, [next_song], 'queue_option_list', next_song,true)">
                  <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static"
                       class="dropdown vertical more-dropdown">
                    <div class="svg-icon more-position">
                      <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                           class="svg-more">
                        <path
                          d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z"
                          fill="#8B949F"></path>
                        <path
                          d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z"
                          fill="#8B949F"></path>
                        <path
                          d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z"
                          fill="#8B949F"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
          
          <div  :class="['tab-pane fade', (lastPlayArray.length > 10) ? 'up_next_scroll' : '', (activeTab === 'history_tab') ? 'show active' : '']" id="history" role="tabpanel" aria-labelledby="history-tab">
            <perfect-scrollbar>
              <div class="block-resume d-flex flex-row align-items-center">
                <div class="text">
                  Music Playback is {{ (SongStatus === false) ? 'Paused' : 'Playing'}}
                </div>
                <div class="btn" @click="playPauseSong">{{(SongStatus === false) ? 'Resume' : 'Pause'}}</div>
              </div>
              <div class="item-block d-flex flex-row align-items-center" v-for="(last_play, index) in lastPlayArray" :key="index">
                <div class="cover">
                  <div class="svg-icon">
                    <svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g opacity="0.2">
                        <path
                          d="M4.62731 27.1672C6.63062 27.1672 8.25463 25.575 8.25463 23.6109C8.25463 21.6469 6.63062 20.0547 4.62731 20.0547C2.624 20.0547 1 21.6469 1 23.6109C1 25.575 2.624 27.1672 4.62731 27.1672Z"
                          stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path
                          d="M20.3724 22.3244C22.3757 22.3244 23.9997 20.7322 23.9997 18.7682C23.9997 16.8041 22.3757 15.2119 20.3724 15.2119C18.3691 15.2119 16.7451 16.8041 16.7451 18.7682C16.7451 20.7322 18.3691 22.3244 20.3724 22.3244Z"
                          stroke="#47505B" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"
                          stroke-linejoin="round"></path>
                        <path d="M8.25537 23.6101V6.28455L23.9237 1V18.6922" stroke="#47505B" stroke-width="1.5"
                              stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                      </g>
                    </svg>
                  </div>
                  <div class="svg-icon">
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2998 4.5V11.2H9.7998V4.5H11.2998Z"
                            fill="#D1433A"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.3999 2.40039V12.8004H12.8999V2.40039H14.3999Z"
                            fill="#D1433A"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 0.400391V15.3004H16V0.400391H17.5Z"
                            fill="#D1433A"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M6.49999 1.82836L2.30803 5.10014H1.5V10.2001H2.09844L6.49999 13.4757V1.82836ZM6.49936 14.102L6.49983 14.0999L6.49936 14.102ZM7.30923 0.0720158C7.78848 0.259551 7.95566 0.709647 7.9954 1.06731L7.99999 1.1086V14.1917L7.9954 14.233C7.95909 14.5598 7.8162 15.0206 7.34151 15.2208C6.93505 15.3923 6.55248 15.2437 6.39222 15.1716C6.20466 15.0872 6.03553 14.9722 5.93273 14.9001H5.90154L1.60155 11.7001H0V3.60014H1.79196L5.67181 0.571952C5.68269 0.562539 5.69697 0.55037 5.71423 0.53605C5.75057 0.505892 5.80136 0.465179 5.86257 0.42003C5.97821 0.334717 6.15947 0.212242 6.36705 0.123277C6.5409 0.0487698 6.91403 -0.0826269 7.30923 0.0720158Z"
                            fill="#D1433A"></path>
                    </svg>
                  </div>
                </div>
                <div class="number">{{ (9 > index ) ? '0' + (1 + index) : 1+ index}}.</div>
                <div class="d-flex flex-column">
                  <div class="title  artist-name-width">{{ (last_play.page) ? last_play.page.title : 'Artist Name' }}</div>
                  <div class="subtitle  artist-name-width">{{ last_play.title ? last_play.title : 'Song Name' }}</div>
                </div>
                <div class="time d-flex ml-auto">{{ last_play.duration ? convertSecondsIntoMinuets(last_play.duration) : '00.00' }}</div>
                <div class="more dropright" @click="openListOption($event, [last_play], 'history_option_list', last_play,true)">
                  <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static"
                       class="dropdown vertical more-dropdown">
                    <div class="svg-icon more-position">
                      <svg width="3" height="15" viewBox="0 0 3 15" fill="none" xmlns="http://www.w3.org/2000/svg"
                           class="svg-more">
                        <path
                          d="M-6.55671e-08 1.5C-1.01779e-07 2.32843 0.671573 3 1.5 3C2.32843 3 3 2.32843 3 1.5C3 0.671573 2.32843 -2.93554e-08 1.5 -6.55671e-08C0.671573 -1.01779e-07 -2.93554e-08 0.671573 -6.55671e-08 1.5Z"
                          fill="#47505B"></path>
                        <path
                          d="M-6.55671e-08 7.29199C-1.01779e-07 8.12042 0.671573 8.79199 1.5 8.79199C2.32843 8.79199 3 8.12042 3 7.29199C3 6.46357 2.32843 5.79199 1.5 5.79199C0.671573 5.79199 -2.93554e-08 6.46356 -6.55671e-08 7.29199Z"
                          fill="#47505B"></path>
                        <path
                          d="M-6.55671e-08 13.084C-1.01779e-07 13.9124 0.671573 14.584 1.5 14.584C2.32843 14.584 3 13.9124 3 13.084C3 12.2556 2.32843 11.584 1.5 11.584C0.671573 11.584 -2.93554e-08 12.2556 -6.55671e-08 13.084Z"
                          fill="#47505B"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="totalCount !== lastPlayArray.length" class="clear-history-style clear-history d-flex align-items-center justify-content-center" @click="showMore" >
                <div v-if="spinner" class="text-center pr-2">
                  <b-spinner class="spinner-color" small label="Small Spinner"></b-spinner>
                </div>
                Show More
              </div>

            </perfect-scrollbar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="application/javascript">
import moment from "moment";
import { getContinueListeningSongs } from "../../../apis/APIs";
export default {
  props:['up_next_songs_list','playAllSongs'],
  data() {
    return {
      activeTab: 'up_next',
      showMoreArray : [],
      spinner : false,
      hasMoreData: true,
      totalCount: 0,
    }
  },
  components: {},
  computed: {
    lastPlayArray:{
      set(val){
        this.$store.commit('Set_Last_Play_Song_List',val)
      },
      get(){
        return this.$store.getters.getLastPlaySongsArray;
      },
    },
    SongStatus(){
      return this.$store.getters.getUpdateSongStatus;
    },
  },
  methods: {
    closeModal: function () {
      this.$parent.closeModal();
    },
    changeActiveTab(tab){
      this.activeTab = tab;
    },
    convertSecondsIntoMinuets(duration) {
      return moment.utc(duration * 1000).format('mm:ss');
    },
    playPauseSong: function(){
      if(this.playAllSongs.length > 0){
        this.$store.commit('Empty_Play_All_Songs_Array');
        //this.$parent.playPauseSong();
      }else{
        this.$parent.playPauseSong();
      }
    },
    async showMore(){
      try {
        this.spinner = true;
        const { data } = await getContinueListeningSongs('latest', this.lastPlayArray.length, 10);

        if(data.success === 1){
          this.showMoreArray = data.channels.center.objects.data;
          this.totalCount = data.channels.center.objects.total;
          this.lastPlayArray = [...this.lastPlayArray, ...this.showMoreArray];

          this.activeTab = 'history_tab';
          this.spinner = false;
        }
      }catch (e){
      }
    }
  }
}
</script>
<style>
.active-tab-style{
  border-bottom: 4px solid #D1433A;
}
.deActive-tab-style{
  border-bottom: 4px solid #C4CCD6;
}
.clear-history-style{
  background: #E6EAF0 !important;
  border-radius: 4px !important;
  height: 40px !important;
  width: 88% !important;
  margin-left: 20px !important;
  margin-top: 16px !important;
}
.artist-name-width{
  white-space: nowrap;
  width: 102px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.queue-head{
  margin-left: 0px !important;
}
.clear-history-style{
  font-weight: bold !important;
  font-size: 12px !important;
}
.pg-modal-dialog{
  height: 65vh;
  margin: 15.7rem 66px 0.15rem auto !important;
}
</style>
<style lang="scss">
.item-block:hover .dropright .more-dropdown .more-position svg path{
  fill: #000000;
}
.playlist{
  padding-right: 15px;
  display: block!important;
  position: unset!important;
 .modal-dialog{
   height: 805px;
   max-width: 300px;
   position: absolute;
   top: unset!important;
   right: -2%;
   @media  screen and (max-width: 1920px) {
     right: -1.6% !important;
   }
   @media  screen and (max-width: 1720px) {
     right: -2.2% !important;
   }
   @media  screen and (max-width: 1600px) {
     right: -2.5% !important;
   }
   @media  screen and (max-width: 1430px) {
     right: -3.8% !important;
   }
   @media  screen and (max-width: 1366px) {
     height: 462px!important;
     right: -6% !important;
   }
   bottom: 60px;
   .tab-content{
     height: 683px;
     @media  screen and (max-width: 1366px) {
       height: 54.5vh !important;
     }
     .tab-pane{
       height: inherit;
       .ps{
         height: calc(100% - 10px);
       }
     }
   }
 }
}
</style>
