import { render, staticRenderFns } from "./EventDetail.vue?vue&type=template&id=0ef6745f&scoped=true"
import script from "./EventDetail.vue?vue&type=script&lang=js"
export * from "./EventDetail.vue?vue&type=script&lang=js"
import style0 from "./EventDetail.vue?vue&type=style&index=0&id=0ef6745f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ef6745f",
  null
  
)

export default component.exports