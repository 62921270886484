<template>
  <div class="container-fluid px-0">
    <div class="app-container">
      <div class="pg-container">
        <div class="container_xxl px-0">
          <div class="row">
            <div class="col-sm-12 col-md-12 ">
              <div class="comment-card">
                <div class="comment-card-body">
                  <div class="row">
                  <div style="cursor: pointer;">
                    <DeleteCommentDropDown :item="item" :post="post" @removeCommentFromList="removeCommentFromList" />
                  </div>
                    <div class="post-comment-width">
                      <img class="radius" v-if="user_picture" :src="user_picture" />
                      <img class="radius" v-if="!user_picture" :src="require(`@/assets/img/user-placeholder.png`)" />
                    </div>
                    <div class="pl-0 pt-2 post-comment-width-1">
                      <div class="col-sm-9 pl-0">
                        <h3 class="comment-heading mb-0 pl-1" style="display: inline-block;">
                          {{ userName }}
                        </h3>
                        <span class="pl-2" style="color: #8B949F;font-weight: 100">replying to</span>
                        <div class="" style="display: inline-block;">
                          <span class="pl-2 date-custom-card">{{ time }}</span>
                          <span class="icon-padding">
                            <svg class="" style="display: inline-block" width="14" height="14" viewBox="0 0 14 14"
                              fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
                                stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M1 7H13" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                              <path
                                d="M6.99961 1C8.50038 2.64301 9.35326 4.77522 9.39961 7C9.35326 9.22478 8.50038 11.357 6.99961 13C5.49884 11.357 4.64596 9.22478 4.59961 7C4.64596 4.77522 5.49884 2.64301 6.99961 1V1Z"
                                stroke="#8B949F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          </span>
                        </div>
                      </div>
                      <div class="col-sm-12 pl-0">
                        <p style="color: #D1433A;" class="mb-0 pl-1">
                          {{ name }}
                        </p>
                      </div>
                    </div>
                  
                    <div v-if="!comment_type" class="pt-3 comment-icon">
                      <!-- <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.16634 1.99935C7.16634 2.45959 7.53944 2.83268 7.99967 2.83268C8.45991 2.83268 8.83301 2.45959 8.83301 1.99935C8.83301 1.53911 8.45991 1.16602 7.99967 1.16602C7.53944 1.16602 7.16634 1.53911 7.16634 1.99935Z"
                          fill="#8B949F" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path
                          d="M13.0003 1.99935C13.0003 2.45959 13.3734 2.83268 13.8337 2.83268C14.2939 2.83268 14.667 2.45959 14.667 1.99935C14.667 1.53911 14.2939 1.16602 13.8337 1.16602C13.3734 1.16602 13.0003 1.53911 13.0003 1.99935Z"
                          fill="#8B949F" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round" />
                        <path
                          d="M1.33333 1.99935C1.33333 2.45959 1.70643 2.83268 2.16667 2.83268C2.6269 2.83268 3 2.45959 3 1.99935C3 1.53911 2.6269 1.16602 2.16667 1.16602C1.70643 1.16602 1.33333 1.53911 1.33333 1.99935Z"
                          fill="#8B949F" stroke="#8B949F" stroke-width="1.5" stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg> -->
                    </div>
                  </div>
                  <div class="row pt-2">
                    <div class=" post-comment-p pl-3">
                      <p class="comment-p" v-if="!is_edit">
                        {{ item.content }}
                      </p>
                      <img v-if="item.artwork_url" :src="item.artwork_url.original_url" alt="" id="cmnt-img" class="comment-image" />
                      <img />
                    </div>
                  </div>
                  <div class=" border-width mb-3"></div>
                  <div class="artist-timeline-activity m-t-10">
                    <div class="activity-main">
                      <CommentLike :item="item" @reactOrUnReact="reactOrUnReact" :comment_type="comment_type" />
                      <div v-if="
                        comment_type === 'Song' ||
                        comment_type === 'Album' ||
                        comment_type === 'Music Podcast'
                      " class="post-section-comment post-section-comment-2" :class="{ active: write_reply }"
                        @click="toggleReplySection($event)">
                        <svg width="16" height="14.3" viewBox="0 0 16 14.3">
                          <defs>
                            <clipPath id="clip-comment">
                              <rect width="16" height="14.3" />
                            </clipPath>
                          </defs>
                          <g id="comment" clip-path="url(#clip-comment)">
                            <path class="post-section-comment-svg" id="Path_2" data-name="Path 2"
                              d="M14.274,11.439l-.052,0H3.572a.75.75,0,0,0-.457.155L.75,13.411V1.771A1.212,1.212,0,0,1,1.093,1,.822.822,0,0,1,1.726.752l.052,0H14.216l.052,0A.822.822,0,0,1,14.9,1h0a1.212,1.212,0,0,1,.343.77l.007,8.65a1.212,1.212,0,0,1-.343.77A.822.822,0,0,1,14.274,11.439Z"
                              fill="none" stroke="#47505b" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="1.5" />
                          </g>
                        </svg>
                        <span>
                          Reply
                        </span>
                        <span class="counts">
                          {{ replies_count ? replies_count : 0 }}
                        </span>
                      </div>
                      <div v-else class="post-section-comment" :class="{ active: write_reply }"
                        @click="toggleReplySection($event)">
                        <svg width="16" height="14.3" viewBox="0 0 16 14.3">
                          <defs>
                            <clipPath id="clip-comment">
                              <rect width="16" height="14.3" />
                            </clipPath>
                          </defs>
                          <g id="comment" clip-path="url(#clip-comment)">
                            <path class="post-section-comment-svg" id="Path_2" data-name="Path 2"
                              d="M14.274,11.439l-.052,0H3.572a.75.75,0,0,0-.457.155L.75,13.411V1.771A1.212,1.212,0,0,1,1.093,1,.822.822,0,0,1,1.726.752l.052,0H14.216l.052,0A.822.822,0,0,1,14.9,1h0a1.212,1.212,0,0,1,.343.77l.007,8.65a1.212,1.212,0,0,1-.343.77A.822.822,0,0,1,14.274,11.439Z"
                              fill="none" stroke="#47505b" stroke-linecap="round" stroke-linejoin="round"
                              stroke-width="1.5" />
                          </g>
                        </svg>
                        {{ replies_count ? replies_count : 0 }}
                      </div>
                      <PostShare :post="item" v-if="!comment_type" />
                    </div>
                  </div>
                  <div v-if="replyList && replyList.length > 0"
                    style="height: 1px; background-color: #E6EAF0; margin-top: 1rem; margin-bottom: 1rem;"></div>
                  <RepliesItem v-for="(eachOp, index) in replyList"  @toggleReplyInput="toggleReplyInput" :key="'reply_item' + index"
                    :reply_count="replyList.length || 0" :item="eachOp" :post_id="id"
                    @reactOrUnReact="reactOrUnReactOnReply(eachOp)" @removeReplyFromList="removeReplyFromList" />
                  <div class="row " v-if="comment_list_loading">
                    <div class="text-center">
                      <b-spinner variant="primary" label="Spinning"></b-spinner>
                    </div>
                  </div>
                  <!-- Reply Input Start -->
                  <ReplyInput ref="replyInput" v-if="write_reply" :item="item" @replyOfCommentAdded="replyAdded" />
                  <!-- Reply Input End -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommentLike from "@/components/Home/NewsFeed/PostItem/CommentLike.vue";
import PostShare from "@/components/Home/NewsFeed/PostItem/PostShare.vue";
import ReplyInput from "@/components/Home/NewsFeed/PostItem/ReplyInput.vue";
import RepliesItem from "@/components/Home/NewsFeed/components/RepliesItem.vue";
import DeleteCommentDropDown from "../PostItem/DeleteCommentDropDown.vue"
import moment from "moment";
import { mapGetters } from "vuex";
import Vue from "vue";
import { getReplyOfComment } from "../../../../apis/APIs";

export default {
  name: "CommentSection",
  data() {
    return {
      is_edit: false,
      comment: "",
      comment_loading: false,
      write_reply: false,
      is_drop_down_open: false,
      comment_list_loading: false,
      replyList: [],
      repliesCount: 0,
      repliesOffset: 0,
      repliesLimit: 3,
      message: "",
      success_message: ""
    };
  },
  mounted() {
    this.getRepliesOfComment();
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    post:{
      type: Object
    },
    comment_check: {
      type: Boolean,
      default: false,
    },
    input_check: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    comment_type: {
      type: String,
      default: "",
    }
  },
  components: {
    CommentLike,
    PostShare,
    ReplyInput,
    RepliesItem,
    DeleteCommentDropDown
  },
  computed: {
    ...mapGetters({
      user: "user_map_to_props",
    }),
    id() {
      const { node_id = "" } = this.item || {};

      return node_id;
    },
    comment_id() {
      const { comment_id = "" } = this.item || {};

      return comment_id;
    },
    userName() {
      const { user } = this.item || {};
      const { name, last_name } = user;
      return `${name} ${last_name}`;
    },
    user_picture() {
      const { user } = this.item || {};
      return user.artwork_url ? user.artwork_url : null;
    },
    time() {
      const { updated_at } = this.item || {};
      return moment(updated_at).fromNow();
    },
    replies_count() {
      if (this.repliesCount > this.item.reply_count) {
        return this.repliesCount;
      } else {
        return this.item.reply_count;
      }
    },
  },
  methods: {
    reactOrUnReact() {
      this.$emit("reactOrUnReact");
    },
    toggleReplyInput() {
      if (!this.write_reply){
        this.write_reply = true;
      }

      this.$nextTick(() => {
        if (this.$refs.replyInput) {
          this.$refs.replyInput.focusInput();
        }
      });
    },
    removeReplyFromList(replyId){
      if (this.replyList.length > 0) {
            const replacedIndex = this.replyList.findIndex(e => e.id === replyId)
            if (replacedIndex !== -1) {
              this.replyList.splice(replacedIndex, 1);
            }
      }
    },
    removeCommentFromList(){
      this.$emit('removeComment',this.item.id);
    },
    toggleReplySection(e) {
      if (e) e.stopPropagation();

      this.write_reply = !this.write_reply;
    },
    async getRepliesOfComment(showMore = false) {
      try {
        if (showMore) {
          this.repliesOffset =
            Number(this.repliesOffset) + Number(this.repliesLimit);
        }
        this.comment_list_loading = true;
        const formData = new FormData();
        formData.append("parent_id", this.item.id);
        const { data } = await getReplyOfComment(
          formData,
          this.repliesOffset,
          this.repliesLimit
        );
        if (showMore) {
          this.replyList = [...this.replyList, ...data.data];
        } else {
          this.replyList = data.data ? data.data : [];
        }
        this.repliesCount = data.total ? data.total : [];
        this.comment_list_loading = false;
      } catch (error) {
        console.error(error);
        this.comment_list_loading = false;
      }
    },
    refresh_comment_list() {
      this.$parent.init();
    },
    openReplySection() {
      this.write_reply = true;
    },
    replyAdded(value) {
      this.replyList.push(value);
      this.repliesCount = this.replyList.length;
    },
    async reactOrUnReactOnReply(reply) {
      const temp = reply;
      let obj = {
        reaction_able_id: reply.id,
        reaction_able_type: "Comment",
        reaction_type: "like",
      };
      if (reply.reacted) {
        reply.reaction_count=reply.reaction_count-1
        Vue.set(reply, "reacted", null);
        const index = reply.reactions.findIndex(
          (e) => (e.type = obj.reaction_type)
        );
        if (index !== -1) {
          reply.reactions[index].count = --reply.reactions[index].count;
        }
      } else {
        reply.reaction_count=reply.reaction_count+1
        Vue.set(reply, "reacted", obj);
        const index = reply.reactions.findIndex(
          (e) => (e.type = obj.reaction_type)
        );
        if (index !== -1) {
          reply.reactions[index].count = ++reply.reactions[index].count;
        } else {
          reply.reactions.push({
            type: obj.reaction_type,
            count: 1,
          });
        }
      }

      let { success } = await this.$store.dispatch("reactOrUnReact", obj);
      if (!Boolean(success)) {
        Vue.set(reply, "reacted", reply.reacted ? null : obj);
        reply = temp;
      }
    },
  },
};
</script>

<style scoped>
.comment-image {
  width: 100px;
  height: 100px;
  display: block;
  padding-bottom: 1rem;
}
.comment-card {
  background: #ffffff;
  border: 1px solid #e6eaf0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 0.6rem;
}

.comment-card-body {
  padding: 10px 20px 10px 20px;
}

.radius {
  border-radius: 100px;
  width: 51px;
  height: 51px;
  object-fit: cover;
}

.comment-heading {
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}

.date-custom-card {
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #8b949f;
}

.date-custom-card::before {
  width: 5px;
  height: 5px;
  background: red;
}

.comment-p {
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #071526;
}

.comment-icon {
  display: inline-block !important;
  width: 20% !important;
  position: absolute;
  right: 30px;
}

.comment-icon svg {
  float: right;
}

.post-comment-bottom-icons-width {
  padding: 7px 0px 7px 12px;
  width: 70px;
}

.post-comment-bottom-icons-width:hover {
  padding: 7px 0px 7px 12px;
  width: 70px;
  border-radius: 4px;
  background: #ecf0ff;
  stroke: #D1433A;
  fill: #D1433A;
  cursor: pointer;
}

.post-comment-bottom-icons-width:nth-child(2):hover span svg g path {
  stroke: #D1433A;
}

.post-comment-bottom-icons-width:hover span svg path {
  stroke: #D1433A;
}

.post-comment-bottom-icons-width:nth-child(4):hover span svg g g g path {
  fill: #D1433A;
  stroke: none;
}

.post-comment-width {
  width: 75px !important;
  display: inline-block !important;
}

.post-comment-width img {
  width: 45px !important;
  height: 45px !important;
}

.post-comment-width-1 {
  width: 70% !important;
  display: inline-block !important;
}

.post-comment-p {
  width: 100%;
}

.border-width {
  height: 1px;
  width: 100%;
  background-color: #e6eaf0;
}

.post-comment-icon-color {
  display: inline-block !important;
  float: left;
  padding-top: 1px;
}

.post-comment-icon-text {
  display: inline-block !important;
  float: left;
  font-size: 11px;
  padding-left: 5px;
}

.post-comment-icon-color-1 {
  display: inline-block !important;
  float: left;
  padding-top: 2px;
}

.post-comment-icon-color-2 {
  display: inline-block !important;
  float: left;
}

.post-inner-comment-card {
  width: 460px;
  background: #f5f6f8;
  box-sizing: border-box;
  border-radius: 34.5px;
  margin-left: 80px;
}

.post-inner-comment-card-body {
  padding: 10px 20px 10px 20px;
  position: relative;
}

.post-comment-reply-width {
  width: 8% !important;
  position: relative;
  left: -18px;
}

.post-comment-reply-width-1 {
  width: 92% !important;
  display: inline-block !important;
}

.photo-radius {
  border-radius: 100px;
  width: 40px;
}

.reply-post-margin {
  margin-left: 97px;
}

.post-like-comment {
  width: auto;
  border-right: 1px solid #8b949f;
}

.post-like-commentt {
  width: auto;
}

.post-like-comment-1 {
  width: 16%;
}

.post-reply-comment-bottom {
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #47505b;
}

.john-comment {
  font-family: "HelveticaNeueMedium";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
}

.post-inner-comment-card-body-1 {
  padding: 5px 20px 5px 20px;
}

.post-inner-comment-heading {
  width: 460px;

  box-sizing: border-box;
  border-radius: 34.5px;
  margin-left: 80px;
}

.post-photo-width {
  width: 257px;
  border-radius: 10px;
}

.post-photo-main-width {
  width: 100%;
}

.photo-margin {
  margin-left: 97px;
}

.pg-container {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .post-inner-comment-card {
    width: 90%;
    margin-left: 40px;
  }

  .reply-post-margin {
    margin-left: 50px;
  }

  .photo-margin {
    margin-left: 50px;
  }

  .post-inner-comment-heading {
    margin-left: 40px;
  }
}

.hover:hover {
  color: #D1433A;
  cursor: pointer;
}

.icon-padding {
  padding-left: 6px;
}
</style>
<style scoped lang="scss">
.post-section-comment-2 {
  width: auto !important;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: #fff !important;

  &:hover {
    background-color: #f5f6f8 !important;

    svg {
      path {
        stroke: #D1433A !important;
      }
    }
  }

  &.active {
    background-color: #D1433A !important;

    span {
      color: #fff !important;
    }

    svg {
      path {
        stroke: #fff !important;
      }
    }
  }

  span {
    margin-top: 0.1rem;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
  }

  .counts {
    margin-left: 0.5rem;
    color: #8b949f;
    font-size: 12px !important;
  }
}

/*Ameer Hamza styling -- song details page*/

@media screen and (min-width: 2560px) {

  .post-comment-width {
    width: auto !important;
  }
  .post-comment-width img {
    width: 4rem !important;
    height: 4rem !important;
  }
  .post-comment-width-1 h3{
    font-size: var(--sm-font-size) !important;
  }
  .post-comment-width-1 span{
    font-size: 1rem !important;
  }
  .post-comment-p p {
    font-size: var(--sm-font-size) !important;
  }
  .post-section-comment svg {
    width: var(--sm-font-size);
    height: var(--sm-font-size);
  }
  .post-section-comment span {
    font-size: var(--sm-font-size) !important;
  }
  .post-section-comment .counts {
    font-size: var(--font-size) !important;
  }
}
</style>
